<template>
  <div>
      <div
          v-if="alert.show"
          class="alert no-data"
          :class="alert.type"
        >
          {{ alert.message }}
        </div>
        <template v-else>

          <template v-for="post in filteredPosts">
            <Row class="cl-ivu-row" v-if="isPostInstaEligible(post)" :key="post.pagePostId">
              <Row-Col span="1">
                <i
                  v-tooltip="'Add this post'"
                  data-placement="right"
                  class="far action-icon fa-plus-square"
                  @click="addToAdSheet(post)"
                >
                </i>
                </Row-Col>
                <Row-Col span="1">
                <i
                  v-tooltip="'Remove this post'"
                  data-placement="right"
                  class="far action-icon fa-minus-square"
                  @click="removeFromAdSheet(post)"
                >
                </i>
              </Row-Col>
              <!-- <Row-Col span="1" offset="1">
                <Poptip transfer placement="left-start">
                  <i class="fas fa-eye"></i>
                  <div slot="content">
                    <img v-lazy="post['imgUrl']" class="list-image-poptip" />
                  </div>
                </Poptip>
              </Row-Col> -->
              <Row-Col class="cl-widget-text" span="16" offset="1">
                <span> {{ post["title"] }} </span>
              </Row-Col>
              <Row-Col span="2" offset="1" class="list-image-wrapper">
                <img v-lazy="post['imgUrl']" class="list-image" />
              </Row-Col>
            </Row>
            <Row
              v-else
              :key="post.pagePostId"
              class="cl-ivu-row"
            >
              <Row-Col span="1">
                <Tooltip
                  max-width="400"
                  :content="'This post is ineligible for instagram. ' + post.instaEligibilityMsg"
                  placement="right-start"
                  :transfer="true"
                >
                  <i class="fa fa-exclamation-triangle"></i>
                </Tooltip>
              </Row-Col>
              <Row-Col class="cl-widget-text fp-dimed" span="19" offset="1">
                <span> {{ post["title"] }} </span>
              </Row-Col>
              <Row-Col span="2" offset="1" class="list-image-wrapper fp-dimed">
                <img v-lazy="post['imgUrl']" class="list-image" />
              </Row-Col>
            </Row>
          </template>
        </template>
  </div>
</template>

<script>
import Vue from "vue";
import { Row, Col, Tooltip } from "iview";
import { APIService } from "./../ApiService.js";
import { mapState, mapMutations } from "vuex";
import VueLazyload from "vue-lazyload";
import FacebookCampaignLauncherConfig from "../Mixins/FacebookCampaignLauncherConfig.js";

Vue.use(VueLazyload, {
  attempt: 1
});

export default {
  components: {
    Row,
    "Row-Col": Col,
    Tooltip
  },
  props: {
    searchQuery: { default: null },
    allPosts: { default: () => { return [] } },
    isFacebookPosts: { default: true }
  },
  mixins: [FacebookCampaignLauncherConfig],
  data () {
    return {
      processingData: false,
      filteredPosts: [],
      posts: [],
      searchPost: "",
      filterTimeout: null,
      checkValidPostTimeout: null,
      alert: {},
      postIdsaddedToAdSheet: {}
    };
  },
  mounted () {},
  watch: {
    allPosts () {
      this.posts = this.processPosts(this.allPosts);
      if (this.posts && this.posts.length) {
        this.$store.state.posts = this.posts
      } else {
        this.$store.state.posts = []
      }
      this.filteredPosts = this.posts;
    },
    searchQuery () {
      clearTimeout(this.filterTimeout)
      clearTimeout(this.checkValidPostTimeout)
      if (this.isFacebookPosts) {
        this.alert.show = false

        if (!this.searchQuery) {
          this.filteredPosts = this.posts
          this.processingData = false
          return
        }

        this.processingData = true
        var that = this;
        this.filterTimeout = setTimeout(() => {
          let postId = that.searchQuery
          if (that.searchQuery.split("_").length == 1) {
            postId = `${this.selectedCampaignLauncherConfig.facebookPageId}_${postId}`
          }
          // if (/^\d+$/.test(postId) == false) {
          //   that.alert = {
          //     show: true,
          //     message: "Please enter a valid Post Id",
          //     type: "alert-warning"
          //   }
          //   that.filteredPosts = []
          //   that.processingData = false
          //   return
          // }

          that.filteredPosts = that.posts.filter(x => x.pagePostId.includes(postId))
          if (that.filteredPosts && that.filteredPosts.length == 0) {
            APIService.getPostById(
              that.businessProfilesSearchEngineId,
              postId,
              false
            ).then(res => {
              let postInfo = JSON.parse(res.data.Data.PostInfo)
              that.addNewPost(postInfo)
            }).catch(() => {
              that.alert = {
                show: true,
                message: `Could not find Post with Id ${that.searchQuery}`,
                type: "alert-warning"
              }
            }).finally(() => {
              that.processingData = false
            })
          } else {
            that.processingData = false
          }
        }, 400)
      }
    }
  },
  computed: {
    ...mapState([
      "businessProfilesSearchEngineId",
      "businessProfileId",
      "selectedCampaignLauncherConfig",
      "existingPostAds",
      "selectedFbObjective",
      "selectedPublisher",
      "publisher"
    ]),
    isInstagramPlacement () {
      if (this.selectedPublisher === this.publisher.Facebook) {
        return this.selectedCampaignLauncherConfig.adset.selectedPlacementOption != 'Automatic' && this.isInstagramPlacementSelected(this.selectedCampaignLauncherConfig);
      }
      return false
    }
  },
  methods: {
    ...mapMutations(["set_existingPostAds"]),
    addOrRemovePostFromAdSheet (post) {
      if (post.pagePostId in this.postIdsaddedToAdSheet) {
        this.removeFromAdSheet(post)
        this.$delete(this.postIdsaddedToAdSheet, post.pagePostId)
      } else {
        this.addToAdSheet(post)
        this.$set(this.postIdsaddedToAdSheet, post.pagePostId, true)
      }
    },
    addToAdSheet (post) {
      this.set_existingPostAds([...this.existingPostAds, post]);
      this.$set(this.postIdsaddedToAdSheet, post.pagePostId, true)
    },
    removeFromAdSheet (post) {
      let indexToRemove = this.existingPostAds.findLastIndex(x => x.pagePostId == post.pagePostId)

      if (indexToRemove != -1) {
        this.set_existingPostAds(
          this.existingPostAds.filter((x, index) => index != indexToRemove)
        );
      }
      this.$delete(this.postIdsaddedToAdSheet, post.pagePostId)
    },
    filterByObjective (post) {
      const mediaType = (post.attachments || { data: [{}] }).data[0].media_type || "";
      if (this.isConversionsObjective(this.selectedCampaignLauncherConfig) || this.isTrafficObjective(this.selectedCampaignLauncherConfig)) {
        return post.call_to_action || mediaType === "link";
      }
      if (this.isVideoViewsObjective(this.selectedCampaignLauncherConfig)) {
        return mediaType === "video";
      }
      return true;
    },
    formatPost (post) {
      const postData = (post.attachments || { data: [{}] }).data[0];

      const pagePostId = post.id;
      const postUrl = postData.url;
      const imgUrl = (postData.media || {}).image;
      const title = postData.title || "This post doesn't have a title";
      const isInstaEligible = post.is_instagram_eligible;
      let instaEligibilityMsg = "";
      if (post.instagram_eligibility && post.instagram_eligibility != "ineligible_unknown_error") {
        instaEligibilityMsg = "Reason: " + post.instagram_eligibility.replaceAll("_", " ")
      }

      return {
        pagePostId,
        postUrl,
        imgUrl,
        title,
        isInstaEligible,
        instaEligibilityMsg
      };
    },
    processPosts (allPosts) {
      let eligiblePosts = allPosts.filter(
        x => x.is_eligible_for_promotion && !x.is_expired
      );
      return eligiblePosts.filter(this.filterByObjective).map(this.formatPost);
    },
    addNewPost (postData) {
      let errorMessage = "";

      if (!postData.is_eligible_for_promotion) {
        errorMessage = `The Post with Id ${this.searchQuery} is not eligible for promotion`
      } else if (postData.is_expired) {
        errorMessage = `The Post with Id ${this.searchQuery} has expired`
      } else if (this.filterByObjective(postData) == false) {
        errorMessage = `The Post with Id ${this.searchQuery} is not valid for the selected facebook objective`
      }

      if (errorMessage !== "") {
        this.alert = {
          show: true,
          message: errorMessage,
          type: "alert-warning"
        }
      } else {
        let formattedPost = this.formatPost(postData)
        this.filteredPosts = [formattedPost]
        this.posts.push(formattedPost)
      }
    },
    isPostInstaEligible (post) {
      if (this.isInstagramPlacement) {
        return true; // hardcoded true for now. post.isInstaEligible;
      }
      return true;
    }
  }
};
</script>

<style>
.input-search-bar .ivu-input {
  height: 30px;
}
</style>
<style scoped>
.fp-dimed {
  opacity: 0.5;
}
.flex-center {
  height: 28px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.flex-center > i {
  font-size: 16px;
}
.list-image-poptip {
  max-width: 250px;
  max-height: 250px;
}
.list-image-wrapper {
  position: relative;
  width: 2rem;
  height: 2rem;
  float: right;
}
.list-image {
  max-width: 100%;
  max-height: 100%;
  position: absolute;
  top: 50%;
  left: 50%;
  transform-origin: center;
  transition: transform 0.05s linear;
  transform: translate(-50%, -50%);
}
</style>
