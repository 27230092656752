import axiosInstance from '../../../Api'
let ApiService = {
  getBusinessProfiles () {
  // eslint-disable-next-line no-undef
    const getAllBusinessProfiles = secretKeyParams.apiBaseUrl
    // eslint-disable-next-line no-undef
      .concat(`portals/${secretKeyParams.portalId}/BusinessProfiles?userId=${secretKeyParams.userId}`)

    const promise = axiosInstance.get(getAllBusinessProfiles)
    return promise
  },

  getAllPortalUsers () {
    // eslint-disable-next-line no-undef
    const getAllStatuses = secretKeyParams.apiBaseUrl
    // eslint-disable-next-line no-undef
      .concat(`portals/${secretKeyParams.portalId}/users`)

    const promise = axiosInstance.get(getAllStatuses)
    return promise
  },

  getMediaPlanObjectives (businessProfileId) {
    // eslint-disable-next-line no-undef
    const mediaPlanObjectivesUrl = secretKeyParams.apiBaseUrl
      .concat(`businessProfiles/${businessProfileId}/`)
      .concat(`budgetcenter/Objectives`)
    const promise = axiosInstance.get(mediaPlanObjectivesUrl);
    return promise;
  },
  getAllBriefs (businessProfileId) {
    // eslint-disable-next-line no-undef
    const getBriefs = secretKeyParams.apiBaseUrl
      .concat(`businessProfiles/${businessProfileId}/`)
      .concat(`brief`)
    const promise = axiosInstance.get(getBriefs);
    return promise;
  },
  updateBrief (businessProfileId, briefId, properties) {
    // eslint-disable-next-line no-undef
    const updateBriefUrl = secretKeyParams.apiBaseUrl
      .concat(`businessProfiles/${businessProfileId}/`)
      .concat(`Brief/${briefId}`)
    const promise = axiosInstance({
      method: 'patch',
      url: updateBriefUrl,
      data: '"' + properties + '"'
    })
    return promise
  },
  async getPhasesByBriefId (briefBusinessProfileId, briefIds) {
    // eslint-disable-next-line no-undef
    let url = secretKeyParams.apiBaseUrl
      .concat(`businessProfiles/${briefBusinessProfileId}/`)
      .concat(`BriefPhases`);
    let res = await axiosInstance.get(url, {
      params: {
        briefIds: briefIds
      } });
    return res.data.data;
  },
  editBrief (businessProfileId, briefId, object) {
    // eslint-disable-next-line no-undef
    const editBriefUrl = secretKeyParams.apiBaseUrl
      .concat(`businessProfiles/${businessProfileId}/`)
      .concat(`Brief/${briefId}`)
    const promise = axiosInstance({
      method: 'put',
      url: editBriefUrl,
      data: object
    })
    return promise
  }
}
export default ApiService;
