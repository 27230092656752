<template>
  <div>
      <div class="businessprofiles-container">
        <Row type="flex" align="middle" justify="space-between">
            <Column span="5">
                <button
                class="btn btn-success"
                @click="createBPPage()"
                >
                <span class="fa fa-plus"></span> Create Business Profile
                </button>

            </Column>
            <Column span="2">
                <div class="header">
                </div>
            </Column>

            <Column span="5" class="search" >
                <div class="input-group col-md-12">
                <input v-model="searchQuery" type="search" placeholder="Search by BP Id or Name" class=" form-control grey-border " />
                <span class="input-group-addon grey-border"><i class="fa-search"> </i></span>
                </div>
            </Column>
        </Row>
        <span class="br"></span>
        <Row type="flex" align="middle" justify="space-between">
          <Column span="24">
              <Alert v-if="responsePrompt==1" type="success" show-icon closable  @on-close="responsePrompt=0">
                  <span slot="desc">{{responseMessage}} </span>
              </Alert>
            </Column>
        </Row>

        <div>
          <i-table
            ref="iViewTable"
            class="ro-table"
            :columns="iColumns"
            :data="bpPageList"
            :no-data-text="noDataText"
            :loading="loadingGrid"
            border

          >
            <template slot-scope="{ row }" slot="businessProfile">
              <Row type="flex" justify="center" align="middle">
                <Column v-if="row.originalLogo!=''" class="col-md-5 logo-div">
                  <img class="logo" :src="imgPath+row.originalLogo">
                </Column>
                <Column v-else class="col-md-5 logo-div">
                  <img class="logo" :src="imgPath+noLogoPath">
                </Column>
                <Column align ="left" class="col-md-7 name-div">{{ row.name }}</Column>
              </Row>
            </template>
            <template slot-scope="{ row }" slot="BPID">
              <div>{{ row.id }}</div>
            </template>
            <template slot-scope="{ row }" slot="users">

                <div v-if="row.users.length>=8">
                  <div class="user-list" v-for="user in row.users.slice(0,7)" :key="user.id">{{ user.name}}, &nbsp;</div>
                  <div class="user-list" v-for="user in row.users.slice(7,8)" :key="user.id">{{ user.name}} &nbsp;</div>
                  <div class="user-list" v-if="row.users.length>8"> and <a @click="onClickEditBPUsers(row)">{{row.users.length - 8}} More</a></div>
                </div>
                <div v-else>
                  <div class="user-list" v-for="user in row.users.slice(0,row.users.length-1)" :key="user.id">{{ user.name}}, &nbsp;</div>
                  <div class="user-list" v-for="user in row.users.slice(row.users.length-1,row.users.length)" :key="user.id">{{ user.name}} &nbsp;</div>
                </div>
            </template>

            <template slot-scope="{ row }" slot="actions" >
                <i-tooltip content="Edit Users" >
                  <span class="action-button" @click="onClickEditBPUsers(row)" >
                      <i class="fa-soild fa-users" aria-hidden="true"></i>
                  </span>
                </i-tooltip>

                <i-tooltip  content="Update" >
                  <span class="action-button"  @click="editBusinessProfile(row.id), showUpdateBPModal=true, businessProfileId=row.id, bpLogo=row.originalLogo">
                    <i class="fa-soild fa-gear" aria-hidden="true"></i>
                  </span>
                </i-tooltip>

                <i-tooltip  content="Mark for deletion" v-if="row.status==2">
                  <span class="action-button" @click="showConfirmation=true, businessProfileId=row.id, statusValue=4">
                    <i class="fa-soild fa-trash-o" aria-hidden="true"></i>
                  </span>
                </i-tooltip>
                <i-tooltip  content="Unmark from deletion" v-if="row.status==4">
                  <span class="action-button deleted-bp" @click="showConfirmation=true, businessProfileId=row.id, statusValue=2">
                    <i class="fa-soild fa-trash-o" aria-hidden="true"></i>
                  </span>
                </i-tooltip>
            </template>
          </i-table>

      <br/>
        <Row type="flex" class="page-div" align="middle" justify="space-between">
                <Column span="12">
                </Column>
                <column span="10" class="pages">
                    <i-page
                        class="ivu-page"
                        :current="currentPage"
                        :total="totalRecords"
                        :page-size="pageSize"
                        @on-change="changePage"
                    />
                </column>
      </Row>
      </div>

      </div>

    <i-modal v-model="showConfirmation" class-name="modal_placement" :closable="false" title="Confirm">
      <div class="body" style="text-align: start;" v-if="statusValue==4">
        Do you want to mark the BP '{{businessProfileId}}' for deletion?
      </div>
      <div class="body" style="text-align: start;" v-if="statusValue==2">
        Do you want to unmark the BP '{{businessProfileId}}' from deletion?
      </div>
      <div slot="footer">
            <button class="btn btn-white" @click="showConfirmation=false">Close</button>
            <button   class="btn btn-success" @click="deleteBusinessProfile(businessProfileId)"><span v-if="saveLoader"><i class="fa-spinner fa-spin" aria-hidden="true"></i></span><span v-if="!saveLoader">Continue</span></button>
      </div>
    </i-modal>

    <div>
    <i-modal class-name="modal_placement" v-model="showEditUsersModal" scrollable width="1143" :title = modalTitle @on-cancel="closeModal()">
        <div v-if="isLoading" class="bp-loader">
          <i class="fa-spinner fa-spin fa-2x" aria-hidden="true"></i>
        </div>
        <div v-if="!isLoading" style="height : 480px">
            <Row>
                <Column span="8" class="align-item-middle select-all">
                  <i-checkbox v-model="selectAll" @on-change="onSelectAll()"><span class="select-all-checkbox"> Select All</span></i-checkbox>
                </Column>
                <Column span="8" class="align-item-middle">
                  {{totalSelectedUsersCount}} Users selected
                </Column>
                <column span="8">
                    <div class="input-group col-md-12">
                        <i-input type="text" v-model="search" placeholder="Search" class="grey-border" />
                        <span class="input-group-addon grey-border" @click="filteredUsers()"><i class=" search fa-search"></i></span>
                    </div>
                </column>
            </Row>
            <div class="tables-div">
              <div class="col-md-5 users-div">
                <div v-for="data in filteredUsersData" :key="data.id" class="row user-row">
                  <div v-if = "data.status==0" class="col-md-1 align-item-middle">
                    <i-checkbox disabled v-model="data._checked" ></i-checkbox>
                  </div>
                  <div v-else class="col-md-1 align-item-middle">
                    <i-checkbox v-model="data._checked" @on-change="onSelect(data)"></i-checkbox>
                  </div>
                  <div class="col-md-10 align-item-middle">
                    <div class="user-name">{{data.name }}</div>
                    <div class="user-email">{{data.email }}</div>
                  </div>

                </div>
              </div>
              <div class="col-md-7 users-assigned-div no-border-left">
                <div v-for="row in iDataAssigned" :key="row.id" class="row user-assigned-row">
                  <div class="col-md-7 align-item-middle">
                    <div class="user-name">{{row.name }}</div>
                    <div class="user-email">{{row.email }}</div>
                  </div>
                  <div v-if = "row.status==0" class="col-md-3 padding">
                    <i-select disabled v-model="row.roleId">
                      <i-option v-for="role in roles" :value="role.id" :key="role.id">{{role.name}}</i-option>
                    </i-select>
                  </div>
                  <div v-else class="col-md-3 padding">
                    <i-select v-model="row.roleId" filterable @on-change="UpdateRole(row)">
                      <i-option v-for="role in roles" :value="role.id" :key="role.id">{{role.name}}</i-option>
                    </i-select>
                  </div>
                    <div class="action-btn col-md-2">
                      <div v-if = "row.status==1" class="filter-icon">
                          <span v-show="row.roleId === 9" @click="OpenDashboardRoleFilter(row)">
                            <i class="fa-solid fa-filter fa-lg" aria-hidden="true"></i>
                          </span>
                          <span v-show="row.roleId === 16" @click="OpenDivisionFilter(row)">
                            <i class="fa-solid fa-filter fa-lg" aria-hidden="true"></i>
                          </span>
                          <span v-show="row.roleId === 10" @click="OpenCampaignMonitorFilter(row)">
                            <i class="fa-solid fa-filter fa-lg" aria-hidden="true"></i>
                          </span>
                          &nbsp;
                      </div>
                      <div v-else class="filter-icon">
                        &nbsp;
                      </div>
                      <div class="remove-icon">
                            <span @click="RemoveAccess(row)">
                              <i class="fa fa-times fa-fw fa-lg" aria-hidden="true"></i>
                            </span>
                      </div>
                    </div>
                </div>
              </div>
            </div>
        </div>
      <div slot="footer">
        <button class="btn btn-white" @click="closeModal()">Cancel</button>
        <button class="btn btn-success" @click.prevent="onAccessSave()"><span v-if="saveLoader"><i class="fa-spinner fa-spin" aria-hidden="true"></i></span><span v-if="!saveLoader">Save</span></button>
      </div>
    </i-modal>
    <division-access
      ref="divisionAccess"
      :setSelectedData="selectedDivisions"
      v-on:OnSave="OnSave"
    >
    </division-access>
    <dashboard-role-access
      ref="dashboardRoleAccess"
      :setDashboardData="selectedDashboard"
      v-on:OnDashboardAccessSave="OnDashboardAccessSave"
    ></dashboard-role-access>
    <campaign-monitor-access
      :isCampaignMonitorAccess="isCampaignMonitorAccess"
      :userId="userId"
      :businessProfileId="businessProfileId"
      :setCmaData="selectedCMA"
      @CloseCampaignMonitorModal="CloseCampaignMonitorModal"
      v-on:OnCampaignMonitorAccessSave="OnCampaignMonitorAccessSave"
      ref="cma"
    ></campaign-monitor-access>
  </div>

    <div>
    <i-modal v-model="showUpdateBPModal" class-name="modal_placement" scrollable :mask-closable="false" @on-cancel="showUpdateBPModal=false, resetFeilds()" title="Update Business Profile">
          <div v-if="isLoading" class="modal-loader">
            <i class="fa-spinner fa-spin fa-2x" aria-hidden="true"></i>
          </div>
          <div v-if="!isLoading">
            <div class="tab-content">
              <div id="user-tab" class="body">
                      <Row class="row" type="flex" align="middle">
                          <Column class = "input-label" span="8">
                              <span class="label-span"><b>Name</b></span>
                              <span class="f_req">&nbsp;*</span>
                          </Column>
                          <Column class="input-field" span="12">
                              <i-input v-model="bpName" :class="nameErrorMessage" @on-change="nameErrorMessage = ''" placeholder="Name"/>
                          </Column>
                      </Row>
                      <Row v-if="nameErrorMessage != ''">
                        <Column class="error-message" span="12" offset="8">
                          <span style="color:#b94a48">{{ nameErrorMessageText }}</span>
                        </Column>
                      </Row>
                      <br>
                      <Row class="row" type="flex" align="middle">
                          <Column class = "input-label" span="8">
                              <span class="label-span"><b>Logo</b></span>
                          </Column>
                          <Column class="input-field" span="12" align="left">
                              <div class="upload-file" v-if="bpLogoName == ''">
                                <i-upload
                                action=""
                                :before-upload="uploadFile"
                                 :format="['jpg','jpeg','png']"
                                accept="image/png, image/jpeg, image/jpg"
                                name="logo"
                                >
                                      <button class="btn btn-success">Update Logo</button>
                                </i-upload>
                            </div>
                            <div
                                v-if="bpLogoName != ''"
                                class="upload-list">
                                    <span>
                                        <span><strong>{{bpLogoName}}</strong></span>
                                        <button type="button" class="close" data-dismiss="alert" @click="logo='', bpLogoName=''">&times;</button>
                                    </span>
                            </div>
                          </Column>
                      </Row>

                      <Row class="row" type="flex" align="middle">
                          <Column v-if="logoErrorMessage != ''"  class="error-message" span="24" >
                            <span style="color:#b94a48">{{ logoErrorMessage }}</span>
                          </Column>
                          <Column class = "input-label" span="8">
                          </Column>
                          <Column class="input-field" span="12" align="left">
                             <div><img class="logo" :src="imgPath+bpLogo"></div>
                          </Column>
                      </Row>
                      <br>
                      <Row class="row" type="flex" align="middle">
                          <Column class = "input-label" span="8">
                              <span class="label-span"><b>Currency</b></span>
                          </Column>
                          <Column class="input-field" span="12" align="left">
                              <span><b>{{bpCurrency}}</b></span>
                          </Column>
                      </Row>
                      <br>
                      <Row class="row" type="flex" align="middle">
                          <Column class = "input-label" span="8">
                              <span class="label-span"><b>Time Zone</b></span>
                          </Column>
                          <Column class="input-field" span="12" align="left">
                              <span><b>{{bpTimeZone}}</b></span>
                          </Column>
                      </Row>
                      <br>
                       <Row class="row" type="flex" align="middle">
                          <Column class = "input-label" span="8">
                              <span class="label-span"><b>Website URL</b></span>
                          </Column>
                          <Column class="input-field" span="12">
                              <i-input v-model="bpWebsiteURL"  placeholder="Website URL"/>
                          </Column>
                      </Row>
                      <br>
                      <Row class="row" type="flex" align="middle">
                          <Column class = "input-label" span="8">
                              <span class="label-span"><b>Category</b></span>
                          </Column>
                          <Column class="input-field" span="12">
                              <Select v-model="bpCategory"  filterable>
                                  <Option v-for="i in bpCategories" :value="i.name" :key="i.id">{{ i.name }}</Option>
                              </Select>
                          </Column>
                      </Row>
                      <br>
                      <Row class="row" type="flex" align="middle">
                          <Column class = "input-label" span="8">
                              <span class="label-span"><b>Sub-Category</b></span>
                          </Column>
                          <Column class="input-field" span="12">
                                <Select v-model="bpSubCategory" filterable>
                                    <Option v-for="i in bpSubCategories" :value="i.name" :key="i.id">{{ i.name }}</Option>
                                </Select>
                          </Column>
                      </Row>
              </div>
            </div>
          </div>
          <div slot="footer">
              <button class="btn btn-white" @click="showUpdateBPModal=false, resetFeilds()">Close</button>
            <button class="btn btn-success" @click="updateBusinessProfile(businessProfileId)" sh><span v-if="saveLoader"><i class="fa-spinner fa-spin" aria-hidden="true"></i></span><span v-if="!saveLoader">Update</span></button>
          </div>
        </i-modal>
    </div>
  </div>
</template>

<script>
import { Page, Table, Checkbox, Tooltip, Row, Col, Input, Alert, Upload, Option, Select, Modal, locale } from "iview";
import DivisionAccess from '../../DivisionAccess/Components/DivisionAccess.vue';
import CampaignMonitorAccess from "../../CampaignMonitorAccess/Components/CampaignMonitorAccess.vue";
import DashboardRoleAccess from '../../DashboardRoleAccess/Components/DashboardRoleAccess.vue';
import { ApiService } from '../Services/ApiService.js';
import lang from "iview/dist/locale/en-US";
locale(lang);
export default {

  components: {
    "i-table": Table,
    "i-page": Page,
    "i-tooltip": Tooltip,
    Row,
    "i-modal": Modal,
    "i-checkbox": Checkbox,
    "i-select": Select,
    "i-option": Option,
    "i-upload": Upload,
    "i-input": Input,
    "Column": Col,
    "division-access": DivisionAccess,
    "dashboard-role-access": DashboardRoleAccess,
    "campaign-monitor-access": CampaignMonitorAccess,
    Alert,
    Option,
    Select
  },

  data () {
    return {

      response: [],
      iColumns: [
        {
          title: "Business Profile",
          resizable: true,
          key: "businessProfile",
          slot: "businessProfile",
          width: 420,
          align: "center"
        },
        {
          title: "BPID",
          resizable: true,
          key: "BPID",
          align: "center",
          slot: "BPID",
          width: 125
        },
        {
          title: "Users",
          key: "users",
          align: "center",
          slot: "users"
        },
        {
          title: "Actions",
          resizable: true,
          key: "actions",
          align: "center",
          slot: "actions",
          width: 140
        }

      ],
      responsePrompt: 0,
      loadingGrid: false,
      bpPageList: [],
      bpData: [],
      bpCategories: [],
      bpAllSubCategories: [],
      searchQuery: '',
      noDataText: "No data available",
      currentPage: 1,
      pageSize: 25,
      responseMessage: 'Deleted Successfully !!',
      showConfirmation: false,
      showUpdateBPModal: false,
      bpName: '',
      bpLogo: '',
      bpLogoName: '',
      bpWebsiteURL: '',
      bpCurrency: 'Indian Rupee (INR Rs.)',
      bpTimeZone: 'India - Calcutta',
      bpCategory: '',
      bpCategoryId: null,
      bpSubCategory: '',
      bpSubCategoryId: null,
      isLoading: false,
      saveLoader: false,
      statusValue: null,

      bp: null,
      file: '',
      nameErrorMessage: '',
      nameErrorMessageText: '',
      logoErrorMessage: '',

      showEditUsersModal: false,
      iDataAssigned: [],
      businessProfileId: null,
      role: 4,
      roles: [],
      bpIds: [],
      search: "",
      userId: null,
      users: [],
      assignedUsers: [],
      filteredUsersData: [],
      isChecked: false,
      isCampaignMonitorAccess: false,
      watchObject: [],
      initialState: [],
      selectedDivisions: [],
      selectedDashboard: {},
      selectedCMA: {},
      selectedUsers: [],
      selectAll: false,
      noLogoPath: 'no_logo_bp.png',
      editBPObj: null,
      isLogoUpdated: false
    }
  },
  computed: {
    totalRecords () {
      return this.bpList.length;
    },
    bpSubCategories () {
      if (this.bpCategory != '') {
        var obj = this.bpCategories.filter(bp => bp.name.includes(this.bpCategory));
        if (obj[0] != null) {
          var id = obj[0].id;
          return this.bpAllSubCategories.filter(bp => bp.parentId.toString().includes(id.toString()));
        }
        return []
      } else {
        return []
      }
    },
    imgPath () {
      var path = ApiService.getPath();
      return path;
    },
    modalTitle () {
      const title = "Add/remove Users for ";
      return title + this.bpName;
    },
    totalSelectedUsersCount () {
      if (this.iDataAssigned.length == this.filteredUsersData.length) {
        this.selectAll = true;
      }
      this.selectAll = false;
      return this.iDataAssigned.length;
    },
    bpList () {
      if (this.searchQuery != '') {
        var filteredBPData = this.bpData.filter((bp) => {
          return bp.id.toString().indexOf(this.searchQuery) >= 0 || bp.name.toLowerCase().indexOf(this.searchQuery.toLowerCase()) >= 0
        });
        return filteredBPData;
      }
      return this.bpData;
    }
  },
  watch: {
    search () {
      this.filteredUsers();
    },
    searchQuery () {
      this.changePage(1);
    }
  },
  methods: {
    resetFeilds () {
      this.businessProfileId = null;
      this.bpName = '';
      this.bpCurrency = '';
      this.bpTimeZone = '';
      this.bpWebsiteURL = '';
      this.bpCategory = '';
      this.bpSubCategory = '';
      this.nameErrorMessage = '';
      this.nameErrorMessageText = '';
      this.logoErrorMessage = '';
    },
    updateResponsePrompt () {
      this.responsePrompt = 1
    },
    getBusinessProfiles () {
      var self = this;
      var promise = [];
      self.loadingGrid = true;
      var responseData = ApiService.getBusinessProfiles();
      promise.push(responseData);
      Promise.all(promise).then(function (response) {
        self.bpData = response[0].data.data;
        self.changePage(self.currentPage);
        self.loadingGrid = false;
      });
    },

    deleteBusinessProfile (id) {
      var self = this;
      self.saveLoader = true;
      var promise = [];
      var responseData = ApiService.deleteBusinessProfile(id, this.statusValue);
      promise.push(responseData);
      Promise.all(promise).then(function (response) {
        self.responseMessage = response[0].data.message;
        self.responsePrompt = 1;
        self.saveLoader = false;
        self.getBusinessProfiles();
        self.showConfirmation = false;
      });
    },
    editBusinessProfile (id) {
      var self = this;
      var promise = [];
      this.businessProfileId = id;
      self.showUpdateBPModal = true;
      var responseData = ApiService.getBusinessProfileById(id);
      promise.push(responseData);
      Promise.all(promise).then(function (response) {
        self.editBPObj = response[0].data.data;
        self.bpName = self.editBPObj.name;
        self.bpWebsiteURL = self.editBPObj.websiteUrl;
        self.bpCategory = self.editBPObj.category;
        self.bpSubCategory = self.editBPObj.subCategory;
        self.bpCurrency = self.editBPObj.currency.description;
        self.bpTimeZone = self.editBPObj.timeZone.displayName;
      });
    },
    getCategories () {
      var self = this;
      var promise = [];
      var responseData = ApiService.getCategories();
      promise.push(responseData);
      Promise.all(promise).then(function (response) {
        self.bpCategories = response[0].data.data;
      });
    },
    getAllSubCategories () {
      var self = this;
      var promise = [];
      var responseData = ApiService.getSubCategories();
      promise.push(responseData);
      Promise.all(promise).then(function (response) {
        self.bpAllSubCategories = response[0].data.data;
      });
    },
    getPageList (p, size) {
      let list = this.bpList.slice((p - 1) * size, p * size);
      return list;
    },
    changePage (p) {
      this.currentPage = p;
      this.bpPageList = this.getPageList(this.currentPage, this.pageSize);
    },
    changePageSize () {
      this.changePage(1);
    },
    uploadFile (file) {
      var extension = file.name.substring(file.name.lastIndexOf('.') + 1, file.name.length) || file.name;
      if (extension == 'png' || extension == 'jpg' || extension == 'jpeg' || extension == 'PNG' || extension == 'JPG' || extension == 'JPEG') {
        this.file = file;
        this.logoName = file.name;
        this.logoErrorMessage = '';
        this.uploadLogo(this.businessProfileId);
      } else {
        this.logoErrorMessage = 'Invalid file format,format should be one of [png,jpg,jpeg]';
      }
    },
    uploadLogo (id) {
      var self = this;
      var formData = new FormData();
      formData.append("logoFile", self.file);
      ApiService.UploadLogo(id, formData)
        .then(resp => {
          self.bpLogo = resp.data.data.originalLogo;
          self.isLogoUpdated = true;
        });
    },

    updateBusinessProfile (id) {
      var areAllFieldsValid = this.ValidateFields();
      if (areAllFieldsValid) {
        var self = this;
        self.saveLoader = true;
        var obj = {};
        if (self.bpName != self.editBPObj.name) {
          obj['name'] = self.bpName;
        }
        if (self.bpWebsiteURL != self.editBPObj.websiteUrl) {
          obj['websiteUrl'] = self.bpWebsiteURL;
        }
        if (self.bpCategory != self.editBPObj.category) {
          obj['category'] = self.bpCategory;
        }
        if (self.bpSubCategory != self.editBPObj.subCategory) {
          obj['subCategory'] = self.bpSubCategory;
        }
        if (self.isLogoUpdated) {
          obj['originalLogo'] = self.bpLogo;
          obj['thumbnailLogo'] = self.bpLogo;
          obj['scaledLogo'] = self.bpLogo;
        }

        var updatedBP = { data: obj }

        const data = JSON.stringify(updatedBP);
        var promise = [];
        var responseData = ApiService.UpdateBusinessProfile(id, "'" + data + "'");
        promise.push(responseData);
        Promise.all(promise).then(function (response) {
          self.responseMessage = response[0].data.message;
          self.saveLoader = false;
          self.showUpdateBPModal = false;
          self.resetFeilds();
          self.getBusinessProfiles();
        });
      }
    },
    getPortalUsers () {
      var self = this;
      self.selectAll = false;
      self.selectedUsers = [];
      var promise = [];
      var responseData = ApiService.getPortalUsers();
      promise.push(responseData);
      Promise.all(promise).then(function (response) {
        var data = response[0].data;
        var IsChecked = false;
        self.users = data.map(u => {
          var obj = {
            "id": u.id,
            "name": u.firstName,
            "email": u.loginName,
            "_checked": IsChecked,
            "status": u.status,
            "roleId": 4
          };
          return obj;
        });

        for (var i = 0; i < self.assignedUsers.length; i++) {
          var index = self.users.findIndex(x => x.id == self.assignedUsers[i].id);
          if (index >= 0) {
            IsChecked = true;
            var obj = self.users[index];
            obj.roleId = self.assignedUsers[i].roleId;
            obj._checked = IsChecked;
          }
        }
        for (i = 0; i < self.assignedUsers.length; i++) {
          self.selectedUsers.push(self.assignedUsers[i].id);
        }
        self.initialState = [];
        self.filteredUsers();
        self.filteredUsersData.forEach((user) => self.initialState.push({ id: user.id, checked: user._checked, roleId: user.roleId }));
        self.isLoading = false;
      });
    },
    filteredUsers () {
      var self = this;
      var filteredData = self.users.filter((user) => {
        return ((user.name.toLowerCase().indexOf(self.search.toLowerCase()) >= 0 || user.email.toLowerCase().indexOf(self.search.toLowerCase()) >= 0) && (user.status == 1 || user._checked == true));
      });
      self.filteredUsersData = filteredData;
      self.iDataAssigned = self.filteredUsersData.filter(x => x._checked == true);
      if (self.iDataAssigned.length == self.filteredUsersData.length) {
        self.selectAll = true;
      }
    },
    filteredUsersOnSelect (id) {
      this.selectAll = false;
      this.iDataAssigned = [];
      if (id != -1) {
        var newlySelectedUserIndex = this.users.findIndex(x => x.id == id);
        if (newlySelectedUserIndex >= 0) {
          var newlySelectedUser = this.users[newlySelectedUserIndex];
          this.iDataAssigned.push(newlySelectedUser);
        }
        var assignedUsers = this.users.filter(x => x._checked == true);
        assignedUsers.forEach((user) => {
          if (user.id != id) {
            this.iDataAssigned.push(user);
          }
        })
      }
      if (this.iDataAssigned.length == this.filteredUsersData.length) {
        this.selectAll = true;
      }
    },
    onSelectAll () {
      if (this.selectAll) {
        this.selectedUsers = [];
        this.filteredUsersData.forEach(x => {
          this.selectedUsers.push(x.id);
        });
        var data;
        this.onSelect(data);
      } else {
        this.selectedUsers = [];
        this.onSelect(data);
      }
    },
    onSelect (data) {
      if (data != undefined) {
        if (data._checked == true) {
          this.selectedUsers.push(data.id);
        } else {
          var ind = this.selectedUsers.findIndex(x => x == data.id);
          if (ind >= 0) {
            this.selectedUsers.splice(ind, 1);
          }
        }
      }
      this.filteredUsersData.forEach((user) => {
        var a = this.selectedUsers;
        if (a.includes(user.id)) {
          var index = this.watchObject.findIndex(x => x.id == user.id && x.delete == 1)
          let initialStateObj = this.initialState.find(ind => ind.id == user.id);
          if (index >= 0) {
            user._checked = true;
            this.watchObject.splice(index, 1);
          } else if (initialStateObj != null && initialStateObj != undefined && !initialStateObj.checked) {
            user._checked = true;
            var obj = {
              "id": user.id,
              "insert": 1,
              "roleId": user.roleId
            }
            index = this.watchObject.findIndex(x => x.id == user.id && x.insert == 1)
            if (index < 0) {
              this.watchObject.push(obj);
            }
          } else {
            user._checked = true;
          }
        } else {
          index = this.watchObject.findIndex(x => x.id == user.id && x.delete == 1)
          let initialStateObj = this.initialState.find(ind => ind.id == user.id);
          if (initialStateObj != null && initialStateObj != undefined && initialStateObj.checked && index < 0) {
            obj = {
              "id": user.id,
              "delete": 1,
              "roleId": user.roleId
            }
            this.watchObject.push(obj);
          }
          user._checked = false;
          index = this.watchObject.findIndex(x => x.id == user.id && x.insert == 1)
          if (index >= 0) {
            this.watchObject.splice(index, 1);
          }
        }
      })
      var n = this.selectedUsers.length;
      var id = -1;
      if (n > 0) {
        id = this.selectedUsers[n - 1];
      }
      this.filteredUsersOnSelect(id);
    },
    UpdateRole (currentValue) {
      var i = this.filteredUsersData.findIndex((x) => x.id == currentValue.id);
      let initialStateObj = this.initialState.find(ind => ind.id == currentValue.id);
      if (initialStateObj != null && initialStateObj != undefined && !initialStateObj.checked) {
        var index = this.watchObject.findIndex(x => x.id == this.filteredUsersData[i].id && x.insert == 1);
        if (index >= 0) {
          this.filteredUsersData[i].roleId = currentValue.roleId;
          var obj = this.watchObject[index];
          obj.roleId = currentValue.roleId;
        }
      }
      if (currentValue.roleId != initialStateObj.roleId) {
        this.filteredUsersData[i].roleId = currentValue.roleId;
        index = this.watchObject.findIndex(x => x.id == this.filteredUsersData[i].id && x.update == 1);
        if (index < 0 && initialStateObj != null && initialStateObj != undefined && initialStateObj.checked) {
          obj = {
            "id": currentValue.id,
            "update": 1,
            "roleId": currentValue.roleId
          }
          this.watchObject.push(obj);
        }
      } else {
        this.filteredUsersData[i].roleId = currentValue.roleId;
        index = this.watchObject.findIndex(x => x.id == this.filteredUsersData[i].id && x.update == 1);
        if (index >= 0) {
          this.watchObject.splice(index, 1);
        }
      }
      if (currentValue.roleId == 9) {
        this.OpenDashboardRoleFilter(currentValue);
      }
      if (currentValue.roleId == 10) {
        this.OpenCampaignMonitorFilter(currentValue);
      }
      if (currentValue.roleId == 16) {
        this.OpenDivisionFilter(currentValue);
      }
    },
    OpenDashboardRoleFilter (row) {
      var userIndex = this.watchObject.findIndex(x => x.id == row.id && x.settings != undefined);
      if (userIndex >= 0 && this.watchObject[userIndex].type == "dashboardAccessSettings") {
        var dashboardSettings = JSON.parse(this.watchObject[userIndex].settings);
        this.selectedDashboard = dashboardSettings;
      } else {
        this.selectedDashboard = {};
      }
      this.$refs["dashboardRoleAccess"].setupModal(true, this.businessProfileId, row.id);
    },
    OpenDivisionFilter (row) {
      var userIndex = this.watchObject.findIndex(x => x.id == row.id && x.settings != undefined);
      if (userIndex >= 0 && this.watchObject[userIndex].type == "divisionAccessSettings") {
        var settings = JSON.parse(this.watchObject[userIndex].settings);
        settings = JSON.parse(settings);
        var divisions = JSON.parse(settings[0].value);
        this.selectedDivisions = divisions;
      } else {
        this.selectedDivisions = [];
      }
      this.$refs["divisionAccess"].setupModal(true, row.id, this.businessProfileId);
    },
    OpenCampaignMonitorFilter (row) {
      this.isCampaignMonitorAccess = true;
      var userIndex = this.watchObject.findIndex(x => x.id == row.id && x.settings != undefined);
      if (userIndex >= 0 && this.watchObject[userIndex].type == "campaignMonitorBPRoleAccessSettings") {
        var cmaSettings = JSON.parse(this.watchObject[userIndex].settings);
        this.selectedCMA = cmaSettings
      } else {
        this.selectedCMA = {};
      }
      this.userId = row.id;
    },
    RemoveAccess (row) {
      if (row._checked) {
        var i = this.filteredUsersData.findIndex(x => x.id == row.id);
        let initialStateObj = this.initialState.find(ind => ind.id == row.id);
        this.filteredUsersData[i]._checked = false;
        this.iDataAssigned.length = this.iDataAssigned.length - 1;
        if (this.iDataAssigned.length == 0) {
          this.selectAll = false;
        }
        var ind = this.selectedUsers.findIndex(x => x == row.id);
        if (ind >= 0) {
          this.selectedUsers.splice(ind, 1);
        }
        this.iDataAssigned = this.filteredUsersData.filter(x => x._checked == true);
        if (initialStateObj != null && initialStateObj != undefined && initialStateObj.checked) {
          var obj = {
            "id": this.filteredUsersData[i].id,
            "delete": 1,
            "roleId": this.filteredUsersData[i].roleId
          }
          this.watchObject.push(obj);
        } else {
          var index = this.watchObject.findIndex(x => x.id == this.filteredUsersData[i].id && x.insert == 1);
          if (index >= 0) {
            this.watchObject.splice(index, 1);
          }
        }
      }
    },
    onAccessSave () {
      var self = this;
      self.saveLoader = true;
      var UserData = [];
      var UpdatedUserIds = [];
      var UserIds = [];
      this.watchObject.forEach((w) => {
        if ((w.insert == 1 || w.update == 1) && parseInt(w.roleId) > 0) {
          var obj = {
            "businessProfileId": this.businessProfileId,
            "userId": w.id,
            "roleId": w.roleId
          }
          if (w.settings != undefined) {
            if (w.type == "divisionAccessSettings") {
              var userSettings = JSON.parse(w.settings);
              obj[`userSettings`] = userSettings;
            } else if (w.type == "dashboardAccessSettings") {
              obj[`accessConfigurationJson`] = w.settings;
            } else if (w.type == "campaignMonitorBPRoleAccessSettings") {
              obj[`campaignMonitorBPRoleAccessSettings`] = w.settings;
            }
          }
          UserData.push(obj);
        }
        if ((w.delete == 1 || w.update == 1) && parseInt(w.roleId) > 0) {
          UserIds.push(w.id);
        }
        if (w.update == 1) {
          UpdatedUserIds.push(w.id);
        }
      });
      let updatedData = {
        UserData,
        UserIds,
        UpdatedUserIds
      }
      var promise = [];
      var responseData = ApiService.EditBPusers(this.businessProfileId, updatedData);
      promise.push(responseData);
      Promise.all(promise).then(function (response) {
        self.responseMessage = response[0].data.message;
        self.saveLoader = false;
        self.closeModal();
        self.responsePrompt = 1;
        self.getBusinessProfiles();
      });
    },
    OnSave (businessProfileId, userId, divisionAccessSettings) {
      this.$refs.divisionAccess.closeModal();
      let initialStateObj = this.initialState.find(ind => ind.id == userId);
      var insertIndex = this.watchObject.findIndex(x => x.id == userId && x.insert == 1);
      if (insertIndex >= 0) {
        var obj = this.watchObject[insertIndex];
        obj[`type`] = "divisionAccessSettings";
        obj[`settings`] = divisionAccessSettings;
      }
      var index = this.watchObject.findIndex(x => x.id == userId && x.update == 1);
      if (index < 0 && insertIndex < 0 && initialStateObj != null && initialStateObj != undefined) {
        obj = {
          "id": userId,
          "businessProfileId": businessProfileId,
          "update": 1,
          "type": "divisionAccessSettings",
          "settings": divisionAccessSettings,
          "roleId": initialStateObj.roleId
        }
        this.watchObject.push(obj);
      } else {
        obj = this.watchObject[index];
        obj[`type`] = "divisionAccessSettings";
        obj[`settings`] = divisionAccessSettings;
      }
    },
    OnDashboardAccessSave (businessProfileId, userId, dashboardAccessSettings) {
      this.$refs.dashboardRoleAccess.closeModal();
      let initialStateObj = this.initialState.find(ind => ind.id == userId);
      var insertIndex = this.watchObject.findIndex(x => x.id == userId && x.insert == 1);
      if (insertIndex >= 0) {
        var obj = this.watchObject[insertIndex];
        obj[`type`] = "dashboardAccessSettings";
        obj[`settings`] = dashboardAccessSettings;
      }
      var index = this.watchObject.findIndex(x => x.id == userId && x.update == 1);
      if (index < 0 && insertIndex < 0) {
        obj = {
          "id": userId,
          "businessProfileId": businessProfileId,
          "update": 1,
          "settings": dashboardAccessSettings,
          "type": "dashboardAccessSettings",
          "roleId": initialStateObj.roleId
        }
        this.watchObject.push(obj);
      } else {
        obj = this.watchObject[index];
        obj[`type`] = "dashboardAccessSettings";
        obj[`settings`] = dashboardAccessSettings;
      }
    },
    OnCampaignMonitorAccessSave (userId, businessProfileId, campaignMonitorBPRoleAccessSettings) {
      this.$refs.cma.closeModal();
      let initialStateObj = this.initialState.find(ind => ind.id == userId);
      var insertIndex = this.watchObject.findIndex(x => x.id == userId && x.insert == 1);
      if (insertIndex >= 0) {
        var obj = this.watchObject[insertIndex];
        obj[`type`] = "campaignMonitorBPRoleAccessSettings";
        obj[`settings`] = campaignMonitorBPRoleAccessSettings;
      }
      var index = this.watchObject.findIndex(x => x.id == userId && x.update == 1);
      if (index < 0 && insertIndex < 0 && initialStateObj != null && initialStateObj != undefined) {
        obj = {
          "id": userId,
          "businessProfileId": businessProfileId,
          "update": 1,
          "type": "campaignMonitorBPRoleAccessSettings",
          "settings": campaignMonitorBPRoleAccessSettings,
          "roleId": initialStateObj.roleId
        }
        this.watchObject.push(obj);
      } else {
        obj = this.watchObject[index];
        obj[`type`] = "campaignMonitorBPRoleAccessSettings";
        obj[`settings`] = campaignMonitorBPRoleAccessSettings;
      }
    },
    closeModal () {
      this.showEditUsersModal = false;
      this.watchObject = [];
      this.search = '';
    },
    CloseCampaignMonitorModal () {
      this.isCampaignMonitorAccess = false;
    },
    getAllBpRoles () {
      var self = this;
      var promise = [];
      var responseData = ApiService.getAllBpRoles();
      promise.push(responseData);
      Promise.all(promise).then(function (response) {
        self.roles = response[0].data;
      });
    },
    createBPPage () {
      window.open(window.location.origin + `/App/BusinessProfile/Create`, "_self");
    },
    onClickEditBPUsers (row) {
      this.showEditUsersModal = true;
      this.bpName = row.name;
      this.assignedUsers = row.users;
      this.businessProfileId = row.id;
      this.isLoading = true;
      this.getPortalUsers();
    },
    ValidateFields () {
      if (this.bpName.trim() == '') {
        this.nameErrorMessage = ' field-empty-error ';
        this.nameErrorMessageText = 'Please enter Business Profile name';
        return false;
      }
      return true;
    }
  },
  mounted () {
    this.getBusinessProfiles();
    this.changePage(this.currentPage);
    this.getCategories();
    this.getAllSubCategories();
    this.getAllBpRoles();
  }
}
</script>

<style scoped>

::v-deep .ivu-table-tip {
  overflow-x: scroll;
}
::v-deep .ivu-table-row {
  height: 26px;
}
::v-deep .ivu-page {
  text-align: right !important;
}
.action-button {
  cursor: pointer;
  display: inline-block;
  font-size: 16px;
  padding-left: 6px;
  padding-right: 6px;
}
.logo{
  max-height:20px;
  max-width:20px;
}
.logo-div{
  float: right;
  width : 10%;
}
.name-div{
  float: left;
  width: 90%;
}
.businessprofiles-container {
  background: white;
  padding: 10px;
  margin-bottom: 20px;
}

.header {
  font-size: 22px;
  font-weight: 500;
}

.search{
  max-width:500px;
  margin-inline-start:0.3%;
  display: flex;
  gap: 10px;
}

.btn{
  margin-bottom: 0px;
}
.btn-success{
  background-color:  #51b848;
  color: white;
  margin-bottom: 0px;
  height: 32px;
}

.user-list{
 float: left;
}
.confirmation {
  margin-top: -8px !important;
  margin-bottom: -6px !important;
}

::v-deep .ivu-page-item-active {
    background-color: #51b848 !important;
    color: #d9d9d9 !important;

}

::v-deep .ivu-table {
    font-size: 12px;
}

::v-deep .ivu-page-item-active a{
  color: #d9d9d9 !important;
}

::v-deep .ivu-checkbox-checked .ivu-checkbox-inner {
  border-color:  #51b848;
  background-color: #51b848;
}
::v-deep .ivu-checkbox-indeterminate .ivu-checkbox-inner{
  border-color:  #51b848;
  background-color: #51b848;
}
.tables-div{
  margin: 5px;
  border: 5px;
  padding-top:15px;
  height: 400px;
}
.no-border-left{
  border-left: 0px !important;
}
.users-div{
  height: 420px;
  overflow-y: auto;
  border: 1px solid #ccc;
}
.users-assigned-div{
  height: 420px;
  overflow-y: auto;
  border: 1px solid #ccc;
}
.user-row{
  min-height: 40px;
  border-bottom: 1px solid #ccc;
}
.user-assigned-row{
 min-height: 40px;
 border-bottom: 1px solid #ccc;
}
.br {
  display: block;
  margin-bottom: 10px;
}
.action-btn{
  padding-top: 5px;
}
.filter-icon{
  float : left ;
  width: 35%;
  padding-top: 5px;
  cursor: pointer;
}
.remove-icon{
  float : left ;
  padding-top: 5px;
  width: 65%;
}
.align-item-middle{
  padding-top: 10px;
}
.select-all-checkbox{
  padding-left: 5px;
}
.select-all{
  padding-left: 15px;
}
.padding{
  padding :5px;
}
::v-deep .ivu-select-input{
  height: 27px;
}
::v-deep .ivu-modal-header{
  padding: 10px;
}
::v-deep .ivu-modal-footer{
  padding: 10px;
}
::v-deep .ivu-modal-body{
  padding: 10px;
}
::v-deep .modal_placement{
    display: flex;
    align-items: center;
    justify-content: center;
}
::v-deep .ivu-modal{
    top: 0;
}
::v-deep .ivu-select-multiple .ivu-tag{
  height: 20px;
  line-height: 18px;
}
::v-deep .ivu-select-multiple .ivu-tag i{
  top: 2px;
}
div.users-div > div:nth-of-type(odd) {
    background-color: #eee;
}
div.users-div > div:nth-of-type(even) {
    background-color: #fff;
}
.user-name {
  float: left;
  overflow-wrap: break-word;
  width: 35%;
}
.user-email {
  float: left;
  overflow-wrap: break-word;
  width: 65%;
}
.bp-loader{
  text-align: center;
}
.items-dropdown{
  width: 55px;
}
.input-label{
    text-align: end;
    padding-left: 15px;
    padding-right: 15px;
}
.input-field{
    padding-left: 15px;
    padding-right: 15px;
    margin: 0px;
}
.btn-white{
  margin-bottom: 0px;
}

.field-empty-error {
  border: red solid 1px;
}
.error-message{
  padding-left: 10px;
  margin-top: 5px;
  margin-bottom: 5px;
}
.label-span{
  color: black;
}
.body{
  text-align: center;
}
.active{
  border-bottom: 6px solid #ccc;
}
.vertical-line{
  border-left: 1px solid #ccc;
}
.modal-loader{
  text-align: center;
}
.deleted-bp{
  color: red;
}

</style>

<style scoped src = "../../../../../../node_modules/bootstrap-icons/font/bootstrap-icons.css">
</style>

<style src="../../../Styles/dx-iview.css">
/*
    For default iview css, use
    iview/dist/styles/iview.css
  */
</style>
