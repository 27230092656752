<template>
    <div>
        <Modal v-model="visible" title="Submit Estimate" width="50" :mask-closable="false" @on-cancel="closeModal">
          <div class="purchase-order-toggle" v-if="dataFetched">
            <div class="toggle-label" style="margin-left: 73px;">Contract</div>
              <i-switch v-model="contractToggle" :disabled="disable" style="margin-left: 35px;" @on-change="changeToggle"/>
          </div>
          <div v-if="dataFetched && contractToggle" style="margin-top: 15px;">
              <i-row type="flex" :gutter="10">
                  <i-col :span="15">
                    <i-select clearable v-model="selectedContract" @on-change="changeErr" :disabled="isContractDisabled" style="width: 53%; margin-left: 33%; margin-top: -2px;">
                          <i-option v-for="item in contracts" :value="item.id" :key="item.id" >{{ item.name }}</i-option>
                      </i-select>
                  </i-col>
              </i-row>
              <span style="margin-left: 21%;" v-show="err">Select contract</span>
              <hr style="margin-top: 18px !important;">
              <div class="purchase-order">
                  <i-row type="flex">
                    <div class="purchase-order-toggle">
                      <div class="toggle-label">Link Purchase Order</div>
                        <i-switch v-model="purchaseOrder.enabled" style="margin-left: 35px;"/>
                    </div>
                  </i-row>
                  <i-row style="margin-left: 14%; margin-top: 15px;">
                    <i-radio-group
                      v-if="purchaseOrder.enabled && !poDisable"
                      v-model="purchaseOrder.tab"
                      class="purchase-order-tabs">
                        <i-radio label="select">Existing</i-radio>
                        <i-radio label="create">New</i-radio>
                    </i-radio-group>
                  </i-row>
                  <i-row
                    v-if="purchaseOrder.enabled && purchaseOrder.tab == 'select'"
                    type="flex"
                    :gutter="10">
                      <i-col :span="12" class="input">
                          <i-select clearable v-model="purchaseOrder.selectedId" :disabled="isPurchaseOrderDisabled" style="width: 67%; margin-left: 42%;">
                              <i-option v-for="item in purchaseOrders" :value="item.id" :key="item.id" >{{ item.name }}</i-option>
                          </i-select>
                      </i-col>
                  </i-row>
                  <i-row
                    v-if="purchaseOrder.enabled && purchaseOrder.tab == 'create'"
                    type="flex"
                    :gutter="10"
                    align="bottom">
                      <i-col :span="5"></i-col>
                      <i-col :span="8" class="input">
                          <label>Name</label>
                          <i-input
                              v-model="purchaseOrder.formData.name"
                              :class="{ 'invalid-field': !purchaseOrder.errorList.isValidName }">
                          </i-input>
                      </i-col>
                      <i-col :span="8" class="input">
                          <label>IO Number</label>
                          <i-input
                              v-model="purchaseOrder.formData.number"
                              :class="{ 'invalid-field': !purchaseOrder.errorList.isValidNumber }">
                          </i-input>
                      </i-col>
                      <i-col :span="5"></i-col>
                      <i-col :span="8" class="input">
                          <label>Amount</label>
                          <i-input
                              type="number"
                              v-model="purchaseOrder.formData.amount"
                              :class="{ 'invalid-field': !purchaseOrder.errorList.isValidAmount }">
                          </i-input>
                      </i-col>
                      <i-col :span="8" class="input">
                          <label>Validity</label>
                          <i-datepicker
                              type="daterange"
                              format="MMM d, yyyy"
                              :clearable="false"
                              :editable="false"
                              v-model="purchaseOrder.formData.validity">
                          </i-datepicker>
                      </i-col>
                      <i-col :span="5"></i-col>
                      <i-col :span="8" class="input">
                          <label>Issued Date</label>
                          <i-datepicker
                              format="MMM d, yyyy"
                              :clearable="false"
                              :editable="false"
                              :options="dateOptions"
                              v-model="purchaseOrder.formData.issued">
                          </i-datepicker>
                      </i-col>
                      <i-col :span="8" class="input">
                          <i-upload
                              :before-upload="handlePurchaseOrderUpload"
                              class="upload-btn">
                              <button class="btn btn-white">
                                  <i class="fa fa-plus icon-plus"></i>
                                  Upload
                              </button>
                              <span v-if="purchaseOrder.file !== null"> {{ purchaseOrder.file.name | shortName }} </span>
                          </i-upload>
                      </i-col>
                  </i-row>
              </div>
          </div>
          <div v-if="!dataFetched" class="spinner-div">
            <i class="fa fa-spinner fa-spin fa-3x"></i>
          </div>
          <div slot="footer">
              <div v-show="dataFetched">
                <button class="btn btn-white" @click="closeModal">Cancel</button>
                <button class="btn btn-success" @click="save">Submit</button>
              </div>
          </div>
        </Modal>
    </div>
</template>
<script>
import { Modal, Select, Option, Row, Col, Input, DatePicker, Switch, Radio, RadioGroup, Upload } from 'iview';

var apiService = require('../Services/EstimateApiService.js');
export default ({
  props: {
    contractModalVisible: Boolean,
    billingPortalId: Number,
    contractId: Number,
    purchaseOrderId: Number,
    mediaPlanId: Number
  },
  filters: {
    shortName (val) {
      if (val.length > 20) {
        return val.substring(0, 20) + '...';
      }
      return val;
    }
  },
  components: {
    Modal,
    "i-select": Select,
    "i-option": Option,
    "i-row": Row,
    "i-col": Col,
    "i-input": Input,
    "i-datepicker": DatePicker,
    "i-switch": Switch,
    "i-radio": Radio,
    "i-radio-group": RadioGroup,
    "i-upload": Upload
  },
  data () {
    return {
      visible: false,
      apiService: new apiService.ApiService(),
      dataFetched: true,
      contractToggle: true,
      purchaseOrders: [],
      clients: [],
      agencyUnits: [],
      contracts: [],
      selectedContract: 0,
      isApprovalFlowEnabled: false,
      disable: false,
      purchaseOrder: {
        enabled: false,
        tab: "select",
        selectedId: 0,
        file: null,
        formData: {
          name: "",
          number: "",
          amount: "",
          issued: moment().format('MMM DD, YYYY'),
          validity: [moment().startOf('month').format('MMM DD, YYYY'), moment().endOf('month').format('MMM DD, YYYY')]
        },
        errorList: {
          isValidName: true,
          isValidNumber: true,
          isValidAmount: true
        }
      },
      isContractDisabled: false,
      isPurchaseOrderDisabled: false,
      poDisable: false,
      dateOptions: {
        disabledDate (date) {
          return date && date.valueOf() > Date.now();
        }
      },
      mpId: 0,
      mediaPlan: {},
      approvedBuy: {},
      err: false
    }
  },
  mounted () {
    const isApprovalFlowEnabledFlag = JSON.parse(deltax.FeatureFlags)["IsApprovalFlowEnabled"]
    this.isApprovalFlowEnabled = isApprovalFlowEnabledFlag != null && isApprovalFlowEnabledFlag != undefined
      ? isApprovalFlowEnabledFlag
      : false
    this.disable = !!this.isApprovalFlowEnabled
  },
  methods: {
    init () {
      this.visible = true;
      this.mpId = this.mediaPlanId;
      this.dataFetched = false;
      this.loadAllData();
    },
    async save () {
      if (this.isApprovalFlowEnabled && this.selectedContract == 0) {
        this.err = true;
        return;
      }
      this.dataFetched = false;
      let purchaseOrderId = null;
      if (this.purchaseOrder.enabled) {
        purchaseOrderId = this.purchaseOrder.selectedId;

        if (this.purchaseOrder.tab == 'create') {
          purchaseOrderId = await this.createPurchaseOrder();
        }

        if (purchaseOrderId == -1) {
          this.dataFetched = true;
          return;
        }
      }

      this.visible = false;
      this.dataFetched = true;
      if (this.mediaPlan.status == "Draft" && (this.selectedContract == 0 || this.selectedContract == undefined)) {
        this.apiService.sendToReview(this.mpId)
          .then(res => {
            this.$emit('closeModal', 1);
          })
        return;
      }
      if (this.selectedContract != 0 && this.selectedContract != undefined) {
        if (this.mediaPlan.status == "Draft") {
          this.apiService.UpdateMediaPlanStatus(this.selectedContract, 5, this.mpId)
            .then(res => {
              if (res.data.mediaPlan.approvedBuyId != undefined && res.data.mediaPlan.approvedBuyId != null && purchaseOrderId != undefined && purchaseOrderId != null && purchaseOrderId != 0) {
                this.apiService.LinkPurchaseOrderToEstimates(purchaseOrderId, res.data.mediaPlan.approvedBuyId)
              }
              this.$emit('closeModal', 1);
            })
        } else if (!this.isContractDisabled) {
          this.apiService.updateMediaPlan(this.mpId, this.selectedContract)
            .then(res => {
              if (res.data.mediaPlan.approvedBuyId != undefined && res.data.mediaPlan.approvedBuyId != null && purchaseOrderId != undefined && purchaseOrderId != null && purchaseOrderId != 0) {
                this.apiService.LinkPurchaseOrderToEstimates(purchaseOrderId, res.data.mediaPlan.approvedBuyId)
              }
              this.$emit('closeModal', 1);
              window.confirm(`Contract and purchase order linked successfully.`)
            })
        }
        if (this.isContractDisabled && this.mediaPlan.status != "Draft" && this.approvedBuy != undefined && this.approvedBuy != null && purchaseOrderId != undefined && purchaseOrderId != null && purchaseOrderId != 0) {
          this.apiService.LinkPurchaseOrderToEstimates(purchaseOrderId, this.approvedBuy.id)
            .then(res => {
              this.$emit('closeModal', 1);
              window.confirm(`Purchase order linked successfully.`)
            })
        }
      }
      this.selectedContract = 0
      this.purchaseOrder.selectedId = 0
      this.isContractDisabled = false;
      this.disable = false
    },
    changeErr () {
      this.err = false
    },
    openModal (showModal, mediaPlanId) {
      this.visible = showModal
      this.dataFetched = false;
      this.mpId = mediaPlanId
      console.log(mediaPlanId, 'mpid')
      this.loadAllData();
    },
    loadAllData () {
      var self = this;
      const getAllPurchaseOrderPromise = this.apiService.getAllPurchaseOrders(this.billingPortalId);
      const getAllClientsPromise = this.apiService.getAllClients(this.billingPortalId);
      const getAllAgencyUnitsPromise = this.apiService.getAllAgencyUnits(this.billingPortalId);
      const getMediaPlanById = this.apiService.getMediaPlanById(this.mpId);
      Promise.all([getAllPurchaseOrderPromise, getAllClientsPromise, getAllAgencyUnitsPromise, getMediaPlanById])
        .then(responses => {
          self.purchaseOrders = responses[0].data.data.purchaseOrders;
          self.agencyUnits = responses[2].data.data.agencyUnits;
          self.clients = responses[1].data.data.clients;
          self.mediaPlan = responses[3].data.data;
          let clientIds = self.clients.map(client => client.id).join(',');
          let agencyUnitIds = self.agencyUnits.map(agencyUnit => agencyUnit.id).join(',');
          self.apiService.getAllContracts(this.billingPortalId, clientIds, agencyUnitIds)
            .then(res => {
              self.contracts = res.data.data.contracts;
              self.dataFetched = true;
              if (self.mediaPlan.contractId !== undefined && self.mediaPlan.contractId !== null && self.mediaPlan.contractId !== 0) {
                this.selectedContract = self.mediaPlan.contractId;
                this.isContractDisabled = true;
                this.disable = true
              }
              if (self.mediaPlan.approvedBuyId != null && self.mediaPlan.approvedBuyId != undefined) {
                this.apiService.GetPurchaseOrdersByApprovedBuyId(self.mediaPlan.approvedBuyId)
                  .then(res => {
                    if (res.data.data != null || res.data.data != undefined) {
                      self.approvedBuy = res.data.data
                      this.purchaseOrder.selectedId = res.data.data.purchaseOrderId
                    }
                    if (this.purchaseOrder.selectedId !== undefined && this.purchaseOrder.selectedId !== null && this.purchaseOrder.selectedId !== 0) {
                      this.purchaseOrder.enabled = true;
                    }
                  })
              }
            })
        })
        .catch(error => {
          self.dataFetched = false;
          console.error(error);
        });
    },
    changeToggle (status) {
      this.contractToggle = status
    },
    closeModal () {
      this.visible = false;
      this.purchaseOrder.tab = "select";
      this.purchaseOrder.enabled = false;
      this.purchaseOrder.selectedId = null;
      this.purchaseOrder.formData = {
        name: "",
        number: "",
        amount: "",
        issued: moment().format('MMM DD, YYYY'),
        validity: [moment().startOf('month').format('MMM DD, YYYY'), moment().endOf('month').format('MMM DD, YYYY')]
      }
      this.purchaseOrder.errorList = {
        isValidName: true,
        isValidNumber: true,
        isValidAmount: true
      }
      this.selectedContract = 0
      this.purchaseOrder.selectedId = 0
      this.isContractDisabled = false;
      this.disable = false
      this.$emit('closeModal', 0);
    },
    async createPurchaseOrder () {
      if (!this.isValidPOData()) {
        return -1;
      }
      let selectedClient = this.contracts.find(item => item.id == this.selectedContract).client;
      let selectedAgencyUnit = this.contracts.find(item => item.id == this.selectedContract).agencyUnit;
      let data = {
        Name: this.purchaseOrder.formData.name,
        ClientId: this.clients.find(item => item.name == selectedClient).id,
        AgencyUnitId: this.agencyUnits.find(item => item.name == selectedAgencyUnit).id,
        Type: 1,
        ClientIONumber: this.purchaseOrder.formData.number,
        Amount: this.purchaseOrder.formData.amount,
        IssuedDate: moment(this.purchaseOrder.formData.issued).format('YYYY-MM-DD'),
        StartDate: moment(this.purchaseOrder.formData.validity[0]).format('YYYY-MM-DD'),
        EndDate: moment(this.purchaseOrder.formData.validity[1]).format('YYYY-MM-DD')
      }

      let response = await this.apiService.createPurchaseOrder(this.billingPortalId, data);
      let purchaseOrderId = response.data.id;

      if (this.purchaseOrder.file && this.purchaseOrder.file != null && this.purchaseOrder.file != '') {
        var formData = new FormData();
        formData.append("file", this.purchaseOrder.file);
        await this.apiService.uploadPurchaseOrderAttachment(this.billingPortalId, purchaseOrderId, formData);
      }

      return purchaseOrderId;
    },
    isValidPOData () {
      this.purchaseOrder.errorList.isValidName = true;
      this.purchaseOrder.errorList.isValidNumber = true;
      this.purchaseOrder.errorList.isValidAmount = true;

      let name = this.purchaseOrder.formData.name;
      let number = this.purchaseOrder.formData.number;
      let amount = this.purchaseOrder.formData.amount;

      if (name == null || name.length == 0) {
        this.purchaseOrder.errorList.isValidName = false;
      }
      if (number == null || number.length == 0) {
        this.purchaseOrder.errorList.isValidNumber = false;
      }
      if (amount == null || amount.length == 0) {
        this.purchaseOrder.errorList.isValidAmount = false;
      }

      return this.purchaseOrder.errorList.isValidName &&
        this.purchaseOrder.errorList.isValidNumber &&
        this.purchaseOrder.errorList.isValidAmount;
    },
    handlePurchaseOrderUpload (file) {
      this.purchaseOrder.file = file;
      return false;
    }
  },
  watch: {
    contractModalVisible (newValue, oldValue) {
      if (newValue) {
        this.visible = this.contractModalVisible;
        this.init();
      }
    }
  }
})
</script>
<style scoped>
.btn {
  margin-bottom: 0;
}
.spinner-div{
  text-align: center;
}

.purchase-order {
  margin-top: 15px;
}

.toggle-label {
  margin-left: 10px;
  display: inline-flex;
  align-items: center;
}

.purchase-order-tabs {
  margin-left: 50px;
}

.input {
  margin-top: 10px;
}

.invalid-field {
  border: red solid 1px !important;
}

.upload-btn {
  display: flex;
  align-items: baseline;
}

::v-deep .purchase-order-tabs > .ivu-radio-wrapper {
  margin-right: 15px;
}

::v-deep .ivu-date-picker {
  width: 100%;
}

::v-deep .purchase-order-toggle .ivu-switch-checked:after {
    left: 18px;
}

::v-deep .purchase-order-toggle .ivu-switch:after {
  width: 15px;
  height: 15px;
}

::v-deep .purchase-order-toggle .ivu-switch {
  width: 35px;
  height: 19px;
}
</style>
