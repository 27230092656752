<template>
    <Form
      label-position="right"
      :label-width="200"
      :model="config"
      :rules="validationRules"
      ref="molocoConfig"
    >
      <div v-show="showConfigForm">
        <Divider orientation="left">Accounts Settings</Divider>
        <Form-Item label="Ad Account" key="bpseId" prop="bpseId">
          <Row>
            <Row-Col span="18">
              <Select
                v-model="config.bpseId"
                placeholder="Select an account"
                :transfer="true"
                :disabled="isExistingConfig"
                filterable
              >
                <Option
                  v-for="(item, index) in molocoAccounts"
                  :key="index"
                  :value="item.id"
                  :disabled="item.isOauthExpired"
                  >{{ item.customerId + " - " + item.accountNickName }}</Option
                >
              </Select>
            </Row-Col>
          </Row>
        </Form-Item>
        <Divider orientation="left">Campaign Settings</Divider>
        <Form-Item label="Campaign Name" key="campaign.name" prop="campaign.name">
          <Row>
            <Row-Col span="18">
              <i-input :disabled="isNomenclatureEnabled" v-model="config.campaign.name" clearable/>
            </Row-Col>
          </Row>
        </Form-Item>
        <Form-Item label="Description" key="campaign.description" prop="campaign.description">
          <Row>
            <Row-Col span="18">
              <i-input v-model="config.campaign.description" clearable/>
            </Row-Col>
          </Row>
        </Form-Item>
        <Form-Item label="Campaign Status" key="campaign.status" prop="campaign.status">
            <Row>
                <Row-Col>
                    <RadioGroup v-model="config.campaign.status">
                        <Radio :label="molocoEnums.Campaignstatus.Active"><span>Active</span></Radio>
                        <Radio :label="molocoEnums.Campaignstatus.Paused"><span>Paused</span></Radio>
                    </RadioGroup>
                </Row-Col>
            </Row>
        </Form-Item>
        <Form-Item label="Objective" key="campaign.objective" prop="campaign.objective">
          <Row>
            <Row-Col span="18">
              <Select
                v-model="config.campaign.objective"
                placeholder="Select an Objective"
                :transfer="true"
                :disabled="isExistingConfig"
              >
                  <Option
                    v-for="(id, name) in molocoEnums.objectives"
                    :key="parseInt(id)"
                    :value="parseInt(id)"
                    >{{ name }}</Option
                  >
              </Select>
            </Row-Col>
          </Row>
        </Form-Item>
        <Form-Item label="Brand App"
          key="campaign.app"
          prop="campaign.app"
          v-if="isAppInstallsObjective"
        >
          <Row>
            <Row-Col span="18">
              <Select
                v-model="config.campaign.app"
                :placeholder="brandAppsPlaceholder"
                :transfer="true"
                :disabled="isBrandAppsDisabled"
                not-found-text="No Apps found for the selected Account"
              >
                <Option
                  v-for="app in brandApps.data"
                  :key="app.bundle_id"
                  :value="app.bundle_id"
                >{{ app.localized_app_name.en }} ({{ getKeyByValue(molocoEnums.osTypes, app.os) }})</Option>
              </Select>
            </Row-Col>
          </Row>
        </Form-Item>
        <Form-Item label="MMP Tracking Link"
          key="campaign.mmpTrackingLink"
          prop="campaign.mmpTrackingLink"
          v-if="isAppInstallsObjective"
        >
          <Row>
            <Row-Col span="18">
              <Select
                v-model="config.campaign.mmpTrackingLink"
                :placeholder="mmpTrackingLinksPlaceholder"
                :transfer="true"
                :disabled="isMMPTrackingLinksDisabled"
                not-found-text="No Tracking Links found for the selected App"
              >
                  <Option
                    v-for="link in filteredMMPTrackingLinks"
                    :key="link.id"
                    :value="link.id"
                    >{{ link.title }}</Option
                  >
              </Select>
            </Row-Col>
          </Row>
        </Form-Item>
        <Form-Item label="Campaign Priority" key="campaign.priority" prop="campaign.priority">
          <Row>
            <Row-Col span="18">
              <Select
                v-model="config.campaign.priority"
                placeholder="Select Campaign Priority"
                :transfer="true"
              >
                  <Option
                    v-for="(id, priority) in molocoEnums.priorityList"
                    :key="parseInt(id)"
                    :value="parseInt(id)"
                    >{{ priority.split("_").join(" ") }}</Option
                  >
              </Select>
            </Row-Col>
          </Row>
        </Form-Item>
        <Form-Item label="Ad Unit Targeting" key="campaign.adUnitTargeting" prop="campaign.adUnitTargeting">
          <Row>
            <Row-Col span="18">
              <Select
                v-model="config.campaign.adUnitTargeting"
                placeholder="Select Ad Unit Targeting values"
                :transfer="true"
                multiple
              >
                <OptionGroup v-for="(value, itemName) in computedAdUnitTargetingOptions" :label="itemName" @click.native="selectAllTargetingOptions(itemName)" :key="itemName" class="targetOptionGroup">
                  <Option v-for="(id, name) in value" :key="id" :value="id">
                    {{ name }}
                  </Option>
                </OptionGroup>
              </Select>
            </Row-Col>
          </Row>
        </Form-Item>
        <Form-Item label="Landing URL" key="campaign.landingUrl" prop="campaign.landingUrl" :required="isLeadsObjective" v-if="isBrandAwarenessObjective || isLeadsObjective">
          <Row>
            <Row-Col span="18">
              <i-input v-model="config.campaign.landingUrl" clearable/>
            </Row-Col>
          </Row>
        </Form-Item>
        <Form-Item label="Country" key="campaign.country" prop="campaign.country">
          <Row>
            <Row-Col span="18">
              <Select
                v-model="config.campaign.country"
                placeholder="Select country"
                :transfer="true"
              >
                  <Option
                    v-for="(id, country) in molocoEnums.countries"
                    :key="id"
                    :value="id"
                    >{{ country }}</Option
                  >
              </Select>
            </Row-Col>
          </Row>
        </Form-Item>
        <Form-Item label="JIO Ads Campaign ID" v-if="isBrandAwarenessObjective || isLeadsObjective">
          <Row>
            <Row-Col span="18">
              <i-input v-model="config.campaign.jioAdsCampaignId" clearable/>
            </Row-Col>
          </Row>
        </Form-Item>
        <Form-Item label="Campaign RO_ID" key="campaign.roId" prop="campaign.roId">
          <Row>
            <Row-Col span="18">
              <i-input v-model="config.campaign.roId" clearable/>
            </Row-Col>
          </Row>
        </Form-Item>
        <Form-Item label="Conversion Settings"
          key="campaign.conversionSettings"
          prop="campaign.conversionSettings"
          v-if="isAppInstallsObjective"
        >
          <Row>
            <Row-Col span="18">
              <Select
                v-model="config.campaign.conversionSettings"
                placeholder="Select Conversion Settings"
                :transfer="true"
              >
                  <Option
                    v-for="settings in conversionSettings.data"
                    :key="settings"
                    :value="settings"
                    >{{ settings }}</Option
                  >
              </Select>
            </Row-Col>
          </Row>
        </Form-Item>
        <Divider orientation="left">Budget Settings</Divider>
        <Form-Item label="Deal type" key="dealType" prop="dealType">
          <Row>
            <Row-Col span="18">
              <Select
                v-model="config.dealType"
                placeholder="Select Deal Type"
                :transfer="true"
              >
                  <Option
                    v-for="(id, deal) in computedDealTypes"
                    :key="parseInt(id)"
                    :value="parseInt(id)"
                    >{{ deal.split("_").join(" ") }}</Option
                  >
              </Select>
            </Row-Col>
          </Row>
        </Form-Item>
        <Form-Item label="Target CPM" key="targetCpm" prop="targetCpm" v-if="isBrandAwarenessObjective || isAppInstallsObjective">
          <Row>
            <Row-Col span="6">
                <i-input v-model="config.targetCpm" type="number">
                    <span slot="prepend">{{ bpCurrencySymbol }}</span>
                </i-input>
            </Row-Col>
          </Row>
        </Form-Item>
        <Form-Item label="Target CPL" key="targetCpl" prop="targetCpl" v-else-if="isLeadsObjective">
          <Row>
            <Row-Col span="6">
                <i-input v-model="config.targetCpl" type="number">
                    <span slot="prepend">{{ bpCurrencySymbol }}</span>
                </i-input>
            </Row-Col>
          </Row>
        </Form-Item>
        <template v-if="isAppInstallsObjective">
          <Form-Item label="CPI Ceiling" key="cpiCeiling" prop="cpiCeiling">
            <Row>
                <Row-Col span="6">
                    <i-input v-model="config.cpiCeiling" type="number">
                        <span slot="prepend">{{ bpCurrencySymbol }}</span>
                    </i-input>
                </Row-Col>
            </Row>
            </Form-Item>

            <Form-Item label="Budget" key="budgetType" prop="budgetType">
                <Row>
                <Row-Col>
                    <RadioGroup v-model="config.budgetType">
                        <Radio :label="molocoEnums.budgetTypes[`Average Daily Budget`]"><span>Average Daily Budget</span></Radio>
                        <Radio :label="molocoEnums.budgetTypes[`Fixed Budget`]"><span>Fixed Budget</span></Radio>
                    </RadioGroup>
                </Row-Col>
                </Row>
            </Form-Item>
            <Form-Item label="" key="averageDailyBudget" prop="averageDailyBudget" v-if="config.budgetType==molocoEnums.budgetTypes['Average Daily Budget']">
            <Row>
                <Row-Col span="6">
                      <span style="display: inline-block;">
                          <i-input v-model="config.averageDailyBudget" type="number" clearable>
                              <span slot="prepend">{{ bpCurrencySymbol }}</span>
                          </i-input>
                      </span>
                </Row-Col>
            </Row>
            </Form-Item>
            <Form-Item label="" key="fixedBudgetType" prop="fixedBudgetType" v-if="config.budgetType==molocoEnums.budgetTypes['Fixed Budget']">
                <Row>
                <Row-Col>
                    <RadioGroup v-model="config.fixedBudgetType">
                        <Radio :label="molocoEnums.fixedBudgetTypes.Daily"><span>Daily</span></Radio>
                        <Radio :label="molocoEnums.fixedBudgetTypes.DayOfWeek"><span>Day Of Week</span></Radio>
                    </RadioGroup>
                </Row-Col>
                </Row>
            </Form-Item>
            <Form-Item
              label="" key="fixedDailyBudget"
              prop="fixedDailyBudget"
              v-if="config.budgetType == molocoEnums.budgetTypes[`Fixed Budget`] && config.fixedBudgetType == molocoEnums.fixedBudgetTypes.Daily"
            >
            <Row>
                <Row-Col span="6">
                  <span style="display: inline-block;">
                      <i-input v-model="config.fixedDailyBudget" type="number" clearable>
                          <span slot="prepend">{{ bpCurrencySymbol }}</span>
                      </i-input>
                  </span>
                </Row-Col>
            </Row>
            </Form-Item>
            <template v-if="config.budgetType == molocoEnums.budgetTypes[`Fixed Budget`] && config.fixedBudgetType == molocoEnums.fixedBudgetTypes.DayOfWeek">
                <Form-Item label="" v-for="(value, day) in molocoEnums.daysOfWeek" :key="`weeklyDailyBudget.${value}`" :prop="`weeklyDailyBudget.${value}`">
                    <Row>
                        <Row-Col span="6">
                            <span style="display: inline-block;">
                                <i-input v-model="config.weeklyDailyBudget[value]" type="number" clearable>
                                    <span slot="prepend">{{ day.slice(0,3) }} {{ bpCurrencySymbol }}</span>
                                </i-input>
                            </span>
                        </Row-Col>
                    </Row>
              </Form-Item>
            </template>
        </template>
        <Form-Item label="Schedule" key="schedule" prop="schedule">
          <Row>
            <Row-Col :md="8" :lg="6">
                <DatePicker
                    class="spaceBetween"
                    v-model="config.schedule.start"
                    type="datetime"
                    format="yyyy-MM-dd HH:mm"
                    placeholder="Select start Date and Time"
                    :options="config.schedule.options.startDateOptions"
                    style="width: 200px">
                </DatePicker>
            </Row-Col>
            <Row-Col :md="8" :lg="6">
                <DatePicker
                    class="spaceBetween"
                    v-model="config.schedule.end"
                    type="datetime"
                    format="yyyy-MM-dd HH:mm"
                    placeholder="Select end Date and Time"
                    :disabled="config.isScheduleEndDateNotRequired"
                    :options="config.schedule.options.endDateOptions"
                    :start-date="endDateBeginsFrom"
                    style="width: 200px">
                </DatePicker>
            </Row-Col>
            <Row-Col :md="8" :lg="6">
                <Checkbox v-model="config.isScheduleEndDateNotRequired">No End Date</Checkbox>
            </Row-Col>
          </Row>
        </Form-Item>
        <Form-Item label="Campaign Maximum Impressions" key="campaign.maximumImpressions" prop="campaign.maximumImpressions" v-if="isBrandAwarenessObjective || isLeadsObjective">
          <Row>
            <Row-Col span="18">
                <i-input v-model="config.campaign.maximumImpressions" type="number"  clearable/>
            </Row-Col>
          </Row>
        </Form-Item>
        <Form-Item label="Daily Maximum Impressions" key="dailyMaxImpressions" prop="dailyMaxImpressions" v-if="isBrandAwarenessObjective || isLeadsObjective">
          <Row>
            <Row-Col span="18">
              <i-input v-model="config.dailyMaxImpressions" type="number" clearable/>
            </Row-Col>
          </Row>
        </Form-Item>
        <!-- Disabling it for now. Need to show for Brand Awareness -->
        <Form-Item label="Key-Value Impression Caps" key="keyValueImpressionCaps" prop="keyValueImpressionCaps" v-if="false">
          <Row>
            <Row-Col span="18">
              <!-- <i-input v-model="config.keyValueImpressionCaps" :number="true" clearable/> -->
              <ImpressionCap :campaignConfiguration=true :maximumImpressions="config.campaign.maximumImpressions" ref="impressionCapCompRef"/>
            </Row-Col>
          </Row>
        </Form-Item>
        <Form-Item label="Impression Interval" key="impressionIntervalValue" prop="impressionIntervalValue">
          <Row>
            <Row-Col span="18">
                <Select
                    v-model="config.impressionIntervalValue"
                    placeholder="Select impression interval"
                    :transfer="true"
                >
                    <Option
                        v-for="(id, name) in molocoEnums.impressionInterval"
                        :key="parseInt(id)"
                        :value="parseInt(id)"
                        >{{ name }}</Option
                    >
                </Select>
            </Row-Col>
          </Row>
        </Form-Item>
        <!-- Added Budget Package but no need to show it -->
        <Form-Item label="Budget Package" v-if="false">
          <Row>
            <Row-Col span="18">
              <i-input v-model="config.budgetPackage" />
            </Row-Col>
          </Row>
        </Form-Item>
        <Form-Item label="Configuration Name" key="name" prop="name">
          <Row>
            <Row-Col span="18">
              <i-input v-model="config.name" />
            </Row-Col>
          </Row>
        </Form-Item>
      </div>
    </Form>
</template>
<script>
import {
  Divider,
  Row,
  Col,
  Form,
  FormItem,
  Select,
  Option,
  Input,
  DatePicker,
  RadioGroup,
  Radio,
  Checkbox,
  OptionGroup
} from "iview";
import { mapState, mapMutations, mapActions } from "vuex";
import * as molocoEnums from "../../Constants/MolocoEnums.js";
import { APIService } from "../../ApiService.js";
import ImpressionCap from "./ImpressionCap.vue";
import CLHelperMethodsMixin from "../../Mixins/CLHelperMethods"

export default {
  props: {
    loadExistingConfigId: { default: "" },
    showConfigForm: { default: false },
    isExistingConfig: { default: false },
    nomenclatureMacro: { default: null }
  },
  components: {
    Divider,
    Row,
    "Row-Col": Col,
    Form,
    "Form-Item": FormItem,
    Select,
    Option,
    "i-input": Input,
    DatePicker,
    RadioGroup,
    Radio,
    Checkbox,
    ImpressionCap,
    OptionGroup
  },
  mounted () {
    // if (!this.$store.state.dbLocations.length) {
    //   await APIService.getLocations()
    //     .then(response => {
    //       this.$store.commit("set_dbLocations", response.data.data)
    //     })
    // }
    this.setScheduleOptions()
    this.addImpressionIntervalToConfig(this.config.impressionIntervalValue);
  },
  mixins: [CLHelperMethodsMixin],
  data () {
    const numberValidator = (value, min = 1, max = null) => {
      const num = parseInt(value);

      if (isNaN(num)) return new Error("Please enter a valid number");
      else if (num < min && max == null) return new Error(`Then value cannot be less than ${min}`);
      else if (num < min || (max != null && num > max)) return new Error(`The value must be between ${min} and ${max}`);
    };

    const dailyMaxImpressionCapValidator = (value) => {
      if (!value) return;

      let dailyMaxImpressionCap = Number(value);
      let maxImpressionCap = Number(this.config.campaign.maximumImpressions);

      if (isNaN(maxImpressionCap)) maxImpressionCap = 0;

      if (isNaN(dailyMaxImpressionCap)) {
        return new Error("Please enter a valid number");
      } else if (dailyMaxImpressionCap > maxImpressionCap) {
        return new Error("The value cannot be more than Campaign Maximum Impressions");
      } else {
        return numberValidator(dailyMaxImpressionCap, 1);
      }
    };

    const urlValidator = (rule, value, callback) => {
      if (this.isLeadsObjective && !value) {
        callback(new Error("Landing URL is required"));
      }
      if (value && !this.isValidURL(value)) {
        callback(new Error("Please enter a valid Landing URL"));
      }
      callback();
    };

    const scheduleValidator = (rule, value, callback) => {
      if (!value.start) {
        callback(new Error("Please select a Start Date"));
      }
      if (value.end && value.start.getTime() >= value.end.getTime()) {
        callback(new Error("End time must be greater than start time"));
      }
      callback();
    };

    return {
      config: {
        searchEngineTypeId: 45,
        name: "",
        bpseId: 0,
        campaign: {
          name: "",
          objective: 2,
          app: "",
          appPlatform: "",
          priority: 4,
          mmpTrackingLink: "",
          mmpTrackingCompany: "",
          adUnitTargeting: [],
          landingUrl: "",
          jioAdsCampaignId: "",
          roId: "",
          maximumImpressions: 0,
          country: "IND",
          conversionSettings: "",
          status: 1,
          description: ""
        },
        dealType: 2,
        targetCpm: 0,
        targetCpl: 0,
        schedule: {
          start: "",
          end: "",
          options: {
            startDateOptions: {},
            endDateOptions: {}
          }
        },
        dailyMaxImpressions: "",
        impressionInterval: {},
        cpiCeiling: 0,
        budgetType: 1,
        averageDailyBudget: 0,
        fixedDailyBudget: 0,
        weeklyDailyBudget: {},
        fixedBudgetType: 1,
        keyValueImpressionCaps: null,
        impressionIntervalValue: null,
        budgetPackage: 0,
        isScheduleEndDateNotRequired: false
      },
      molocoEnums,
      brandApps: {
        data: [],
        loading: false
      },
      mmpTrackingLinks: {
        data: [],
        loading: false
      },
      conversionSettings: {
        data: [],
        loading: false
      },
      isNomenclatureEnabled: false,
      validationRules: {
        bpseId: [
          {
            validator: (rule, value, callback) => {
              if (this.checkIfValid && (isNaN(value) || value <= 0)) {
                callback(new Error("Please select an Account"));
              }
              callback();
            }
          }
        ],
        "campaign.name": [
          { required: true, message: "Please enter a Campaign Name" }
        ],
        "campaign.status": [
          { required: true, message: "Please select Campaign Status" }
        ],
        "campaign.objective": [
          { required: true, message: "Please select a Campaign Objective" }
        ],
        "campaign.app": [
          { required: true, message: "Please select an App" }
        ],
        "campaign.mmpTrackingLink": [
          { required: true, message: "Please select a MMP Tracking Link" }
        ],
        "campaign.priority": [
          { required: true, message: "Please enter a Campaign Priority" }
        ],
        "campaign.adUnitTargeting": [
          {
            required: true,
            message: "Select at least one AdUnitTargeting",
            trigger: "change",
            type: "array",
            min: 1
          }
        ],
        "campaign.landingUrl": [
          { required: false, validator: urlValidator }
        ],
        "campaign.country": [
          { required: true, message: "Please select a Country" }
        ],
        "campaign.roId": [
          { required: true, message: "Please enter a Campaign RO_ID" }
        ],
        dealType: [
          { required: true, message: "Please select Deal type" }
        ],
        targetCpm: [
          { required: true, validator (rule, value, callback) { callback(numberValidator(value, 0, 100000000)); } }
        ],
        targetCpl: [
          { required: true, validator (rule, value, callback) { callback(numberValidator(value, 0, 100000000)); } }
        ],
        budgetType: [
          { required: true, message: "Please select a Budget Type" }
        ],
        averageDailyBudget: [
          { required: true, validator (rule, value, callback) { callback(numberValidator(value, 100)) } }
        ],
        fixedBudgetType: [
          { required: true, message: "Please select Fixed Budget Type" }
        ],
        fixedDailyBudget: [
          { required: true, validator (rule, value, callback) { callback(numberValidator(value, 100)) } }
        ],
        ...Object
          .values(molocoEnums.daysOfWeek)
          .reduce((l, day) => ({ ...l, [`weeklyDailyBudget.${day}`]: { required: true, validator (rule, value, callback) { callback(numberValidator(value, 100)); } } }), {}),
        "schedule": [
          { required: true, validator: scheduleValidator }
        ],
        "campaign.maximumImpressions": [
          { required: true, validator (rule, value, callback) { callback(numberValidator(value, 1, 1000000000000)); } }
        ],
        "dailyMaxImpressions": [
          { required: false, validator (rule, value, callback) { callback(dailyMaxImpressionCapValidator(value)); } }
        ],
        impressionIntervalValue: [
          { required: true, message: "Please select a Impression Interval" }
        ],
        name: [
          { required: true, message: "Please enter a Configuration name" }
        ]
      }
    };
  },
  watch: {
    async loadExistingConfigId (id) {
      if (id) {
        const existingConfig = JSON.parse(this.campaignLauncherConfigs[id].config);
        const config = JSON.parse(this.campaignLauncherConfigs[id].config);

        // config.schedule.start = config.schedule.start && new Date(config.schedule.start);
        // config.schedule.end = config.schedule.end && new Date(config.schedule.end);
        config.schedule.options = {};

        this.config = config;

        if (this.isAppInstallsObjective && this.config.budgetType == this.molocoEnums.budgetTypes["Fixed Budget"] && this.config.fixedBudgetType == this.molocoEnums.fixedBudgetTypes["DayOfWeek"]) {
          this.config.weeklyDailyBudget = Object
            .keys(existingConfig.weeklyDailyBudget)
            .reduce((l, day) => ({ ...l, [this.molocoEnums.daysOfWeek[day]]: existingConfig.weeklyDailyBudget[day] }), {});
        }

        if (this.isAppInstallsObjective) {
          await this.fetchBrandAppsAndMMPTrackingLinks();

          this.config.campaign.app = existingConfig.campaign.app;
          this.config.campaign.mmpTrackingLink = existingConfig.campaign.mmpTrackingLink;
        }

        if (!this.isExistingConfig) {
          this.config.name = this.appendCopyToConfigName(this.config.name)
        }
      }
    },
    "config.isScheduleEndDateNotRequired" (value) {
      if (value) {
        this.config.schedule.end = "";
        this.setScheduleOptions();
      }
    },
    "config.impressionIntervalValue": function (value) {
      if (value) {
        this.addImpressionIntervalToConfig(value)
      }
    },
    "config.schedule.start": function (value) {
      if (value) {
        if (value > this.config.schedule.end) {
          this.config.schedule.end = "";
        }
        this.setScheduleOptions();
      }
    },
    "config.bpseId": function () {
      this.fetchBrandAppsAndMMPTrackingLinks();
    },
    "config.campaign.objective": function () {
      this.fetchBrandAppsAndMMPTrackingLinks();
    },
    "config.campaign.mmpTrackingLink": function (value) {
      if (!value) return;

      const trackingLink = this.mmpTrackingLinks.data.find(e => e.id === value);

      if (!trackingLink) return;

      this.config.campaign.mmpTrackingCompany = trackingLink.tracking_company;
    }
  },
  computed: {
    ...mapState([
      "molocoAccounts",
      "bpCurrencySymbol",
      "campaignLauncherConfigs",
      "isValidMolocoTarget",
      "molocoBrandApps",
      "molocoTrackingLinks"
    ]),
    isAppInstallsObjective () {
      return this.config.campaign.objective == molocoEnums.objectives['App Installs']
    },
    isBrandAwarenessObjective () {
      return this.config.campaign.objective == molocoEnums.objectives["Brand Awareness"]
    },
    isLeadsObjective () {
      return this.config.campaign.objective == molocoEnums.objectives["Leads"]
    },
    endDateBeginsFrom () {
      if (this.config.schedule.start) {
        return new Date(this.config.schedule.start)
      }
      return new Date(Date.now())
    },
    computedAdUnitTargetingOptions () {
      var adUnitTargetingOptions = {}
      molocoEnums.adUnitTargetingTypes.forEach(type => {
        let filteredKeys = Object.keys(molocoEnums.adUnitTargeting).filter(key => key.startsWith(type.slice(0, 3)));
        let filteredObject = {};
        filteredKeys.forEach(key => {
          filteredObject[key.split('_').join(' ')] = molocoEnums.adUnitTargeting[key]
        });
        adUnitTargetingOptions[type] = filteredObject
      })
      return adUnitTargetingOptions
    },
    computedDealTypes () {
      if (!this.config.campaign.objective) return {};

      return this.molocoEnums.dealTypes[this.config.campaign.objective];
    },
    isBrandAppsDisabled () {
      return this.brandApps.loading || !this.config.bpseId;
    },
    isMMPTrackingLinksDisabled () {
      return this.mmpTrackingLinks.loading || !this.config.campaign.app;
    },
    brandAppsPlaceholder () {
      if (this.brandApps.loading) return "Loading...";
      else if (!this.config.bpseId) return "Please select an Ad Account";
      else return "Select a Brand App";
    },
    mmpTrackingLinksPlaceholder () {
      if (this.mmpTrackingLinks.loading) return "Loading...";
      else if (!this.config.campaign.app) return "Please select a Brand App";
      else return "Select a MMP tracking link";
    },
    filteredMMPTrackingLinks () {
      if (!this.config.campaign.app) return [];

      const app = this.brandApps.data.find(e => e.bundle_id === this.config.campaign.app);

      if (!app || !app.os) return [];

      this.config.campaign.appPlatform = app.os;

      const platform = this.getKeyByValue(molocoEnums.osTypes, app.os);

      return this.mmpTrackingLinks.data.filter(e => e.device_os === platform);
    }
  },
  methods: {
    ...mapMutations(["set_dbLocations"]),
    ...mapActions(["fetchMolocoBrandAppsAndMMPTrackingLinks"]),
    selectAllTargetingOptions (itemName) {
      var elements = this.computedAdUnitTargetingOptions;
      var targetOptions = Object.values(elements[itemName]);
      if (targetOptions.every(element => this.config.campaign.adUnitTargeting.includes(element))) {
        this.config.campaign.adUnitTargeting = this.config.campaign.adUnitTargeting.filter(element => !targetOptions.includes(element));
      } else {
        this.config.campaign.adUnitTargeting = [...this.config.campaign.adUnitTargeting, ...targetOptions.filter(elementX => !this.config.campaign.adUnitTargeting.includes(elementX))]
      }
    },
    resetFields () {
      // this.$refs.impressionCapCompRef.impressionCaps = [];
      // this.$refs.impressionCapCompRef.selectedCondition = "";
      this.$store.state.molocoImpressionCaps = [];
      this.$refs["molocoConfig"].resetFields();
    },
    isMolocoFormValid (saveCallback) {
      this.checkIfValid = true;
      // this.config.keyValueImpressionCaps = this.$refs.impressionCapCompRef.impressionCaps; //instead of refs now can use store
      // this.$refs["molocoConfig"].validate(valid => {
      //   if (valid && this.isValidMolocoTarget) {
      //     saveCallback();
      //   }
      //   this.checkIfValid = false;
      // });
      this.$refs["molocoConfig"].validate(valid => {
        if (valid) {
          saveCallback();
        }
        this.checkIfValid = false;
      });
    },
    setScheduleOptions () {
      var self = this;
      this.config.schedule["options"] = {}
      this.config.schedule.options["startDateOptions"] = {
        disabledDate (date) {
          return date && date.valueOf() < Date.now() - 86400000;
        }
      }
      this.config.schedule.options["endDateOptions"] = {
        disabledDate (date) {
          if (self.config.isScheduleEndDateNotRequired) {
            return date
          }
          return date && (date.valueOf() < Date.now() - 86400000 || (date.valueOf() <= self.config.schedule.start.valueOf() - 86400000));
        }
      }
    },
    getConfig () {
      this.convertScheduleToUtcTime()
      return this.config;
    },
    getKeyByValue (object, value) {
      return Object.keys(object).find(key => object[key] === value);
    },
    addImpressionIntervalToConfig (id) {
      if (!id) return;

      var selectedImpressionInterval = this.getKeyByValue(molocoEnums.impressionInterval, parseInt(id))
      if (selectedImpressionInterval) {
        var intervalParts = selectedImpressionInterval.split(' ')
        var selectedImpressionIntervalObject = {
          amount: intervalParts[0],
          unit: intervalParts[1].toUpperCase()
        }
        if (selectedImpressionIntervalObject.unit.includes('MIN')) {
          selectedImpressionIntervalObject["unit"] = this.getKeyByValue(this.molocoEnums.impressionIntervalUnits, this.molocoEnums.impressionIntervalUnits.MINUTE)
        }
        if (selectedImpressionIntervalObject.unit.includes('HOUR')) {
          selectedImpressionIntervalObject["unit"] = this.getKeyByValue(this.molocoEnums.impressionIntervalUnits, this.molocoEnums.impressionIntervalUnits.HOUR)
        }
        this.config.impressionInterval = selectedImpressionIntervalObject
      }
    },
    convertScheduleToUtcTime () {
      if (this.config.schedule.start) {
        var utcDate = new Date(this.config.schedule.start.getTime() - this.config.schedule.start.getTimezoneOffset() * 60000);
        this.config.schedule.start = utcDate
      }
      if (this.config.schedule.end) {
        var utcEndDate = new Date(this.config.schedule.end.getTime() - this.config.schedule.end.getTimezoneOffset() * 60000);
        this.config.schedule.end = utcEndDate
      }
    },
    isValidURL (url) {
      const urlPattern = /^(https?|ftp):\/\/[^\s/$.?#].[^\s]*$/;
      var isValid = false
      if (url) {
        isValid = urlPattern.test(url);
      }
      return isValid
    },
    async fetchBrandAppsAndMMPTrackingLinks () {
      this.config.campaign.app = null;
      this.config.campaign.mmpTrackingLink = null;

      if (!this.isAppInstallsObjective || !this.config.bpseId) return;

      const account = this.molocoAccounts.find(e => e.id == this.config.bpseId);

      if (!account) return;

      this.brandApps = { loading: true, data: [] };
      this.mmpTrackingLinks = { loading: true, data: [] };

      await this.fetchMolocoBrandAppsAndMMPTrackingLinks({ bpseId: account.id, adAccountId: account.accountId, productId: account.customerId });

      this.brandApps = { loading: false, data: this.molocoBrandApps[account.customerId] || [] };
      this.mmpTrackingLinks = { loading: false, data: this.molocoTrackingLinks[account.customerId] || [] };
    }
  }
};
</script>
<style scoped>
.targetOptionGroup{
  cursor: pointer !important;
}
.spaceBetween {
  padding-right: 10px !important;
}
</style>
