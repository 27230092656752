<template>
  <Modal :value="activeCampaignId != null" class="campaign-edit-modal" width="75%" :scrollable="true"
    @on-cancel="close">
    <template v-slot:header>
      <h3 class="ccHeader">Campaign Edit</h3>
    </template>
    <template>
      <Form label-position="right" :label-width="200" :model="campaign" :rules="validationRules"
        ref="campaign-edit-form">
        <Spin v-if="loading" fix>
          <Icon type="ios-loading" class="spin-icon" />
        </Spin>
        <FormItem label="Campaign" key="id" prop="id">
          <Row>
            <Column span="18">
              <Select v-model="campaign.id" :filterable="true">
                <Option v-for="item in campaigns" :key="item.id" :value="item.id">{{ item.name }} ({{ item.searchEngineCampaignId }})</Option>
              </Select>
            </Column>
          </Row>
        </FormItem>
        <Divider orientation="left">Campaign Settings</Divider>
        <FormItem label="Campaign Name" key="name" prop="name">
          <Row>
            <Column span="18">
              <IvInput v-model="campaign.name" clearable />
            </Column>
          </Row>
          <Row v-if="isUpdated.name">
            <Column span="18">
              <Alert type="warning" show-icon closable>
                <span class="alert-subheading">Outdated! Name was updated on Moloco</span>
                <MolocoOverwrite slot="desc" :moloco-value="molocoCampaign.title" :dx-value="dxCampaign.name"
                  @overwrite="overwrite(molocoEnums.campaignEditableFields.Name, molocoCampaign.title)"
                  @useLive="useLive(molocoEnums.campaignEditableFields.Name, molocoCampaign.title)" />
              </Alert>
            </Column>
          </Row>
        </FormItem>
        <FormItem label="Description" key="description" prop="description">
          <Row>
            <Column span="18">
              <IvInput v-model="campaign.description" clearable />
            </Column>
          </Row>
          <Row v-if="isUpdated.description">
            <Column span="18">
              <Alert type="warning" show-icon closable>
                <span class="alert-subheading">Outdated! Description was updated on Moloco</span>
                <MolocoOverwrite slot="desc" :moloco-value="molocoCampaign.description"
                  :dx-value="dxCampaign.description"
                  @overwrite="overwrite(molocoEnums.campaignEditableFields.Description, molocoCampaign.description)"
                  @useLive="useLive(molocoEnums.campaignEditableFields.Description, molocoCampaign.description)" />
              </Alert>
            </Column>
          </Row>
        </FormItem>
        <FormItem label="Campaign Status" key="status" prop="status">
          <Row>
            <Column>
              <RadioGroup v-model="campaign.status">
                <Radio :label="molocoEnums.Campaignstatus.Active"><span>Active</span></Radio>
                <Radio :label="molocoEnums.Campaignstatus.Paused"><span>Paused</span></Radio>
              </RadioGroup>
            </Column>
          </Row>
          <Row v-if="isUpdated.status">
            <Column span="18">
              <Alert type="warning" show-icon closable>
                <span class="alert-subheading">Outdated! Status was updated on Moloco</span>
                <MolocoOverwrite slot="desc"
                  :moloco-value="getKeyByValue(molocoEnums.CampaignEnablingState, molocoCampaign.enabling_state)"
                  :dx-value="getKeyByValue(molocoEnums.Campaignstatus, dxCampaign.status)"
                  @overwrite="overwrite(molocoEnums.campaignEditableFields.Status, molocoEnums.Campaignstatus[getKeyByValue(molocoEnums.CampaignEnablingState, molocoCampaign.enabling_state)])"
                  @useLive="useLive(molocoEnums.campaignEditableFields.Status, molocoEnums.Campaignstatus[getKeyByValue(molocoEnums.CampaignEnablingState, molocoCampaign.enabling_state)])" />
              </Alert>
            </Column>
          </Row>
        </FormItem>
        <FormItem label="Objective" key="objective" prop="objective">
          <Row>
            <Column span="18">
              <Select v-model="campaign.objective" placeholder="Select an Objective" :disabled="true">
                <Option v-for="(id, name) in molocoEnums.objectives" :key="parseInt(id)" :value="parseInt(id)">
                  {{ name }}
                </Option>
              </Select>
            </Column>
          </Row>
        </FormItem>
        <FormItem v-if="isAppInstallsObjective" label="Brand App" key="app" prop="app">
          <Row>
            <Column v-model="campaign.app" span="18">
              <Select :placeholder="brandAppsPlaceholder" :disabled="isAppInstallsObjective || isBrandAppsDisabled"
                not-found-text="No Apps found for the selected Account">
                <!-- <Option v-for="app in brandApps.data" :key="app.bundle_id" :value="app.bundle_id">
                  {{ app.localized_app_name.en }} ({{ getKeyByValue(molocoEnums.osTypes, app.os) }})
                </Option> -->
              </Select>
            </Column>
          </Row>
        </FormItem>
        <FormItem v-if="isAppInstallsObjective" label="MMP Tracking Link" key="mmpTrackingLink" prop="mmpTrackingLink">
          <Row>
            <Column span="18">
              <Select v-model="campaign.mmpTrackingLink" :placeholder="mmpTrackingLinksPlaceholder"
                :disabled="isAppInstallsObjective || isMMPTrackingLinksDisabled"
                not-found-text="No Tracking Links found for the selected App">
                <!-- <Option v-for="link in filteredMMPTrackingLinks" :key="link.id" :value="link.id">
                  {{ link.title }}
                </Option> -->
              </Select>
            </Column>
          </Row>
        </FormItem>
        <FormItem label="Campaign Priority" key="priority" prop="priority">
          <Row>
            <Column span="18">
              <Select v-model="campaign.priority" placeholder="Select Campaign Priority">
                <Option v-for="(id, priority) in molocoEnums.priorityList" :key="parseInt(id)" :value="parseInt(id)">
                  {{ priority.split("_").join(" ") }}
                </Option>
              </Select>
            </Column>
          </Row>
          <Row v-if="isUpdated.priority">
            <Column span="18">
              <Alert type="warning" show-icon closable>
                <span class="alert-subheading">Outdated! Priority was updated on Moloco</span>
                <MolocoOverwrite slot="desc" :moloco-value="molocoCampaign.jio_support.priority.split('_').join(' ')"
                  :dx-value="getKeyByValue(molocoEnums.priorityList, dxCampaign.priority).split('_').join(' ')"
                  @overwrite="overwrite(molocoEnums.campaignEditableFields.Priority, molocoEnums.priorityList[molocoCampaign.jio_support.priority])"
                  @useLive="useLive(molocoEnums.campaignEditableFields.Priority, molocoEnums.priorityList[molocoCampaign.jio_support.priority])" />
              </Alert>
            </Column>
          </Row>
        </FormItem>
        <FormItem label="Ad Unit Targeting" key="adUnits" prop="adUnits">
          <Row>
            <Column span="18">
              <Select v-model="campaign.adUnits" placeholder="Select Ad Unit Targeting values" multiple>
                <OptionGroup v-for="(value, group) in adUnitOptionGroups" :label="group"
                  @click.native="toggleAdUnits(value)" :key="group" class="adunit-option-group">
                  <Option v-for="(id, name) in value" :key="id" :value="id">
                    {{ name }}
                  </Option>
                </OptionGroup>
              </Select>
            </Column>
          </Row>
          <Row v-if="isUpdated.adUnits">
            <Column span="18">
              <Alert type="warning" show-icon closable>
                <span class="alert-subheading">Outdated! Ad Unit Targets were updated on Moloco</span>
                <MolocoOverwrite slot="desc"
                  :moloco-value="molocoCampaign.ad_units.map(e => getKeyByValue(molocoEnums.adUnitTargeting, e).split('_').join(' ')).join(', ')"
                  :dx-value="dxCampaign.adUnits.map(e => getKeyByValue(molocoEnums.adUnitTargeting, e).split('_').join(' ')).join(', ')"
                  @overwrite="overwrite(molocoEnums.campaignEditableFields.AdUnitTargeting, molocoCampaign.ad_units)"
                  @useLive="useLive(molocoEnums.campaignEditableFields.AdUnitTargeting, molocoCampaign.ad_units)" />
              </Alert>
            </Column>
          </Row>
        </FormItem>
        <FormItem v-if="isBrandAwarenessObjective || isLeadsObjective" :required="isLeadsObjective" label="Landing URL" key="landingUrl" prop="landingUrl">
          <Row>
            <Column span="18">
              <IvInput v-model="campaign.landingUrl" clearable />
            </Column>
          </Row>
          <Row v-if="isUpdated.landingUrl">
            <Column span="18">
              <Alert type="warning" show-icon closable>
                <span class="alert-subheading">Outdated! Landing URL was updated on Moloco</span>
                <MolocoOverwrite slot="desc" :moloco-value="molocoCampaign.landing_url"
                  :dx-value="dxCampaign.landingUrl"
                  @overwrite="overwrite(molocoEnums.campaignEditableFields.LandingUrl, molocoCampaign.landing_url)"
                  @useLive="useLive(molocoEnums.campaignEditableFields.LandingUrl, molocoCampaign.landing_url)" />
              </Alert>
            </Column>
          </Row>
        </FormItem>
        <FormItem label="Country" key="country" prop="country">
          <Row>
            <Column span="18">
              <Select v-model="campaign.country" placeholder="Select country" :disabled="true">
                <Option v-for="(id, country) in molocoEnums.countries" :key="id" :value="id">
                  {{ country }}
                </Option>
              </Select>
            </Column>
          </Row>
        </FormItem>
        <FormItem v-if="isBrandAwarenessObjective || isLeadsObjective" label="JIO Ads Campaign ID" key="jioId" prop="jioId">
          <Row>
            <Column span="18">
              <IvInput v-model="campaign.jioId" clearable />
            </Column>
          </Row>
          <Row v-if="isUpdated.jioId">
            <Column span="18">
              <Alert type="warning" show-icon closable>
                <span class="alert-subheading">Outdated! Jio Campaign Id was updated on Moloco</span>
                <MolocoOverwrite slot="desc" :moloco-value="molocoCampaign.jio_support.jio_campaign_id"
                  :dx-value="dxCampaign.jioId"
                  @overwrite="overwrite(molocoEnums.campaignEditableFields.JioCampaignId, molocoCampaign.jio_support.jio_campaign_id)"
                  @useLive="useLive(molocoEnums.campaignEditableFields.JioCampaignId, molocoCampaign.jio_support.jio_campaign_id)" />
              </Alert>
            </Column>
          </Row>
        </FormItem>
        <FormItem label="Campaign RO_ID" key="roId" prop="roId">
          <Row>
            <Column span="18">
              <IvInput v-model="campaign.roId" clearable />
            </Column>
          </Row>
          <Row v-if="isUpdated.roId">
            <Column span="18">
              <Alert type="warning" show-icon closable>
                <span class="alert-subheading">Outdated! RO ID was updated on Moloco</span>
                <MolocoOverwrite slot="desc" :moloco-value="molocoCampaign.custom_key_values.jio_ro_id"
                  :dx-value="dxCampaign.roId"
                  @overwrite="overwrite(molocoEnums.campaignEditableFields.RoId, molocoCampaign.custom_key_values.jio_ro_id)"
                  @useLive="useLive(molocoEnums.campaignEditableFields.RoId, molocoCampaign.custom_key_values.jio_ro_id)" />
              </Alert>
            </Column>
          </Row>
        </FormItem>
        <FormItem v-if="isAppInstallsObjective" label="Conversion Settings" key="conversionSettings"
          prop="conversionSettings">
          <Row>
            <Column span="18">
              <Select v-model="campaign.conversionSettings" placeholder="Select Conversion Settings"
                :disabled="isAppInstallsObjective">
                <!-- <Option v-for="settings in conversionSettings.data" :key="settings" :value="settings">
                  {{ settings }}
                </Option> -->
              </Select>
            </Column>
          </Row>
        </FormItem>
        <Divider orientation="left">Budget Settings</Divider>
        <FormItem label="Deal type" key="dealType" prop="dealType">
          <Row>
            <Column span="18">
              <Select v-model="campaign.dealType" placeholder="Select Deal Type">
                <Option v-for="(id, deal) in dealTypes" :key="parseInt(id)" :value="parseInt(id)">
                  {{ deal.split("_").join(" ") }}
                </Option>
              </Select>
            </Column>
          </Row>
          <Row v-if="isUpdated.dealType">
            <Column span="18">
              <Alert type="warning" show-icon closable>
                <span class="alert-subheading">Outdated! Deal Type was updated on Moloco</span>
                <MolocoOverwrite slot="desc" :moloco-value="molocoCampaign.jio_support.deal_type.split('_').join(' ')"
                  :dx-value="getKeyByValue(dealTypes, dxCampaign.dealType).split('_').join(' ')"
                  @overwrite="overwrite(molocoEnums.campaignEditableFields.DealType, dealTypes[molocoCampaign.jio_support.deal_type])"
                  @useLive="useLive(molocoEnums.campaignEditableFields.DealType, dealTypes[molocoCampaign.jio_support.deal_type])" />
              </Alert>
            </Column>
          </Row>
        </FormItem>
        <FormItem :label="`Target ${isLeadsObjective ? 'CPL' : 'CPM'}`" key="bid" prop="bid">
          <Row>
            <Column span="6">
              <IvInput v-model="campaign.bid" type="number">
                <span slot="prepend">{{ bpCurrencySymbol }}</span>
              </IvInput>
            </Column>
          </Row>
          <Row v-if="isUpdated.bid">
            <Column span="18">
              <Alert type="warning" show-icon closable>
                <span class="alert-subheading">
                  Outdated! Target {{ isLeadsObjective ? "CPL" : "CPM" }} was updated on Moloco
                </span>
                <MolocoOverwrite slot="desc" :moloco-value="`${bpCurrencySymbol} ${molocoCampaignBid}`"
                  :dx-value="`${bpCurrencySymbol} ${dxCampaign.bid}`"
                  @overwrite="overwrite(molocoEnums.campaignEditableFields.Bid, molocoCampaignBid)"
                  @useLive="useLive(molocoEnums.campaignEditableFields.Bid, molocoCampaignBid)" />
              </Alert>
            </Column>
          </Row>
        </FormItem>
        <template v-if="isAppInstallsObjective">
          <FormItem label="CPI Ceiling" key="cpiCeiling" prop="cpiCeiling">
            <Row>
              <Column span="6">
                <IvInput v-model="campaign.cpiCeiling" type="number" :disabled="isAppInstallsObjective">
                  <span slot="prepend">{{ bpCurrencySymbol }}</span>
                </IvInput>
              </Column>
            </Row>
          </FormItem>
          <FormItem label="Budget" key="budget.type" prop="budget.type">
            <Row>
              <Column>
                <RadioGroup v-model="campaign.budget.type">
                  <Radio :label="molocoEnums.budgetTypes[`Average Daily Budget`]" :disabled="isAppInstallsObjective">
                    <span>Average Daily Budget</span>
                  </Radio>
                  <Radio :label="molocoEnums.budgetTypes[`Fixed Budget`]" :disabled="isAppInstallsObjective">
                    <span>Fixed Budget</span>
                  </Radio>
                </RadioGroup>
              </Column>
            </Row>
          </FormItem>
          <FormItem v-if="campaign.budget.type == molocoEnums.budgetTypes['Average Daily Budget']" label=""
            key="budget.averageDaily" prop="budget.averageDaily">
            <Row>
              <Column span="6">
                <span style="display: inline-block;">
                  <IvInput v-model="campaign.budget.averageDaily" type="number" :disabled="isAppInstallsObjective"
                    clearable>
                    <span slot="prepend">{{ bpCurrencySymbol }}</span>
                  </IvInput>
                </span>
              </Column>
            </Row>
          </FormItem>
          <template v-if="campaign.budget.type == molocoEnums.budgetTypes['Fixed Budget']">
            <FormItem label="" key="budget.fixedType" prop="budget.fixedType">
              <Row>
                <Column>
                  <RadioGroup v-model="campaign.budget.fixedType">
                    <Radio :label="molocoEnums.fixedBudgetTypes.Daily" :disabled="isAppInstallsObjective">
                      <span>Daily</span>
                    </Radio>
                    <Radio :label="molocoEnums.fixedBudgetTypes.DayOfWeek" :disabled="isAppInstallsObjective">
                      <span>Day Of Week</span>
                    </Radio>
                  </RadioGroup>
                </Column>
              </Row>
            </FormItem>
            <FormItem v-if="campaign.budget.fixedType == molocoEnums.fixedBudgetTypes.Daily" label=""
              key="budget.fixedDaily" prop="budget.fixedDaily" :disabled="isAppInstallsObjective">
              <Row>
                <Column span="6">
                  <span style="display: inline-block;">
                    <IvInput v-model=campaign.budget.fixedDaily type="number" clearable>
                      <span slot="prepend">{{ bpCurrencySymbol }}</span>
                    </IvInput>
                  </span>
                </Column>
              </Row>
            </FormItem>
            <template v-if="campaign.fixedBudgetType == molocoEnums.fixedBudgetTypes.DayOfWeek">
              <FormItem label="" v-for="(value, day) in molocoEnums.daysOfWeek" :key="`budget.weeklyDaily.${value}`"
                :prop="`budget.weeklyDaily.${value}`" :disabled="isAppInstallsObjective">
                <Row>
                  <Column span="6">
                    <span style="display: inline-block;">
                      <IvInput v-model="campaign.budget.weeklyDaily[value]" type="number" clearable>
                        <span slot="prepend">{{ day.slice(0, 3) }} {{ bpCurrencySymbol }}</span>
                      </IvInput>
                    </span>
                  </Column>
                </Row>
              </FormItem>
            </template>
          </template>
        </template>
        <FormItem label="Schedule" key="schedule" prop="schedule">
          <Row>
            <Column :md="8" :lg="6">
              <DatePicker v-model="campaign.schedule.start" class="spaceBetween" type="datetime"
                format="yyyy-MM-dd HH:mm" placeholder="Select start Date and Time" :options="scheduleOptions.start"
                :disabled="!isUpcomingCampaign">
              </DatePicker>
            </Column>
            <Column :md="8" :lg="6">
              <DatePicker v-model="campaign.schedule.end" class="spaceBetween" type="datetime" format="yyyy-MM-dd HH:mm"
                placeholder="Select end Date and Time" :disabled="campaign.schedule.noEnd"
                :options="scheduleOptions.end" :start-date="endDateBeginsFrom">
              </DatePicker>
            </Column>
            <Column :md="8" :lg="6">
              <Checkbox v-model="campaign.schedule.noEnd">
                No End Date
              </Checkbox>
            </Column>
          </Row>
          <Row v-if="isUpdated.schedule">
            <Column span="18">
              <Alert type="warning" show-icon closable>
                <span class="alert-subheading">Outdated! Schedule was updated on Moloco</span>
                <MolocoOverwrite slot="desc"
                  :moloco-value="`Start - ${getReadableDate(molocoCampaign.schedule.start)}, End - ${getReadableDate(molocoCampaign.schedule.end)}`"
                  :dx-value="`Start - ${getReadableDate(dxCampaign.schedule.start)}, End - ${getReadableDate(dxCampaign.schedule.end)}`"
                  @overwrite="overwrite(molocoEnums.campaignEditableFields.Schedule, { start: molocoCampaign.schedule.start, end: molocoCampaign.schedule.end || null })"
                  @useLive="useLive(molocoEnums.campaignEditableFields.Schedule, { start: molocoCampaign.schedule.start, end: molocoCampaign.schedule.end || null })" />
              </Alert>
            </Column>
          </Row>
        </FormItem>
        <FormItem v-if="isBrandAwarenessObjective || isLeadsObjective" label="Campaign Maximum Impressions" key="maxImpressionCap"
          prop="maxImpressionCap">
          <Row>
            <Column span="18">
              <IvInput v-model="campaign.maxImpressionCap" type="number" clearable />
            </Column>
          </Row>
          <Row v-if="isUpdated.maxImpressionCap">
            <Column span="18">
              <Alert type="warning" show-icon closable>
                <span class="alert-subheading">Outdated! Maximum Impression Cap was updated on Moloco</span>
                <MolocoOverwrite slot="desc"
                  :moloco-value="molocoCampaign.jio_support.campaign_max_imp_cap.max_imp_cap_count"
                  :dx-value="dxCampaign.maxImpressionCap"
                  @overwrite="overwrite(molocoEnums.campaignEditableFields.MaxImpressionCap, molocoCampaign.jio_support.campaign_max_imp_cap.max_imp_cap_count)"
                  @useLive="useLive(molocoEnums.campaignEditableFields.MaxImpressionCap, molocoCampaign.jio_support.campaign_max_imp_cap.max_imp_cap_count)" />
              </Alert>
            </Column>
          </Row>
        </FormItem>
        <FormItem v-if="isBrandAwarenessObjective || isLeadsObjective" label="Daily Maximum Impressions" key="dailyMaxImpressionCap"
          prop="dailyMaxImpressionCap">
          <Row>
            <Column span="18">
              <IvInput v-model="campaign.dailyMaxImpressionCap" type="number" clearable />
            </Column>
          </Row>
          <Row v-if="isUpdated.dailyMaxImpressionCap">
            <Column span="18">
              <Alert type="warning" show-icon closable>
                <span class="alert-subheading">Outdated! Daily Maximum Impression Cap was updated on Moloco</span>
                <MolocoOverwrite slot="desc" :moloco-value="molocoCampaign.jio_support.campaign_daily_imp_cap"
                  :dx-value="dxCampaign.dailyMaxImpressionCap"
                  @overwrite="overwrite(molocoEnums.campaignEditableFields.DailyMaxImpressionCap, molocoCampaign.jio_support.campaign_daily_imp_cap)"
                  @useLive="useLive(molocoEnums.campaignEditableFields.DailyMaxImpressionCap, molocoCampaign.jio_support.campaign_daily_imp_cap)" />
              </Alert>
            </Column>
          </Row>
        </FormItem>
        <FormItem label="Impression Interval" key="impressionInterval" prop="impressionInterval">
          <Row>
            <Column span="18">
              <Select v-model="campaign.impressionInterval" placeholder="Select impression interval">
                <Option v-for="key in Object.keys(impressionIntervalValues)" :key="key"
                  :value="JSON.stringify(impressionIntervalValues[key])">
                  {{ key }}
                </Option>
              </Select>
            </Column>
          </Row>
          <Row v-if="isUpdated.impressionInterval">
            <Column span="18">
              <Alert type="warning" show-icon closable>
                <span class="alert-subheading">Outdated! Impression Interval was updated on Moloco</span>
                <MolocoOverwrite slot="desc"
                  :moloco-value="getImpressionIntervalValue(molocoCampaign.jio_support.capper.imp_interval)"
                  :dx-value="getImpressionIntervalValue(dxCampaign.impressionInterval)"
                  @overwrite="overwrite(molocoEnums.campaignEditableFields.ImpressionInterval, JSON.stringify(molocoCampaign.jio_support.capper.imp_interval))"
                  @useLive="useLive(molocoEnums.campaignEditableFields.ImpressionInterval, JSON.stringify(molocoCampaign.jio_support.capper.imp_interval))" />
              </Alert>
            </Column>
          </Row>
        </FormItem>
      </Form>
    </template>
    <template v-slot:footer>
      <Button id="reset" @click="reset" :disabled="loading || submit">
        Reset
      </Button>
      <Button id="close" @click="close" :disabled="loading || submit">
        Close
      </Button>
      <Button type="success" @click="save" :disabled="loading || submit">
        Save
      </Button>
    </template>
  </Modal>
</template>

<script>
import { Alert, Button, Checkbox, Col, DatePicker, Divider, Form, FormItem, Icon, Input, Modal, Option, OptionGroup, Radio, RadioGroup, Row, Select, Spin } from "iview";
import { mapState } from "vuex";
import { APIService } from "../../../ApiService.js";
import { EventBus } from "../../../EventBus.js";
import * as molocoEnums from "../../../Constants/MolocoEnums.js";
import MolocoOverwrite from "./MolocoOverwrite.vue";

export default {
  components: { Alert, Button, Checkbox, Column: Col, DatePicker, Divider, Form, FormItem, Icon, IvInput: Input, Modal, Option, OptionGroup, Radio, RadioGroup, Row, Select, Spin, MolocoOverwrite },
  props: {
    campaigns: {
      type: Array,
      required: true,
      default: () => []
    },
    activeCampaignId: {
      type: Number,
      required: false,
      default: () => null
    }
  },
  emits: ["close"],
  data () {
    const { campaignEditableFields } = molocoEnums;

    const numberValidator = (value, min = 1, max = null) => {
      const num = parseInt(value);

      if (isNaN(num)) return new Error("Please enter a valid number");
      else if (num < min && max == null) return new Error(`Then value cannot be less than ${min}`);
      else if (num < min || (max != null && num > max)) return new Error(`The value must be between ${min} and ${max}`);
    };

    const dailyMaxImpressionCapValidator = (value) => {
      if (!value) return;

      let dailyMaxImpressionCap = Number(value);
      let maxImpressionCap = Number(this.campaign.maxImpressionCap);

      if (isNaN(maxImpressionCap)) maxImpressionCap = 0;

      if (isNaN(dailyMaxImpressionCap)) {
        return new Error("Please enter a valid number");
      } else if (dailyMaxImpressionCap > maxImpressionCap) {
        return new Error("The value cannot be more than Campaign Maximum Impressions");
      } else {
        return numberValidator(dailyMaxImpressionCap, 1);
      }
    };

    const urlValidator = (rule, value, callback) => {
      if (this.isLeadsObjective && !value) {
        callback(new Error("Landing URL is required"));
      }
      if (value && !this.isValidURL(value)) {
        callback(new Error("Please enter a valid Landing Url"));
      }
      callback();
    };

    const scheduleValidator = (rule, value, callback) => {
      let { start, end, noEnd } = value;

      if (start && !(start instanceof Date)) start = new Date(start);
      if (end && !(end instanceof Date)) end = new Date(end);

      if (!start && !noEnd && !end) {
        callback(new Error("Please select start and end date / time"));
      } else if (!start) {
        callback(new Error("Please select start date / time"));
      } else if (!noEnd && !end) {
        callback(new Error("Please select end date / time"));
      } else if (!noEnd && start.getTime() >= end.getTime()) {
        callback(new Error("End date / time must be greater than start date / time"));
      } else callback();
    };

    return {
      molocoEnums,
      impressionIntervalValues: {
        "1 Min": { amount: "1", unit: "MINUTE" },
        "1 Day": { amount: "1", unit: "DAY" },
        "12 Hours": { amount: "12", unit: "HOUR" },
        "1 Hour": { amount: "1", unit: "HOUR" }
      },
      editableFieldPropertyMap: {
        [campaignEditableFields.Name]: "name",
        [campaignEditableFields.Description]: "description",
        [campaignEditableFields.Status]: "status",
        [campaignEditableFields.Priority]: "priority",
        [campaignEditableFields.AdUnitTargeting]: "adUnits",
        [campaignEditableFields.LandingUrl]: "landingUrl",
        [campaignEditableFields.JioCampaignId]: "jioId",
        [campaignEditableFields.RoId]: "roId",
        [campaignEditableFields.DealType]: "dealType",
        [campaignEditableFields.Bid]: "bid",
        [campaignEditableFields.Schedule]: "schedule",
        [campaignEditableFields.MaxImpressionCap]: "maxImpressionCap",
        [campaignEditableFields.DailyMaxImpressionCap]: "dailyMaxImpressionCap",
        [campaignEditableFields.ImpressionInterval]: "impressionInterval"
      },
      loading: false,
      submit: false,
      liveUpdates: [],
      dxCampaign: null,
      molocoCampaign: null,
      campaign: {
        id: null,
        name: null,
        description: null,
        status: null,
        objective: null,
        lineItemId: null,
        targetId: null,
        lineItemFractionId: null,
        app: null,
        mmpTrackingLink: null,
        priority: null,
        adUnits: [],
        landingUrl: null,
        country: null,
        jioId: null,
        roId: "",
        conversionSettings: null,
        dealType: null,
        bid: null,
        cpiCeiling: null,
        budget: {
          type: null,
          averageDaily: null,
          fixedType: null,
          fixedDaily: null,
          weeklyDaily: {}
        },
        schedule: {
          start: null,
          end: null,
          noEnd: null
        },
        maxImpressionCap: null,
        dailyMaxImpressionCap: null,
        impressionInterval: null,
        editedSummary: null
      },
      validationRules: {
        "id": [{ required: true, message: "Please select a Campaign" }],
        "name": [{ required: true, message: "Please enter Campaign Name" }],
        "description": [{ required: false }],
        "status": [{ required: true, message: "Please select Campaign Status" }],
        "objective": [{ required: true, message: "Please select Campaign Objective" }],
        "app": [{ required: true, message: "Please select an App" }],
        "mmpTrackingLink": [{ required: true, message: "Please select a MMP Tracking Link" }],
        "priority": [{ required: true, message: "Please enter Campaign Priority" }],
        "adUnits": [{ required: true, message: "Please select at least one AdUnitTargeting", trigger: "change", type: "array", min: 1 }],
        "landingUrl": [{ required: false, validator: urlValidator }],
        "country": [{ required: true, message: "Please select a Country" }],
        "roId": [{ required: true, message: "Please enter Campaign RO_ID" }],
        "dealType": [{ required: true, message: "Please select Deal type" }],
        "bid": [{ required: true, validator (rule, value, callback) { callback(numberValidator(value, 0, 100000000)); } }],
        "budget.type": [{ required: true, message: "Please select a Budget Type" }],
        "budget.averageDaily": [{ required: true, validator (rule, value, callback) { callback(numberValidator(value, 100)); } }],
        "budget.fixedType": [{ required: true, message: "Please select Fixed Budget Type" }],
        "budget.fixedDaily": [{ required: true, validator (rule, value, callback) { callback(numberValidator(value, 100)); } }],
        ...Object
          .values(molocoEnums.daysOfWeek)
          .reduce((l, day) => ({ ...l, [`budget.weeklyDaily.${day}`]: { required: true, validator (rule, value, callback) { callback(numberValidator(value, 100)); } } }), {}),
        "schedule": [{ required: true, validator: scheduleValidator }],
        "maxImpressionCap": [{ required: true, validator (rule, value, callback) { callback(numberValidator(value, 1, 1000000000000)); } }],
        "dailyMaxImpressionCap": [{ required: false, validator (rule, value, callback) { callback(dailyMaxImpressionCapValidator(value)); } }],
        "impressionInterval": [{ required: true, message: "Please select a Impression Interval" }]
      }
    };
  },
  computed: {
    ...mapState(["bpCurrencySymbol", "businessProfilesSearchEngineId", "molocoCampaignLauncherPublishDataId"]),
    scheduleOptions () {
      const today = Date.today();
      const { start } = this.campaign.schedule;

      return {
        start: {
          disabledDate (date) {
            return date && date < today;
          }
        },
        end: {
          disabledDate (date) {
            return date && (date < today || (start && date <= start));
          }
        }
      };
    },
    endDateBeginsFrom () {
      return new Date(this.campaign.schedule.end || this.campaign.schedule.start || Date.now());
    },
    isUpcomingCampaign () {
      return !this.campaign.schedule.start || new Date(this.campaign.schedule.start) >= Date.today();
    },
    adUnitOptionGroups () {
      return molocoEnums.adUnitTargetingTypes.reduce((groups, type) => {
        groups[type] = Object
          .keys(molocoEnums.adUnitTargeting)
          .filter(key => key.startsWith(type.slice(0, 3)))
          .reduce((obj, key) => {
            obj[key.split('_').join(' ')] = molocoEnums.adUnitTargeting[key];

            return obj;
          }, {});

        return groups;
      }, {});
    },
    dealTypes () {
      if (!this.campaign.objective) return [];

      return molocoEnums.dealTypes[this.campaign.objective];
    },
    isAppInstallsObjective () {
      return this.campaign.objective == molocoEnums.objectives['App Installs'];
    },
    isBrandAwarenessObjective () {
      return this.campaign.objective == molocoEnums.objectives['Brand Awareness'];
    },
    isLeadsObjective () {
      return this.campaign.objective == molocoEnums.objectives["Leads"]
    },
    isBrandAppsDisabled () {
      // disabled for now
      return true;
    },
    isMMPTrackingLinksDisabled () {
      // disabled for now
      return true;
    },
    brandAppsPlaceholder () {
      return "Select a Brand App";
    },
    mmpTrackingLinksPlaceholder () {
      return "Select a MMP tracking link";
    },
    molocoCampaignBid () {
      if (!this.molocoCampaign) return 0;

      return (this.molocoEnums.campaignGoalTypes[this.molocoCampaign.goal.type] == this.molocoEnums.campaignGoalTypes.FIXED_CPM_GENERIC ? this.molocoCampaign.goal.optimize_fixed_cpm_pacing.target_cpm.amount_micros : this.molocoCampaign.avod_support.bid_flow_policy.predetermined_fixed_bid_cpm.amount_micros) / 1000000;
    },
    isUpdated () {
      const { dxCampaign, molocoCampaign, molocoCampaignBid, dealTypes, areNotEqual, getKeyByValue } = this;
      const isValidCampaign = dxCampaign && molocoCampaign;

      return {
        "bid": isValidCampaign && !dxCampaign.editedSummary.includes("Bid") && areNotEqual(molocoCampaignBid, dxCampaign.bid),
        "status": isValidCampaign && !dxCampaign.editedSummary.includes("Status") && areNotEqual(getKeyByValue(molocoEnums.CampaignEnablingState, molocoCampaign.enabling_state), getKeyByValue(molocoEnums.Campaignstatus, dxCampaign.status)),
        "landingUrl": isValidCampaign && !dxCampaign.editedSummary.includes("LandingUrl") && areNotEqual(molocoCampaign.landing_url, dxCampaign.landingUrl),
        "name": isValidCampaign && !dxCampaign.editedSummary.includes("Name") && areNotEqual(molocoCampaign.title, dxCampaign.name),
        "description": isValidCampaign && !dxCampaign.editedSummary.includes("Description") && areNotEqual(molocoCampaign.description, dxCampaign.description),
        "dealType": isValidCampaign && !dxCampaign.editedSummary.includes("DealType") && areNotEqual(molocoCampaign.jio_support.deal_type, getKeyByValue(dealTypes, dxCampaign.dealType)),
        "priority": isValidCampaign && !dxCampaign.editedSummary.includes("Priority") && areNotEqual(molocoCampaign.jio_support.priority, getKeyByValue(molocoEnums.priorityList, dxCampaign.priority)),
        "adUnits": isValidCampaign && !dxCampaign.editedSummary.includes("AdUnitTargeting") && molocoCampaign.ad_units instanceof Array && dxCampaign.adUnits instanceof Array && areNotEqual(molocoCampaign.ad_units, dxCampaign.adUnits),
        "schedule": isValidCampaign && !dxCampaign.editedSummary.includes("Schedule") && molocoCampaign.schedule && dxCampaign.schedule && (areNotEqual(new Date(molocoCampaign.schedule.start), new Date(dxCampaign.schedule.start)) || areNotEqual(new Date(molocoCampaign.schedule.end || null), new Date(dxCampaign.schedule.end || null))),
        "jioId": isValidCampaign && !dxCampaign.editedSummary.includes("JioCampaignId") && areNotEqual(molocoCampaign.jio_support.jio_campaign_id, dxCampaign.jioId),
        "roId": isValidCampaign && !dxCampaign.editedSummary.includes("RoId") && areNotEqual(molocoCampaign.custom_key_values.jio_ro_id, dxCampaign.roId),
        "maxImpressionCap": isValidCampaign && !dxCampaign.editedSummary.includes("MaxImpressionCap") && areNotEqual(molocoCampaign.jio_support.campaign_max_imp_cap.max_imp_cap_count, dxCampaign.maxImpressionCap),
        "dailyMaxImpressionCap": isValidCampaign && !dxCampaign.editedSummary.includes("DailyMaxImpressionCap") && areNotEqual(molocoCampaign.jio_support.campaign_daily_imp_cap, dxCampaign.dailyMaxImpressionCap),
        "impressionInterval": isValidCampaign && !dxCampaign.editedSummary.includes("ImpressionInterval") && areNotEqual(JSON.stringify(molocoCampaign.jio_support.capper.imp_interval), dxCampaign.impressionInterval)
      };
    },
    isEdited () {
      const { campaign, dxCampaign, areNotEqual } = this;
      const isValidCampaign = campaign && dxCampaign;

      return {
        "bid": isValidCampaign && areNotEqual(campaign.bid, dxCampaign.bid),
        "status": isValidCampaign && areNotEqual(campaign.status, dxCampaign.status),
        "landingUrl": isValidCampaign && areNotEqual(campaign.landingUrl, dxCampaign.landingUrl),
        "name": isValidCampaign && areNotEqual(campaign.name, dxCampaign.name),
        "description": isValidCampaign && areNotEqual(campaign.description, dxCampaign.description),
        "dealType": isValidCampaign && areNotEqual(campaign.dealType, dxCampaign.dealType),
        "priority": isValidCampaign && areNotEqual(campaign.priority, dxCampaign.priority),
        "adUnits": isValidCampaign && campaign.adUnits instanceof Array && dxCampaign.adUnits instanceof Array && areNotEqual(campaign.adUnits, dxCampaign.adUnits),
        "schedule": isValidCampaign && campaign.schedule && dxCampaign.schedule && (areNotEqual(new Date(campaign.schedule.start), new Date(dxCampaign.schedule.start)) || areNotEqual(new Date(campaign.schedule.end || null), new Date(dxCampaign.schedule.end || null))),
        "jioId": isValidCampaign && areNotEqual(campaign.jioId, dxCampaign.jioId),
        "roId": isValidCampaign && areNotEqual(campaign.roId, dxCampaign.roId),
        "maxImpressionCap": isValidCampaign && areNotEqual(campaign.maxImpressionCap, dxCampaign.maxImpressionCap),
        "dailyMaxImpressionCap": isValidCampaign && areNotEqual(campaign.dailyMaxImpressionCap, dxCampaign.dailyMaxImpressionCap),
        "impressionInterval": isValidCampaign && areNotEqual(campaign.impressionInterval, dxCampaign.impressionInterval)
      };
    }
  },
  watch: {
    activeCampaignId (value) {
      this.campaign.id = value;
    },
    async "campaign.id" (value) {
      if (!value) return;

      try {
        const { budgetTypes, fixedBudgetTypes, Campaignstatus } = molocoEnums;
        const campaign = this.campaigns.find(e => e.id == value);

        if (!campaign) throw new String("Campaign not found");

        this.loading = true;
        this.liveUpdates = [];
        this.dxCampaign = null;
        this.molocoCampaign = null;
        this.campaign = {
          id: campaign.id,
          name: campaign.name,
          description: campaign.description || null,
          status: Campaignstatus[campaign.status],
          objective: campaign.objective,
          lineItemId: campaign.lineItemId,
          targetId: campaign.targetId,
          lineItemFractionId: campaign.lineItemFractionId,
          app: null,
          mmpTrackingLink: null,
          priority: campaign.priority,
          adUnits: campaign.adUnitTargeting,
          landingUrl: campaign.landingUrl || null,
          country: campaign.country,
          jioId: campaign.jioId || null,
          roId: campaign.roId,
          conversionSettings: null,
          dealType: campaign.dealType,
          bid: campaign.bid,
          cpiCeiling: campaign.cpiCeiling,
          budget: {
            type: budgetTypes[campaign.budgetType],
            averageDaily: budgetTypes[campaign.budgetType] == budgetTypes["Average Daily Budget"] ? campaign.budget : null,
            fixedType: campaign.weeklyDailyBudget == "{}" ? fixedBudgetTypes.Daily : fixedBudgetTypes.DayOfWeek,
            fixedDaily: budgetTypes[campaign.budgetType] == budgetTypes["Fixed Budget"] ? campaign.budget : null,
            weeklyDaily: JSON.parse(campaign.weeklyDailyBudget)
          },
          schedule: {
            start: campaign.startDate,
            end: campaign.endDate,
            noEnd: !campaign.endDate
          },
          maxImpressionCap: campaign.maxImpressionCap,
          dailyMaxImpressionCap: campaign.dailyMaxImpressionCap || null,
          impressionInterval: campaign.impressionInterval,
          editedSummary: campaign.editedSummary
        };

        this.dxCampaign = JSON.parse(JSON.stringify(this.campaign));

        if (campaign.searchEngineCampaignId) {
          const response = await APIService.getMolocoCampaignData(this.businessProfilesSearchEngineId, campaign.id);

          this.molocoCampaign = response.campaign;
          this.molocoCampaign.schedule.start = new Date(this.molocoCampaign.schedule.start);
          this.molocoCampaign.schedule.end = this.molocoCampaign.schedule.end ? new Date(this.molocoCampaign.schedule.end) : null;
        }
      } catch (error) {
        if (error instanceof String) {
          console.error(error.toString());
          this.showMessage("error", error);
        } else {
          console.error(error);
          if (!error.response || error.response.status != 404) {
            this.showMessage("error", "Something went wrong!");
          }
        }
      } finally {
        this.loading = false;
      }
    },
    "campaign.schedule.noEnd" (value) {
      this.campaign.schedule.end = value ? null : this.dxCampaign.schedule.end && new Date(this.dxCampaign.schedule.end);
    }
  },
  methods: {
    showMessage (type, message) {
      this.$Message.destroy();
      this.$Message[type](message);
    },
    areNotEqual (value1, value2) {
      if (value1 instanceof Array && value2 instanceof Array) {
        return [...value1].sort().join() != [...value2].sort().join();
      } else if (value1 instanceof Date && value2 instanceof Date) {
        return value1.getTime() != value2.getTime();
      } else {
        return (value1 || null) != (value2 || null);
      }
    },
    getKeyByValue (object, value) {
      return Object.keys(object).find(key => object[key] === value);
    },
    getReadableDate (dateTimeStamp) {
      if (!dateTimeStamp) return "N/A";

      return new Date(dateTimeStamp).toDateString();
    },
    getImpressionIntervalValue (impressionInterval) {
      if (!impressionInterval || (typeof (impressionInterval) != typeof ("") && typeof (impressionInterval) != typeof ({}))) {
        return "Invalid Impression Interval";
      }

      if (typeof (impressionInterval) == typeof ("")) {
        impressionInterval = JSON.parse(impressionInterval);
      }

      return `${impressionInterval.amount} ${impressionInterval.unit}`;
    },
    isValidURL (url) {
      const urlPattern = /^(https?|ftp):\/\/[^\s/$.?#].[^\s]*$/;
      var isValid = false;
      if (url) {
        isValid = urlPattern.test(url);
      }
      return isValid;
    },
    toggleAdUnits (adUnits) {
      const adUnitValues = Object.values(adUnits);

      if (adUnitValues.every(adUnit => this.campaign.adUnits.includes(adUnit))) {
        this.campaign.adUnits = this.campaign.adUnits.filter(adUnit => !adUnitValues.includes(adUnit));
      } else {
        this.campaign.adUnits = [...new Set([...this.campaign.adUnits, ...adUnitValues])];
      }
    },
    useLive (editableField, value) {
      var property = this.editableFieldPropertyMap[editableField];

      if (editableField == molocoEnums.campaignEditableFields.Schedule) {
        this.campaign[property] = {
          start: new Date(value.start),
          end: value.end ? new Date(value.end) : null,
          noEnd: !value.end
        };
        this.dxCampaign[property] = {
          start: new Date(value.start),
          end: value.end ? new Date(value.end) : null,
          noEnd: !value.end
        };
      } else if (editableField == molocoEnums.campaignEditableFields.AdUnitTargeting) {
        this.campaign[property] = JSON.parse(JSON.stringify(value));
        this.dxCampaign[property] = JSON.parse(JSON.stringify(value));
      } else {
        this.campaign[property] = value;
        this.dxCampaign[property] = value;
      }

      this.liveUpdates.push(editableField);
    },
    overwrite (editableField, value) {
      var property = this.editableFieldPropertyMap[editableField];

      this.dxCampaign[property] = value;
    },
    async save () {
      if (this.submit) return;

      const isValid = await this.$refs["campaign-edit-form"].validate();

      if (!isValid) return this.showMessage("error", "Please enter the required fields");

      try {
        this.submit = true;

        const { campaignEditableFields } = molocoEnums;
        const patchRequestModels = [];

        for (let key of Object.keys(this.editableFieldPropertyMap)) {
          const editableField = Number(key);
          const property = this.editableFieldPropertyMap[editableField];

          if (this.isEdited[property] || this.liveUpdates.includes(editableField)) {
            if (editableField === campaignEditableFields.Schedule) {
              this.campaign[property].start = new Date(this.campaign[property].start);
              this.campaign[property].end = this.campaign[property].end && new Date(this.campaign[property].end);

              patchRequestModels.push({
                property: editableField,
                value: JSON.stringify({
                  start: new Date(this.campaign[property].start.getTime() - this.campaign[property].start.getTimezoneOffset() * 60000),
                  end: this.campaign[property].noEnd ? null : new Date(this.campaign[property].end.getTime() - this.campaign[property].end.getTimezoneOffset() * 60000)
                }),
                isLiveUpdate: !this.isEdited[property] && this.liveUpdates.includes(editableField)
              });
            } else {
              patchRequestModels.push({
                property: editableField,
                value: editableField === campaignEditableFields.AdUnitTargeting ? JSON.stringify(this.campaign[property]) : this.campaign[property],
                isLiveUpdate: !this.isEdited[property] && this.liveUpdates.includes(editableField)
              });
            }
          }
        }

        const patchRequestEdits = patchRequestModels.filter(e => !e.isLiveUpdate).map(e => this.getKeyByValue(campaignEditableFields, e.property));
        const data = {
          lineItemId: this.campaign.lineItemId,
          targetId: this.campaign.targetId,
          lineItemFractionId: this.campaign.lineItemFractionId,
          patchRequestModels
        };

        if (patchRequestModels.length != 0) {
          await APIService.updateMolocoCampaignData(this.molocoCampaignLauncherPublishDataId, data);

          this.campaign.editedSummary = [...new Set([...this.campaign.editedSummary.split(","), ...patchRequestEdits])].filter(e => e).join(",");
          this.dxCampaign = JSON.parse(JSON.stringify(this.campaign));
          this.showMessage("success", "Campaign updated successfully");
          EventBus.$emit("update-campaign", this.campaign);
          this.close();
        } else {
          this.showMessage("info", "Nothing to update");
        }
      } catch (error) {
        console.error(error);
        this.showMessage("error", "Failed to update campaign");
      } finally {
        this.submit = false;
      }
    },
    async reset () {
      this.dxCampaign = null;
      this.molocoCampaign = null;
      await this.$refs["campaign-edit-form"].resetFields();
    },
    close () {
      this.$emit("close");
    }
  }
};
</script>

<style scoped>
.spin-icon {
  animation: spin-icon 1s linear infinite;
  color: var(--dx-gray);
  font-size: 24px;
}

.campaign-edit-modal ::v-deep .ivu-modal-body {
  max-height: 80vh;
  overflow: auto;
}

.campaign-edit-modal ::v-deep .ivu-modal-body::-webkit-scrollbar {
  width: 0.4rem;
  border-radius: 2.4rem;
  background-color: #f1f1f1;
}

.campaign-edit-modal ::v-deep .ivu-modal-body::-webkit-scrollbar-track {
  background: transparent;
}

.campaign-edit-modal ::v-deep .ivu-modal-body::-webkit-scrollbar-thumb {
  border-radius: 2.4rem;
  background-color: #c1c1c1;
}

::v-deep .ivu-alert-warning {
  padding: 8px 48px 8px 76px !important;
  margin-bottom: 0 !important;
}

::v-deep .ivu-alert-desc {
  text-align: start;
  overflow-wrap: break-word;
}

.alert-subheading {
  font-weight: bold;
  color: #E6A700 !important;
}

.outdated-actions {
  margin-top: 1rem;
  display: flex;
  align-items: center;
  gap: 1rem;
}

.adunit-option-group {
  cursor: pointer !important;
  user-select: none;
}

@keyframes spin-icon {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}
</style>
