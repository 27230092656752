<template>
  <div>
    <i-modal
      width="70%"
      v-model="modalSettings.openModal"
      :closable="false"
      :mask-closable="false"
      class-name="vertical-center-modal">

      <h4
        slot="header"
        class="header-class">
        <label class="header-label" v-if="incomingBillLog.id == 0">{{ modalSettings.header.createMode }}</label>
        <label class="header-label" v-else>{{ modalSettings.header.editMode }}</label>
      </h4>

      <div class="modal-body">
        <div v-show="loaders.isRetrievingData" class="modal-loader">
          <i class="fa fa-spinner fa-spin"></i>
        </div>

        <div v-show="!loaders.isRetrievingData">
          <i-row class="vertical-center" :gutter="10">
            <i-col span="3" class="col-heading"> AgencyUnit <span class="color-error">*</span></i-col>
            <i-col span="21">
              <i-select
                :class="{'error-field': !errorList.isValidAgencyUnit}"
                v-model="incomingBillLog.agencyUnitId"
                @on-change="(val) => handleSelectChange('agencyUnitId', val)"
                filterable>
                <i-option
                  v-for="agencyUnit in agencyUnitsList"
                  :value="agencyUnit.id"
                  :key="agencyUnit.id"
                  >{{ agencyUnit.name }}</i-option>
              </i-select>
            </i-col>
          </i-row>
          <br />
          <i-row class="vertical-center" :gutter="10">
            <i-col span="3" class="col-heading"> Client <span class="color-error">*</span></i-col>
            <i-col span="21">
              <i-select
                :class="{'error-field': !errorList.isValidClient}"
                v-model="incomingBillLog.clientId"
                @on-change="(val) => handleSelectChange('clientId', val)"
                filterable>
                  <i-option
                    v-for="client in clientsList"
                    :value="client.id"
                    :key="client.id"
                    >{{ client.name }}</i-option>
              </i-select>
            </i-col>
          </i-row>
          <br />
          <i-row class="vertical-center" :gutter="10">
            <i-col span="3" class="col-heading"> Supplier
            </i-col>
            <i-col span="21">
              <i-select
                v-model="incomingBillLog.supplierId"
                filterable>
                  <i-option
                    v-for="supplier in suppliersList"
                    :value="supplier.id"
                    :key="supplier.id">{{ supplier.name }}</i-option>
              </i-select>
            </i-col>
          </i-row>
          <br />
          <i-row class="vertical-center" :gutter="10">
            <i-col span="3" class="col-heading">RO Number</i-col>
            <i-col>
              <i-select
                v-model="selectedReserveOrderId"
                placeholder="Enter RO Number"
                filterable>
                  <i-option
                    v-for="reserveOrder in reserveOrdersList"
                    :value="reserveOrder.id"
                    :key="reserveOrder.id">{{ reserveOrder.number }}</i-option>
              </i-select>
            </i-col>
            <i-col>
              <Button
                class="btn btn-success addButton"
                @click="selectedReserveOrderId != undefined && selectedReserveOrderId != '' ? insertROTableData() : ''">
                Add
              </Button>
            </i-col>
          </i-row>
          <br />
          <i-row class="vertical-center" :gutter="10">
            <i-col span="3" class="col-heading"> RO Details </i-col>
            <i-col span="21">
              <i-table
                ref="tbl-ro-details"
                :columns="reserveOrderDetails.columns"
                :data="reserveOrderDetails.data"
                border
                disabled-hover>

                  <template slot-scope="{ row }" slot="billShare">
                    <div>
                      {{ row.billShare }}%
                    </div>
                  </template>

                  <template slot-scope="{ row }" slot="actions">
                    <div>
                      <i
                        class="fa fa-times"
                        @click="deleteReserveOrderDetail(row.id)"></i>
                    </div>
                  </template>

              </i-table>
            </i-col>
          </i-row>
          <br>
          <i-row class="vertical-center" :gutter="10">
            <i-col span="3" class="col-heading"> Line Item Information </i-col>
            <i-col span="21">
              <i-table
                ref="tbl-lineItems-info"
                class="tbl-lineItems-info"
                :columns="lineItemsInformation.columns"
                :data="lineItemsInformation.data"
                border
                disabled-hover>

                  <template slot-scope="{ row }" slot="billed">
                      <i-input
                        class="billedInput"
                        size= "small"
                        type="number"
                        v-model="row.billed"
                        @on-change="(e) => handleBilledValueChange(e.target.value, row.id)"
                        @on-blur="refreshTablesData">
                      </i-input>
                  </template>

              </i-table>
            </i-col>
          </i-row>
          <br/>
          <i-row class="vertical-center" :gutter="10">
            <i-col span="3" class="col-heading">
              Total Cost
            </i-col>
            <i-col span="5">
              <i-input
                v-model="totalCost"
                type="number"
                @on-blur="refreshTablesData"></i-input>
            </i-col>
            <i-col span="3" class="col-heading">
              Adjustment
            </i-col>
            <i-col span="5">
              <i-input
                :value="adjustment"
                disabled> </i-input>
            </i-col>
            <i-col span="3" class="col-heading">
              Line Item Total
            </i-col>
            <i-col span="5">
                <i-input
                :value="lineItemTotal"
                disabled> </i-input>
            </i-col>
          </i-row>
          <br>
          <i-row class="vertical-center" :gutter="10" style="margin-top: -25px">
            <i-col span="3" class="col-heading"> Tax Information </i-col>
            <i-col span="21">
              <br />
              <i-table
                ref="tbl-tax-information"
                class="tbl-tax-information"
                :columns="taxInformation.columns"
                :data="taxInformation.data"
                border
                disabled-hover>

                <template slot-scope="{ row, index }" slot="taxType">
                  <i-input
                    size="small"
                    :data-tax-error="row.taxTypeEdited && row.taxType == 0"
                    v-model="row.taxType"
                    @on-change="handleTaxTypeChange(row, index)"
                    placeholder="Tax Type">
                  </i-input>
                </template>

                <template slot-scope="{ row, index }" slot="taxPercentage">
                  <i-input
                    :data-tax-error="row.taxPercentageEdited && isNaN(parseFloat(row.taxPercentage))"
                    size="small"
                    type="number"
                    v-model="row.taxPercentage"
                    @on-change="handleTaxPercentageChange(row, index)">
                  </i-input>
                </template>

                <template slot-scope="{ index }" slot="remove">
                  <div>
                    <i
                      class="fa fa-times"
                      @click="deleteTaxRow(index)"></i>
                  </div>
                </template>

              </i-table>
            </i-col>
          </i-row>
          <i-row :gutter="10">
            <i-col offset="3" class-name="top-padding-10">
              <Button
                class="btn btn-success addButton"
                @click="addTaxInformation">
                Add Tax Information
              </Button>
            </i-col>
          </i-row>
          <br>
          <i-row class="vertical-center" :gutter="10">
            <i-col span="3" class="col-heading"> Total Invoice Value </i-col>
            <i-col span="7">
                <i-input
                  type="number"
                  step="any"
                  :value="incomingBillLog.totalInvoiceValue"
                  disabled>
                </i-input>
            </i-col>
          </i-row>
          <br>
          <i-row class="vertical-center" :gutter="10">
            <i-col span="3" class="col-heading"> Verification </i-col>
            <i-col span="21">
              <i-radiogroup
                style="padding-top: 5px"
                v-model="incomingBillLog.verificationId">
                  <i-radio
                    :label='flag.id'
                    v-for="flag in verificationFlags"
                    :key="flag.id">{{flag.name}}</i-radio>
              </i-radiogroup>
            </i-col>
          </i-row>
          <br />
          <i-row class="vertical-center" :gutter="10">
            <i-col span="3" class="col-heading"> Invoice Number <span class="color-error">*</span></i-col>
            <i-col span="5">
              <i-input
                :class="{'error-field-input': !errorList.isValidInvoiceNumber}"
                v-model="incomingBillLog.invoiceNumber"></i-input>
            </i-col>
          </i-row>
          <br />
          <i-row class="vertical-center" :gutter="10">
            <i-col span="3" class="col-heading"> Invoice period </i-col>
            <i-col span="21">
              <i-datepicker
                type="daterange"
                format="MMM d, yyyy"
                v-model="incomingBillLog.invoicePeriod"
                :clearable="false"
                :editable="false">
              </i-datepicker>
            </i-col>
          </i-row>
          <br />
          <i-row class="vertical-center" :gutter="10">
            <i-col span="3" class="col-heading"> Generation Date </i-col>
            <i-col span="21">
              <i-datepicker
                format="MMM d, yyyy"
                v-model="incomingBillLog.generationDate"
                :clearable="false"
                :editable="false"
                :options="generationDateOptions">
              </i-datepicker>
            </i-col>
          </i-row>
          <br />
          <i-row class="vertical-center" :gutter="10">
            <i-col span="3" class="col-heading"> Received Date </i-col>
            <i-col span="21">
              <i-datepicker
                format="MMM d, yyyy"
                v-model="incomingBillLog.receivedDate"
                :clearable="false"
                :editable="false"
                :options="receivedDateOptions">
              </i-datepicker>
            </i-col>
          </i-row>
          <br />
          <i-row class="vertical-center" :gutter="10" style="align-items: baseline;">
            <i-col span="3" class="col-heading">
                Supportings
            </i-col>
            <i-col span="10">
              <div>
                <i-upload
                  multiple
                  class="supportingsUpload"
                  type="drag"
                  action=""
                  :before-upload="uploadSupportingFile"
                  accept=".jpg,.jpeg,.png,.pdf"
                  :format="fileFormats.supportingsFormat"
                  name="supportings">
                  <div style="padding: 10px 0">
                    Drag & Drop or <span style="color: green;"><u> Click to Browse</u></span>
                  </div>
                </i-upload>
              </div>
              <div
                v-if="currentFiles.length != 0"
                style="display: inline-flex ; flex-direction: column">
                <div
                  v-for="(supportingFile, index) in currentFiles"
                  :key="index + 1"
                  class="uploadedFileName uploadedSupportingsFileName">
                  {{supportingFile.name}}
                <span>
                  <i
                  class="fa fa-times"
                  @click="deleteSupportingFile(index)"></i>
                </span>
                </div>
                <br>
              </div>
            </i-col>
          </i-row>
          <i-row class="vertical-center" :gutter="10" >
            <i-col span="3" class="col-heading">
              Notes
            </i-col>
            <i-col span="10">
              <i-input v-model="incomingBillLog.notes"> </i-input>
            </i-col>
          </i-row>
        </div>
      </div>

      <div slot="footer" class="footer">
        <Button
          class="btn btn-success btn-footer"
          @click="handleOk"
          :disabled="loaders.isRetrievingData">
          Save
        </Button>
        <Button
          class="btn btn-white btn-footer"
          @click="handleCancel">
          Cancel
        </Button>
      </div>
    </i-modal>
    <i-modal
        title="Change Selection?"
        :closable="false"
        :mask-closable="false"
        v-model="showConfirmModal"
        class="ro-reset-popup"
        >
        <p>Changing the selection will remove the selected reserve orders and line items information! Do you want to continue?</p>

        <div slot="footer" class="footer">
          <Button
            class="btn btn-white btn-footer"
            @click="keepSameSelection">
            No
          </Button>
          <Button
            class="btn btn-success btn-footer"
            @click="clearTablesData">
            Yes
          </Button>
        </div>
      </i-modal>
  </div>
</template>
<script>
import {
  locale,
  Modal,
  Row,
  Col,
  Select,
  Option,
  Input,
  RadioGroup,
  Radio,
  Table,
  DatePicker,
  Upload
} from "iview";
import lang from "iview/dist/locale/en-US";
import { ApiService } from ".././Services/ApiService.js";
locale(lang);
export default {
  components: {
    "i-modal": Modal,
    "i-row": Row,
    "i-col": Col,
    "i-select": Select,
    "i-option": Option,
    "i-input": Input,
    "i-radiogroup": RadioGroup,
    "i-radio": Radio,
    "i-table": Table,
    "i-datepicker": DatePicker,
    "i-upload": Upload
  },
  data () {
    return {
      apiService: new ApiService(),
      showConfirmModal: false,
      loaders: {
        isRetrievingData: false
      },
      errorList: {
        isValidClient: true,
        isValidAgencyUnit: true,
        isValidInvoiceNumber: true
      },
      modalSettings: {
        openModal: false,
        header: {
          createMode: "Add Inward Bill",
          editMode: "Edit Inward Bill"
        }
      },
      incomingBillLog: {
        id: 0,
        supplierId: 0,
        businessProfileId: 0,
        agencyUnitId: 0,
        notes: '',
        totalInvoiceValue: 0,
        verificationId: 1,
        invoiceNumber: '',
        invoicePeriod: [moment().format("MMM DD, YYYY"), moment().format("MMM DD, YYYY")],
        generationDate: moment().format("MMM DD, YYYY"),
        receivedDate: moment().format("MMM DD, YYYY"),
        currencyId: 0,
        voucher: 1,
        clientId: 0
      },
      selectedDropdowns: {
        agencyUnitId: 0,
        clientId: 0
      },
      generationDateOptions: {
        disabledDate (date) {
          return date && date.valueOf() > Date.now();
        }
      },
      receivedDateOptions: {
        disabledDate (date) {
          return date && date.valueOf() > Date.now();
        }
      },
      suppliersList: [],
      clientsList: [],
      agencyUnitsList: [],
      reserveOrdersList: [],

      selectedReserveOrderId: 0,
      reserveOrderDetails: {
        columns: [
          {
            title: "RO Name",
            key: "roName",
            align: "center"
          },
          {
            title: "RO Number",
            key: "reserveOrderNumber",
            align: "center"
          },
          {
            title: "RO Value",
            key: "reserveOrderValue",
            align: "center"
          },
          {
            title: "Bill Share",
            key: "billShare",
            slot: "billShare",
            align: "center"
          },
          {
            title: "Remove",
            key: "actions",
            align: "center",
            slot: "actions"
          }
        ],
        data: []
      },
      costBreakdown: Object,
      lineItemsInformation: {
        columns: [
          {
            title: "Campaign",
            key: "mediaPlanName",
            align: "center",
            minWidth: 200
          },
          {
            title: "Line item id",
            key: "id",
            align: "center",
            minWidth: 80
          },
          {
            title: "Type",
            key: "type",
            align: "center",
            minWidth: 100
          },
          {
            title: "Targeting",
            key: "targeting",
            align: "center",
            minWidth: 100
          },
          {
            title: "Buy Type",
            key: "buyType",
            align: "center",
            minWidth: 100
          },
          {
            title: "Cost (INR)",
            key: "cost",
            align: "center",
            children: [
              {
                title: "Ordered",
                key: "ordered",
                align: "center",
                minWidth: 100
              },
              {
                title: "Billed",
                key: "billed",
                align: "center",
                slot: "billed",
                minWidth: 120
              }
            ]
          }
        ],
        data: []
      },
      taxInformation: {
        columns: [
          {
            title: "Tax Type",
            key: "taxType",
            align: "center",
            slot: "taxType"
          },
          {
            title: "Tax Percentage",
            key: "taxPercentage",
            align: "center",
            slot: "taxPercentage"
          },
          {
            title: "Tax Value",
            key: "taxValue",
            align: "center"
          },
          {
            title: "Remove",
            key: "remove",
            align: "center",
            slot: "remove",
            width: 150
          }
        ],
        data: []
      },
      fileFormats: {
        invoiceFormat: ['.pdf'],
        supportingsFormat: ['.jpg', '.jpeg', '.png', '.pdf']
      },
      totalCost: 0,
      adjustment: 0,
      lineItemTotal: 0,
      verificationFlags: [
        {
          id: 1,
          name: 'Confirm'
        },
        {
          id: 2,
          name: 'Yellow Flag'
        },
        {
          id: 3,
          name: 'Red Flag'
        },
        {
          id: 4,
          name: 'Reject'
        }
      ],
      allFiles: [],
      currentFiles: []
    };
  },
  methods: {
    openModal (incomingBillLogId) {
      this.modalSettings.openModal = true;
      if (incomingBillLogId != undefined) {
        this.incomingBillLog.id = incomingBillLogId;
      }
      this.initializeModal();
    },
    initializeModal () {
      var self = this;
      this.loaders.isRetrievingData = true;
      var promises = [];
      promises.push(this.apiService.getSuppliers());
      promises.push(this.apiService.getClients());
      promises.push(this.apiService.getAgencyUnits());

      this.selectedDropdowns = {
        agencyUnitId: 0,
        clientId: 0
      }

      Promise.all(promises)
        .then((results) => {
          this.clientsList = results[1].data.data.clients;
          this.suppliersList = results[0].data.suppliers.reverse();
          this.agencyUnitsList = results[2].data.data.agencyUnits;

          if (this.incomingBillLog.id == 0) {
            this.loaders.isRetrievingData = false;
            return;
          }

          self.apiService.getIncomingBillLogById(self.incomingBillLog.id)
            .then(async (response) => {
              if (response.data.data.length == 0) {
                this.loaders.isRetrievingData = false;
                return;
              }
              let ibLog = response.data.data;

              self.incomingBillLog.agencyUnitId = ibLog.agencyUnitId;
              self.incomingBillLog.supplierId = ibLog.supplierId;
              self.incomingBillLog.clientId = ibLog.clientId;

              this.selectedDropdowns = {
                agencyUnitId: ibLog.agencyUnitId,
                clientId: ibLog.clientId
              }

              self.totalCost = ibLog.baseCost;
              self.incomingBillLog.verificationId = ibLog.verification;
              self.incomingBillLog.invoiceNumber = ibLog.invoiceNumber;
              self.incomingBillLog.invoicePeriod = [
                moment(ibLog.startDate).format("MMM DD, YYYY"),
                moment(ibLog.endDate).format("MMM DD, YYYY")
              ];
              self.incomingBillLog.generationDate = moment(ibLog.generationDate).format("MMM DD, YYYY");
              self.incomingBillLog.receivedDate = moment(ibLog.receivedDate).format("MMM DD, YYYY");
              self.incomingBillLog.notes = ibLog.notes;
              await this.loadReserveOrders();
              await this.prefillTablesData(ibLog);
              this.refreshTablesData();
              await this.setSupportingFiles(ibLog.id);
              this.loaders.isRetrievingData = false;
            });
        })
        .catch(() => {
          this.loaders.isRetrievingData = false;
          this.handleCancel();
          alert('Failed to load pop up');
        });
    },
    handleCancel () {
      this.clearModalData();
      this.closeModal("cancel", "");
    },
    handleOk () {
      if (!this.isValidData()) {
        return;
      };
      var consolidatedData = this.consolidateData();
      this.clearModalData();
      this.closeModal("save", consolidatedData);
    },
    closeModal (type, consolidatedData) {
      this.modalSettings.openModal = false;
      this.$emit(type, consolidatedData);
    },
    clearModalData () {
      var self = this;
      Object.keys(this.loaders).forEach(key => {
        self.loaders[key] = false;
      });
      Object.keys(this.errorList).forEach(key => {
        self.errorList[key] = true;
      });
      this.incomingBillLog = {
        id: 0,
        supplierId: 0,
        businessProfileId: 0,
        agencyUnitId: 0,
        notes: '',
        totalInvoiceValue: 0,
        verificationId: 1,
        invoiceNumber: '',
        invoicePeriod: [moment().format("MMM DD, YYYY"), moment().format("MMM DD, YYYY")],
        generationDate: moment().format("MMM DD, YYYY"),
        receivedDate: moment().format("MMM DD, YYYY"),
        currencyId: 0,
        voucher: 1,
        clientId: 0
      }
      this.selectedDropdowns = {
        agencyUnitId: 0,
        clientId: 0
      }
      this.suppliersList = [];
      this.agencyUnitsList = [];
      this.clientsList = [];
      this.reserveOrdersList = [];

      this.selectedReserveOrderId = 0;
      this.reserveOrderDetails.data = [];
      this.costBreakdown = Object;
      this.lineItemsInformation.data = [];
      this.taxInformation.data = [];
      this.totalCost = 0;
      this.adjustment = 0;
      this.lineItemTotal = 0;
      this.allFiles = [];
      this.currentFiles = [];
      this.showConfirmModal = false;
    },
    handleSelectChange (property, value) {
      let { clientId, agencyUnitId } = this.selectedDropdowns;
      if (clientId !== 0 && agencyUnitId !== 0 && this.reserveOrderDetails.data.length !== 0) {
        this.showConfirmModal = true;
      } else {
        this.selectedDropdowns[property] = value;
        this.loadReserveOrders();
      }
    },
    clearTablesData () {
      this.selectedDropdowns = {
        agencyUnitId: this.incomingBillLog.agencyUnitId,
        clientId: this.incomingBillLog.clientId
      };
      this.lineItemsInformation.data = [];
      this.reserveOrderDetails.data = [];
      this.totalCost = 0;
      this.adjustment = 0;
      this.lineItemTotal = 0;

      this.refreshTablesData();
      this.loadReserveOrders();
      this.showConfirmModal = false;
    },
    keepSameSelection () {
      this.incomingBillLog.agencyUnitId = this.selectedDropdowns.agencyUnitId;
      this.incomingBillLog.clientId = this.selectedDropdowns.clientId;
      this.showConfirmModal = false;
    },
    async prefillTablesData (ibLog) {
      if (ibLog.reserveOrderIds && ibLog.reserveOrderIds != '') {
        let reserveOrderIds = ibLog.reserveOrderIds.split(",").map(id => parseInt(id))
        for (let id of reserveOrderIds) {
          this.selectedReserveOrderId = id;
          await this.insertROTableData();
        }
      }

      let costBreakdown = JSON.parse(ibLog.costBreakdown);
      let lineItemsInformation = costBreakdown.lineItemsInformation;

      this.lineItemsInformation.data.forEach(lineItem => {
        lineItem.billed = lineItemsInformation.find(x => x.id == lineItem.id).billedValue;
      })

      let taxInformation = costBreakdown.taxInformation.map(tax => ({
        taxType: tax.name,
        taxValue: tax.value,
        taxPercentage: tax.rate,
        taxPercentageEdited: false,
        taxTypeEdited: false
      }));
      this.taxInformation.data = taxInformation;

      this.selectedReserveOrderId = 0;
    },
    async loadReserveOrders () {
      let { clientId, agencyUnitId } = this.incomingBillLog;

      if (clientId === 0 || clientId === undefined ||
          agencyUnitId === 0 || agencyUnitId === undefined) {
        return;
      }

      try {
        let response = await this.apiService.getReserveOrders({ clientIds: clientId, agencyUnitIds: agencyUnitId });
        this.reserveOrdersList = response.data.data.reserveOrderResponses;
      } catch {
        alert("Error occurred in fetching the reserve orders");
      }
    },
    async insertROTableData () {
      if (this.reserveOrderDetails.data.find(x => x.id === this.selectedReserveOrderId)) {
        return;
      }

      let reserveOrder = this.reserveOrdersList.find(x => x.id == this.selectedReserveOrderId)
      this.reserveOrderDetails.data.unshift({
        roName: reserveOrder.name,
        reserveOrderNumber: reserveOrder.number,
        reserveOrderValue: reserveOrder.allocatedBudget,
        billShare: 0,
        id: reserveOrder.id
      })

      await this.insertLineItemsTableData(reserveOrder);
    },
    addTaxInformation () {
      this.taxInformation.data.unshift({
        taxType: "",
        taxPercentage: 0,
        taxValue: 0,
        taxTypeEdited: false,
        taxPercentageEdited: false
      })
    },
    deleteTaxRow (index) {
      this.taxInformation.data.splice(index, 1);
      this.refreshTablesData();
    },
    handleTaxPercentageChange (row, index) {
      let taxRate = row.taxPercentage;
      if (taxRate == undefined || taxRate == '' || isNaN(parseFloat(taxRate))) {
        taxRate = 0
      }
      taxRate = parseFloat(taxRate)
      row.taxValue = taxRate * this.getTotalCost() / 100;

      this.taxInformation.data[index].taxPercentage = row.taxPercentage;
      this.taxInformation.data[index].taxPercentageEdited = true;
      this.taxInformation.data[index].taxValue = row.taxValue;

      this.calculateTotalInvoiceValue();
    },
    handleTaxTypeChange (row, index) {
      this.taxInformation.data[index].taxType = row.taxType;
      this.taxInformation.data[index].taxTypeEdited = true;
    },
    refreshTaxInformation () {
      let totalCost = this.getTotalCost();
      this.taxInformation.data.forEach(taxRow => {
        taxRow.taxValue = totalCost * taxRow.taxPercentage / 100;
      })
    },
    getTotalCost () {
      let totalCost = this.totalCost;
      if (totalCost == undefined || isNaN(parseFloat(totalCost))) {
        totalCost = 0.0;
      }
      return parseFloat(totalCost);
    },
    consolidateData () {
      this.refreshTablesData();

      var lineItems = [];
      this.lineItemsInformation.data.forEach(lineItem => {
        lineItems.push({
          id: lineItem.id,
          billedValue: lineItem.billed
        });
      });

      var data = {
        roDetailsData: this.reserveOrderDetails.data,
        taxInformationData: this.taxInformation.data,
        incomingBillLog: this.incomingBillLog,
        supportingFiles: this.currentFiles,
        allFiles: this.allFiles,
        lineItemsInformation: lineItems,
        totalCost: this.totalCost
      }
      data.lineItemsInformation.lineItemsInformation = lineItems;
      return data;
    },
    uploadSupportingFile (file) {
      this.currentFiles.push(file);
    },
    deleteSupportingFile: function (index) {
      this.currentFiles.splice(index, 1);
    },
    async insertLineItemsTableData (reserveOrder) {
      let { businessProfileId, id: reserveOrderId } = reserveOrder;

      if (businessProfileId == undefined || businessProfileId == 0) return;

      try {
        let response = await this.apiService
          .getLineItemInfoByReserveOrderId(businessProfileId, { ReserveOrderIds: reserveOrderId });

        let lineItems = response.data.data;
        if (lineItems == undefined || lineItems.length == 0) {
          return;
        }
        let lineItemTableData = []

        lineItems.forEach(lineItem => {
          let billed = 0;
          if (this.costBreakdown != undefined && this.costBreakdown.lineItemsInformation != undefined) {
            billed = self.costBreakdown.lineItemsInformation
              .filter(li => li.id == lineItem.lineItemId)
              .map(x => x.billedValue)[0];
          }

          lineItemTableData.push({
            id: lineItem.id,
            reserveOrderId,
            type: lineItem.lineItemType,
            targeting: lineItem.targeting,
            buyType: lineItem.buyType,
            ordered: lineItem.budget,
            billed: billed,
            mediaPlanName: lineItem.mediaPlanName
          })
        })

        this.lineItemsInformation.data.splice(0, 0, ...lineItemTableData)
        this.refreshTablesData();
      } catch {
        alert('Failed to fetch line item information for selected reserve order')
      }
    },
    deleteReserveOrderDetail (roId) {
      this.reserveOrderDetails.data = this.reserveOrderDetails.data.filter(x => x.id != roId);
      this.lineItemsInformation.data = this.lineItemsInformation.data.filter(x => x.reserveOrderId != roId);

      this.refreshTablesData();
    },
    refreshTablesData () {
      this.calculateLineItemTotal();
      this.calculateAdjustment();
      this.calculateBillShare();
      this.refreshTaxInformation();
      this.calculateTotalInvoiceValue();
    },
    handleBilledValueChange (value, rowId) {
      let billedValue = 0;
      if (value != undefined && !isNaN(parseFloat(value))) {
        billedValue = parseFloat(value);
      }

      this.lineItemsInformation.data
        .find(x => x.id == rowId)
        .billed = billedValue;
    },
    calculateLineItemTotal () {
      var lineItemTable = this.lineItemsInformation.data;
      var lineItemTotal = 0.0;
      lineItemTable.forEach(lineItem => {
        if (lineItem.billed == undefined || isNaN(parseFloat(lineItem.billed))) {
          lineItem.billed = 0.0;
        }
        lineItemTotal += parseFloat(lineItem.billed);
      });
      this.lineItemTotal = lineItemTotal;
    },
    calculateAdjustment () {
      let totalCost = this.getTotalCost();
      this.adjustment = totalCost - this.lineItemTotal;
    },
    calculateTotalInvoiceValue () {
      var totalTax = this.computeTotalTax();
      var totalCost = this.getTotalCost();
      this.incomingBillLog.totalInvoiceValue = totalTax + totalCost;
    },
    computeTotalTax () {
      var sum = 0.0;
      this.taxInformation.data.forEach(tax => {
        sum += parseFloat(tax.taxValue);
      });
      return sum;
    },
    calculateBillShare () {
      let totalCost = this.getTotalCost();

      this.reserveOrderDetails.data.forEach(reserveOrder => {
        let reserveOrderLineItems = this.lineItemsInformation.data.filter(ro => ro.reserveOrderId == reserveOrder.id);
        if (reserveOrderLineItems == undefined || reserveOrderLineItems.length == 0) {
          return;
        }
        var reserveOrderSum = 0.0;
        reserveOrderLineItems.forEach(lineItem => {
          if (lineItem.billed == undefined || isNaN(parseFloat(lineItem.billed))) {
            lineItem.billed = 0.0;
          }
          reserveOrderSum += parseFloat(lineItem.billed);
        });

        if (totalCost == 0) {
          reserveOrder.billShare = 0;
        } else {
          reserveOrder.billShare = parseFloat(reserveOrderSum / totalCost * 100).toFixed(2);
        }
      });
    },
    async setSupportingFiles (ibLogId) {
      let response = await this.apiService.getAttachmentsByIbLogId(ibLogId);
      let attachments = response.data.data;
      let files = attachments.map(x => {
        return {
          id: x.id,
          name: x.name
        };
      })
      this.currentFiles.push(...files);
      this.allFiles.push(...files);
    },
    isValidData () {
      this.errorList.isValidClient = !!(this.incomingBillLog.clientId != undefined && this.incomingBillLog.clientId != '');
      this.errorList.isValidAgencyUnit = !!(this.incomingBillLog.agencyUnitId != undefined && this.incomingBillLog.agencyUnitId != '');
      this.errorList.isValidInvoiceNumber = !!(this.incomingBillLog.invoiceNumber != undefined && this.incomingBillLog.invoiceNumber != '');

      let invalidTax = false;
      for (let tax of this.taxInformation.data) {
        if (tax.taxPercentage == 0) {
          tax.taxPercentageEdited = true;
          invalidTax = true;
        }
        if (tax.taxType == '') {
          tax.taxTypeEdited = true;
          invalidTax = true;
        }
      }

      var isValid = Object.values(this.errorList).find(isValid => isValid == false);
      if ((isValid != undefined && isValid != null && !isValid) || invalidTax) {
        return false;
      }

      return true;
    }
  }
};
</script>
<style scoped>
.btn-footer {
  margin-bottom: 0px;
}
.vertical-center {
  display: flex;
  align-items: center;
}
.col-heading {
  text-align: right;
}
.vertical-center-modal {
  display: flex !important;
  align-items: center !important;
  justify-content: center !important;
}
.header-class {
  margin: 0px;
}
.color-error {
  color: red;
}
.disable {
  background-color: gray !important;
}
.modal-loader {
  text-align: center;
  font-size: 35px;
}
.modal-body {
  max-height: 500px;
}
::v-deep .ivu-table-column-center {
  height: 30px;
}
::v-deep .ivu-modal-content {
  padding: 15px;
}
::v-deep .ivu-modal-header {
  padding: 0px;
  padding-bottom: 15px;
  border-bottom: 2px solid #ccc;
}
::v-deep .ivu-modal-footer {
  padding-bottom: 0px;
}
::v-deep .ivu-table-cell {
  padding: 5px;
}
::v-deep .ivu-modal {
  top: 40px;
}
::v-deep .tbl-lineItems-info .ivu-table-tip{
  overflow-x: auto;
}
.uploadedFileName{
  padding: 8px;
  display: inline-flex;
  gap: 15px;
  background-color: #F3F3F3;
  border-radius: 4px;
}
.uploadedSupportingsFileName{
  margin-top: 5px;
  justify-content: space-between;
}
.addButton{
  margin: 0;
}
::v-deep .ivu-checkbox-checked .ivu-checkbox-inner {
  border-color:  #51b848;
  background-color: #51b848;
}
::v-deep .ivu-checkbox-indeterminate .ivu-checkbox-inner{
  border-color:  #51b848;
  background-color: #51b848;
}
::v-deep .ivu-checkbox-wrapper{
  margin: 0;
}
::v-deep .ivu-radio-inner:after {
  background-color: #51b848;
}
::v-deep .ivu-radio-checked .ivu-radio-inner {
  border-color: #51b848;
}
.billedInput {
  padding: 2px;
  border-bottom: 1px solid #DDDEDF;
}
.billedInput:last-child {
  border-bottom: 0;
}
::v-deep .billedInput >  .ivu-input-small{
  height: 22px;
}
::v-deep .tbl-lineItems-info .ivu-table-cell{
  padding: 0;
}
.error-field {
  border: 2px solid red;
}
.error-field ::v-deep .ivu-select-selection {
  border: 0px;
}
.error-field-input {
  border: 1px solid red;
}
.header-label {
  margin-bottom: 0px;
}
.top-padding-10 {
  padding: 10px 0 0;
}
::v-deep [data-tax-error=true] .ivu-input {
  border-color: red;
}
.ro-reset-popup ::v-deep .ivu-modal {
  top: 50%;
  transform: translateY(-50%);
}
</style>
