import axiosInstance from '../../../Api'
let ApiService = {
  getBusinessProfiles () {
  // eslint-disable-next-line no-undef
    const getAllBusinessProfiles = secretKeyParams.apiBaseUrl
    // eslint-disable-next-line no-undef
      .concat(`portals/${secretKeyParams.portalId}/BusinessProfiles?userId=${secretKeyParams.userId}`)

    const promise = axiosInstance.get(getAllBusinessProfiles)
    return promise
  },

  getAllPortalUsers () {
    // eslint-disable-next-line no-undef
    const getAllStatuses = secretKeyParams.apiBaseUrl
    // eslint-disable-next-line no-undef
      .concat(`portals/${secretKeyParams.portalId}/users`)

    const promise = axiosInstance.get(getAllStatuses)
    return promise
  },

  getMediaPlanObjectives (businessProfileId) {
    // eslint-disable-next-line no-undef
    const mediaPlanObjectivesUrl = secretKeyParams.apiBaseUrl
      .concat(`businessProfiles/${businessProfileId}/`)
      .concat(`budgetcenter/Objectives`)
    const promise = axiosInstance.get(mediaPlanObjectivesUrl);
    return promise;
  },
  saveBrief (businessProfileId, briefObj) {
    // eslint-disable-next-line no-undef
    const saveUrl = secretKeyParams.apiBaseUrl
      .concat(`businessProfiles/${businessProfileId}/`)
      .concat(`brief`)
    const promise = axiosInstance.post(saveUrl, briefObj);
    return promise;
  },
  getBusinessProfile (businessProfileId) {
    // eslint-disable-next-line no-undef
    const getBusinessProfileDetails = secretKeyParams.apiBaseUrl
      .concat(`businessprofiles/${businessProfileId}`)
    const promise = axiosInstance.get(getBusinessProfileDetails)
    return promise
  },
  getDivisions (businessProfileId) {
    // eslint-disable-next-line no-undef
    const getDivisionDetails = secretKeyParams.apiBaseUrl
      .concat(`businessprofiles/${businessProfileId}/divisions`)
    const promise = axiosInstance.get(getDivisionDetails)
    return promise
  }
}
export { ApiService };
