<template>
  <div>
    <PurchaseOrderLinkingPopUp
     ref="purchaseOrderLinkingPopUp"
     :budget="mediaPlanBudget"
     @cancelPurchaseOrderPopUp="cancelPurchaseOrderPopUp"
     @linkPurchaseOrder="linkPurchaseOrder"
     style="margin-top: 20px;"
    >
    </PurchaseOrderLinkingPopUp>
    <AddPurchaseOrder
    ref="addPurchaseOrderPopUp"
    :clientId="clientId"
    @cancelAddPurchaseOrder="cancelAddPurchaseOrder"
    >
    </AddPurchaseOrder>
    <i-modal
      v-model="showModal"
      width="90%"
      :closable="false"
      :mask-closable="false"
    >
      <div slot="header" class="header-style">
        <div class="col-md-8 no-padding">
          <h4>Raise Estimate</h4>
        </div>
        <div class="col-md-4 budget-style" style="padding-right: 0px">
          <span class="text-style" style="float: right"
            >Budget : {{ getBpCurrency() }}
            {{ mediaPlanBudget | commaSeparatedNumberFormatter }}</span
          >
        </div>
      </div>
      <div v-if="showSpinner" style="text-align: center">
        <i class="fa fa-spinner fa-spin fa-3x"></i>
      </div>
      <div
        v-if="!showSpinner"
        class="modal-body no-padding"
        :style="
          hideTable
            ? 'height: 450px;'
            : enableCoFundingRules
            ? 'height: 450px;'
            : 'height: 450px;'
        "
      >
        <div class="col-md-12 no-padding estimate-modal-headers-style">
          <div class="header-text-style">Estimate Configuration</div>
        </div>
        <div class="col-md-12 no-padding">
          <div class="col-md-3 no-padding">
            <span class="label-billing-agencyUnit" style="padding: 5px"
              >Estimate Raised By</span
            >
          </div>
          <div class="col-md-3 no-padding padding-right">
            <i-select
              v-model="selectedAgencyUnit"
              placeholder="Select AgencyUnit"
              :class="agencyUnitEmptyError"
              :disabled="!showEstimateConfiguration"
              filterable
            >
              <i-option
                v-for="agencyUnit in agencyUnits"
                :value="agencyUnit.id"
                :key="agencyUnit.id"
                >{{ agencyUnit.name }}</i-option
              >
            </i-select>
          </div>
        </div>
        <div class="col-md-12 no-padding" style="margin-top: 20px">
          <div class="col-md-3 no-padding">
            <span class="text-style">Estimate Raised For</span>
          </div>
          <div class="col-md-3 no-padding padding-right">
            <i-select
              v-model="primaryClient"
              filterable
              placeholder="Select Client"
              :class="primaryClientEmptyError"
              :disabled="!showEstimateConfiguration"
              @on-change="primaryClientChanged()"
            >
              <i-option
                v-for="client in clients"
                :value="client.id"
                :key="client.id"
                >{{ client.name }}</i-option
              >
            </i-select>
          </div>
        </div>
        <div class="col-md-12 no-padding" style="margin-top: 20px">
          <div class="col-md-3 no-padding">
            <span class="text-style">Remarks</span>
          </div>
          <div class="col-md-3 no-padding padding-right">
            <i-input v-model="remarks" type="textarea" placeholder="Enter Remarks..." />
          </div>
        </div>
        <div
          class="col-md-12 no-padding"
          style="margin-top: 20px"
          v-show="!hideTable"
        >
          <div class="col-md-3 no-padding">
            <span class="cofunding-text-style" style="padding: 12px"
              >Co Funding Rules</span
            >
          </div>
          <div class="col-md-1 no-padding padding-right">
            <i-switch
              v-model="enableCoFundingRules"
              :disabled="!showEstimateConfiguration"
              @on-change="resetCoFundingData"
            />
          </div>
        </div>
        <div
          class="col-md-12 no-padding co-funding-table-class"
          v-show="enableCoFundingRules"
        >
          <div class="col-md-3 no-padding" style="text-align: center"></div>
          <div class="col-md-9 no-padding padding-right">
            <i-table
              ref="iViewTable"
              :columns="iColumns"
              :data="iData"
              border
              disabled-hover
              size="small"
            >
              <template slot-scope="{ row }" slot="checkboxAction">
                <div>
                  <CheckboxGroup
                    size="small"
                    v-model="selectedClients"
                    @on-change="checkboxChecked(row)"
                  >
                    <Checkbox
                      :label="row.client"
                      :disabled="disableCheckbox(row)"
                      class="cross-channel-checkbox"
                    >
                      <span></span>
                    </Checkbox>
                  </CheckboxGroup>
                </div>
              </template>
              <template slot-scope="{ row }" slot="client">
                <div style="float: left">{{ row.clientName }}</div>
              </template>
              <template slot-scope="{ row }" slot="fundingType">
                <div>
                  <i-select
                    v-model="row.fundingType"
                    size="small"
                    filterable
                    @on-change="fundingTypeChanged(row)"
                    :class="row.fundingTypeEmptyError"
                  >
                    <i-option
                      v-for="fundingType in fundingTypes"
                      class="padding-left-select-option"
                      :value="fundingType.id"
                      :key="fundingType.id"
                      >{{ fundingType.type }}</i-option
                    >
                  </i-select>
                </div>
              </template>
              <template slot-scope="{ row }" slot="fundingValue">
                <div>
                  <i-input
                    size="small"
                    v-model="row.fundingValue"
                    type="number"
                    @on-blur="fundingValueChanged(row)"
                    :class="row.fundingValueEmptyError"
                  />
                </div>
              </template>
              <template slot-scope="{ row }" slot="amount">
                <div>
                  <i-input
                    size="small"
                    :disabled="disableAmount"
                    v-model="row.amount"
                    type="number"
                  />
                </div>
              </template>
            </i-table>
          </div>
        </div>
        <!-- -->
        <div
          class="col-md-12 no-padding estimate-modal-headers-style"
          style="margin-top: 20px"
        >
          <div class="header-text-style">PO Linking</div>
        </div>
        <div class="col-md-12 no-padding">
          <button
            class="btn create-btn btn-success button-style"
            @click="linkPurchaseOrdersPopUp"
          >
            Link PO
          </button>
          <button
            class="btn create-btn btn-white button-style"
            @click="addPurchaseOrders"
          >
            Add PO
          </button>
        </div>
        <div
          class="col-md-12 no-padding"
          style="margin-top: 10px"
          v-if="showPoTable"
        >
        <i-table
            ref="poTable"
            :columns="poColumns"
            :data="poData"
            border
            disabled-hover
            size="small"
          >
          <template slot-scope="{ row }" slot="action">
            <span @click="removePo(row)">
              <i class="fa fa-times" aria-hidden="true"></i>
            </span>
          </template>
        </i-table>
        </div>
        <div v-if="showPoTable" class="col-md-12 no-padding" style="margin-top: 5px">
          <button
            class="btn create-btn btn-success button-style"
            @click="resetPO()"
          >
            Reset
          </button>
        </div>
        <!-- -->
        <div
          class="col-md-12 no-padding estimate-modal-headers-style flex"
          style="margin-top: 20px"
        >
          <div class="header-text-style" style="width:30%" align="left">RO Confirmation</div>
          <div class="header-text-style" style="width:70%" align="right">
            <span style="margin-right:15px">Locked : {{locked | commaSeparatedNumberFormatter}}</span>
            <span style="margin-right:15px">Invoiced : {{invoiced | commaSeparatedNumberFormatter}}</span>
            <span style="margin-right:15px">Balance : {{balance | commaSeparatedNumberFormatter}}</span>
          </div>
          <div style="margin-right:10px; margin-top:5px">
            <span class="green" v-if="totalSum >= mediaPlanBudget">   <i class="fa fa-check fa-2x" aria-hidden="true"></i>  </span>
            <span class="red" v-else><i class="fa fa-exclamation fa-2x" aria-hidden="true"></i></span>
          </div>
        </div>
        <div class="col-md-12 no-padding">
          <i-switch
            v-model="autoGenerateROSwitch"
            :style="autoGenerateROSwitch ? 'background-color: #03629B;' : ''"
            :disabled="disableROSwitch"
          />
          <span class="text-style" style="padding-left: 10px; padding-top: 20px"
            >Auto Generate RO</span
          >
        </div>
        <div class="col-md-12 no-padding ro-confirmation-table-class">
          <i-table
            ref="roTable"
            :columns="roColumns"
            :data="roData"
            border
            disabled-hover
            size="small"
          >
            <template slot-scope="{ row }" slot="dateRange">
              <div>
                <DatePicker
                  type="daterange"
                  size="small"
                  placement="top"
                  format="MMM d, yyyy"
                  :clearable="false"
                  :split-panels="true"
                  :editable="false"
                  v-model="row.dateRange"
                  @on-change="dateRangeChange(row)"
                ></DatePicker>
              </div>
            </template>

            <template slot-scope="{ row }" slot="publisherEmail">
              <div>
                <i-input
                  size="small"
                  placeholder="Enter comma seperated values"
                  v-model="row.publisherEmail"
                  @on-blur="publisherEmailChange(row)"
                />
              </div>
            </template>

            <template slot-scope="{ row }" slot="supplier">
              <div>
                <i-select
                  v-model="row.supplierId"
                  size="small"
                  filterable
                  @on-change="supplierChange(row)"
                >
                  <i-option
                    v-for="sup in row.filteredSuppliers"
                    :value="sup.id"
                    :key="sup.id"
                    >{{ sup.name }}</i-option
                  >
                </i-select>
              </div>
            </template>
          </i-table>
        </div>
      </div>
      <div class="footer-wrapper" slot="footer">
        <div class="col-md-7 no-padding">
          <div
            class="col-md-10 alert alert-danger error-padding"
            v-show="fundingValueExceedingError"
            role="alert"
          >
            {{ errorMessage }}
            <button
              class="close error-close-padding"
              @click="fundingValueExceedingError = false"
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
        </div>
        <div class="col-md-5 no-padding" style="float: left">
          <button
            class="btn create-btn btn-white button-style"
            @click="cancelAction"
          >
            Cancel
          </button>
          <button
            id="btn-save"
            class="btn create-btn btn-success button-style"
            @click="save"
          >
            Submit
          </button>
        </div>
      </div>
    </i-modal>
  </div>
</template>
<script>
import {
  Modal,
  Select,
  Option,
  Table,
  Input,
  Switch,
  CheckboxGroup,
  Checkbox,
  DatePicker,
  locale
} from "iview";
import PurchaseOrderLinkingPopUp from '../Components/PurchaseOrderLinkingPopUp.vue';
import AddPurchaseOrder from '../../PurchaseOrder/Components/AddPurchaseOrder.vue';
import { ApiService } from ".././Services/MediaPlanCoFundingApiService.js";
import lang from "iview/dist/locale/en-US"; // iview is default chinese
locale(lang);
export default {
  components: {
    "i-modal": Modal,
    "i-select": Select,
    "i-option": Option,
    "i-table": Table,
    "i-input": Input,
    "i-switch": Switch,
    CheckboxGroup,
    Checkbox,
    PurchaseOrderLinkingPopUp,
    AddPurchaseOrder,
    DatePicker
  },
  filters: {
    commaSeparatedNumberFormatter: function (number) {
      var num = 0;
      if (number > 0) {
        num = number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
      }
      return num;
    }
  },
  mounted () {
    $(".perfect-scroll-bar").perfectScrollbar({});
  },
  created () {
    this.apiService = new ApiService();
  },
  data: function () {
    return {
      showModal: false,
      apiService: {},
      agencyUnits: [],
      selectedAgencyUnit: 0,
      clients: [],
      fundingTypes: [
        {
          id: 1,
          type: "Fixed"
        },
        {
          id: 2,
          type: "Percentage"
        }
      ],
      iColumns: [
        {
          title: " ",
          key: "checkboxAction",
          align: "center",
          slot: "checkboxAction",
          width: 50
        },
        {
          title: "Client",
          key: "client",
          align: "center",
          slot: "client"
        },
        {
          title: "Funding Type",
          key: "fundingtype",
          align: "center",
          slot: "fundingType"
        },
        {
          title: "Funding Value",
          key: "fundingValue",
          align: "center",
          slot: "fundingValue"
        },
        {
          title: "Amount (" + deltax.currencySymbol + ")",
          key: "amount",
          align: "center",
          slot: "amount"
        }
      ],
      roColumns: [
        {
          title: "Publisher",
          key: "publisher",
          align: "center",
          className: "read-only-class"
        },
        {
          title: "Target",
          key: "target",
          align: "center",
          className: "read-only-class"
        },
        {
          title: "Date Range",
          key: "dateRange",
          align: "center",
          slot: "dateRange",
          width: 215
        },
        {
          title: "Supplier",
          key: "supplierId",
          align: "center",
          slot: "supplier"
        },
        {
          title: "Supply AgencyUnit",
          key: "supplyAgencyUnit",
          align: "center",
          className: "read-only-class"
        },
        {
          title: "Publisher Email",
          key: "publisherEmail",
          align: "center",
          slot: "publisherEmail"
        }
      ],
      poColumns: [
        {
          slot: "action",
          width: 30
        },
        {
          title: "PO Date",
          key: "poDate",
          align: "center"
        },
        {
          title: "PO Number",
          key: "poNumber",
          align: "center"
        },
        {
          title: "Value",
          key: "value",
          align: "center"
        },
        {
          title: "Invoiced",
          key: "consumed",
          align: "center"
        },
        {
          title: "Locked",
          key: "locked",
          align: "center"
        },
        {
          title: "Balance",
          key: "remaining",
          align: "center"
        }
      ],
      iData: [],
      suppliers: [],
      publishers: [],
      remarks: "",
      mediaPlanBudget: 0,
      disableAmount: true,
      primaryClient: 0,
      agencyUnitEmptyError: "",
      errorMessage: "",
      roData: [],
      poData: [],
      invoices: [],
      fundingValueExceedingError: false,
      primaryClientEmptyError: "",
      selectedClients: [],
      lineItems: [],
      mediaPlanId: 0,
      enableCoFundingRules: false,
      showSpinner: false,
      autoGenerateROSwitch: false,
      disableROSwitch: false,
      approvedBuyBusinessProfileId: null,
      mediaPlan: null
    };
  },
  computed: {
    locked: function () {
      if (this.poData.length > 0) {
        var sum = this.poData.map(p => p.locked).reduce((prev, next) => prev + next);
        return sum;
      }
      return 0;
    },
    invoiced: function () {
      if (this.poData.length > 0) {
        var sum = this.poData.map(p => p.consumed).reduce((prev, next) => prev + next);
        return sum;
      }
      return 0;
    },
    balance: function () {
      if (this.poData.length > 0) {
        var sum = this.poData.map(p => p.remaining).reduce((prev, next) => prev + next);
        return sum;
      }
      return 0;
    },
    totalSum: function () {
      var sum = 0;
      if (this.poData.length > 0) {
        sum = this.poData.map(p => p.consumed).reduce((prev, next) => prev + next) +
                this.poData.map(p => p.locked).reduce((prev, next) => prev + next) +
                this.poData.map(p => p.remaining).reduce((prev, next) => prev + next);
      }
      if (sum < this.mediaPlanBudget) {
        this.autoGenerateROSwitch = false;
        this.disableROSwitch = true;
      } else {
        this.disableROSwitch = false;
      }
      return sum;
    },
    clientId: function () {
      if (deltax.businessProfileId != undefined) {
        return parseInt(deltax.businessProfileId);
      }
      return parseInt(this.approvedBuyBusinessProfileId);
    },
    showPoTable: function () {
      if (this.poData.length > 0) {
        return true;
      }
      return false;
    },
    hideTable: function () {
      if (this.clients.length == 1 || this.clients.length == 0) {
        return true;
      } else {
        return false;
      }
    },
    showEstimateConfiguration: function () {
      if (this.mediaPlan == undefined || this.approvedBuyBusinessProfileId != null) {
        return true;
      }
      if (this.mediaPlan.status == 2) {
        return false;
      }
      //  Draft
      if (this.mediaPlan.status == 1) {
        if (
          this.mediaPlan.approvedBuyId == undefined
        ) {
          return true;
        } else {
          return false;
        }
      }
      return false;
    }
  },
  methods: {
    linkPurchaseOrdersPopUp: function () {
      this.$refs.purchaseOrderLinkingPopUp.setupModal(this.poData);
    },
    cancelPurchaseOrderPopUp: function () {
      this.$refs.purchaseOrderLinkingPopUp.cancelModal();
    },
    addPurchaseOrders: function () {
      this.$refs.addPurchaseOrderPopUp.setupModal();
    },
    cancelAddPurchaseOrder: function () {
      this.$refs.addPurchaseOrder.cancelModal();
    },
    linkPurchaseOrder: function (poData) {
      this.poData = poData;
    },
    setupModalForEstimateGrid: function (mediaPlanId, approvedBuyBusinessProfileId) {
      if (approvedBuyBusinessProfileId != undefined) {
        this.approvedBuyBusinessProfileId = approvedBuyBusinessProfileId;
        this.apiService.SetBuinessProfileId(this.approvedBuyBusinessProfileId).then(() => {
          this.mediaPlanId = mediaPlanId;
          this.showModal = true;
          this.showSpinner = true;
          this.fetchData();
        });
      }
    },
    setupModal: function (mediaPlanId) {
      this.mediaPlanId = mediaPlanId;
      this.showModal = true;
      this.showSpinner = true;
      this.poData = [];
      this.fetchData();
    },
    cancelModal: function () {
      this.showModal = false;
    },
    removePo: function (row) {
      this.poData = this.poData.filter((p) => p.id != row.id);
    },
    resetPO: function () {
      var invoicesApprovedBuyIds = this.invoices.map((i) => i.approvedBuyId);
      this.poData = this.poData.filter((p) => p.approvedBuyIds.some(abIds => invoicesApprovedBuyIds.includes(abIds)));
    },
    getBpCurrency: function () {
      return deltax.currencySymbol;
    },
    supplierChange: function (row) {
      this.roData[row.index].supplierId = row.supplierId;
      row.supplyAgencyUnit = row.filteredSuppliers
        .filter((x) => x.id == row.supplierId)
        .map((supplier) => supplier.addressLine1)[0];
      this.roData[row.index].supplyAgencyUnit = row.supplyAgencyUnit;
    },
    publisherEmailChange: function (row) {
      this.roData[row.index].publisherEmail = row.publisherEmail;
    },
    dateRangeChange: function (row) {
      this.roData[row.index].dateRange = row.dateRange;
    },
    lineItemSave: function () {
      var lineItems = this.roData.map((x) => {
        var startDate = moment(x.dateRange[0]).format("YYYY/MM/DD");
        var endDate = moment(x.dateRange[1]).format("YYYY/MM/DD");
        var reserveOrderDetails = {
          email: x.publisherEmail,
          autoGenerateROFlag: this.autoGenerateROSwitch
        };
        var lineItem = {
          lineItemId: x.lineItemId,
          startDate: startDate,
          endDate: endDate,
          supplierId: x.supplierId,
          reserveOrderDetails: reserveOrderDetails
        };
        return lineItem;
      });
      var promises = [];
      for (var roLineItem of lineItems) {
        var promise = this.apiService.updateLineItem(
          roLineItem.lineItemId,
          roLineItem.startDate,
          roLineItem.endDate,
          roLineItem.supplierId,
          roLineItem.reserveOrderDetails
        );
        promises.push(promise);
      }
      return promises;
    },
    mapLineItemsToRoColumns: function (lineItems, suppliers, publishers) {
      var index = 0;
      var ro = lineItems.map((lineItem) => {
        var lineItemRoData = {
          index: index,
          lineItemId: lineItem.id,
          publisher: lineItem.publisher,
          target: lineItem.targeting,
          supplierId: lineItem.supplierId,
          dateRange: [],
          supplier: "",
          supplyAgencyUnit: "",
          filteredSuppliers: [],
          publisherEmail: "",
          reserveOrderDetails: null,
          autoGenerateROFlag: false
        };
        lineItemRoData.dateRange = [
          moment(lineItem.startDate).toDate(),
          moment(lineItem.endDate).toDate()
        ];
        lineItemRoData.supplier = suppliers
          .filter((x) => x.id == lineItem.supplierId)
          .map((supplier) => supplier.name)[0];
        lineItemRoData.supplyAgencyUnit = suppliers
          .filter((x) => x.id == lineItem.supplierId)
          .map((supplier) => supplier.addressLine1)[0];
        var supplierIdsOfFilteredPublishers = publishers
          .filter((x) => x.name == lineItem.publisher)
          .map((p) => p.supplierId);
        lineItemRoData.filteredSuppliers = suppliers.filter((x) =>
          supplierIdsOfFilteredPublishers.includes(x.id)
        );
        if (lineItem.reserveOrderDetails != undefined) {
          lineItemRoData.reserveOrderDetails = JSON.parse(
            lineItem.reserveOrderDetails
          );
          lineItemRoData.publisherEmail =
            lineItemRoData.reserveOrderDetails.email;
          lineItemRoData.autoGenerateROFlag =
            lineItemRoData.reserveOrderDetails.autoGenerateROFlag;
        }
        index++;
        return lineItemRoData;
      });
      this.roData = ro;
      this.autoGenerateROSwitch = this.roData.some(
        (x) => x.autoGenerateROFlag == true
      );
    },
    fetchData: function () {
      this.clearData();
      var promises = [];
      var getAgencyUnitsPromise = this.apiService.GetAgencyUnits();
      promises.push(getAgencyUnitsPromise);
      var clientsPromise = this.apiService.GetClients();
      promises.push(clientsPromise);
      var getMediaPlanWithChildEntitiesPromise =
        this.apiService.GetMediaPlanWithChildEntities(this.mediaPlanId);
      promises.push(getMediaPlanWithChildEntitiesPromise);
      var getSuppliersPromise = this.apiService.GetSuppliers();
      promises.push(getSuppliersPromise);
      var getPublisherPromise = this.apiService.GetPublishers();
      promises.push(getPublisherPromise);
      var getInvoicesPromise = this.apiService.GetInvoices();
      promises.push(getInvoicesPromise);
      Promise.all(promises)
        .then((response) => {
          this.showSpinner = false;
          this.mediaPlan = response[2].data.data;
          this.mediaPlanBudget = response[2].data.data.budget;
          this.agencyUnits = response[0].data.data.agencyUnits;
          if (this.agencyUnits.length > 0) {
            this.selectedAgencyUnit = this.agencyUnits[0].id;
          }

          this.clients = response[1].data.data.clients;
          if (this.clients.length > 0) {
            // set first Client as primary by default
            this.primaryClient = this.clients[0].id;
            if (this.clients.length > 0) {
              this.assignTableData();
            }
          }
          this.lineItems = response[2].data.data.lineItems;
          this.suppliers = response[3].data.suppliers;
          this.publishers = response[4].data.publishers;
          this.mapLineItemsToRoColumns(
            response[2].data.data.lineItems,
            response[3].data.suppliers,
            response[4].data.publishers
          );
          this.invoices = response[5].data.invoices;
          if (this.mediaPlan.approvedBuyId != undefined) {
            var promisesApprovedBuyId = [];
            var getPurchaseOrdersByApprovedBuyIdPromise = this.apiService.GetPurchaseOrdersByApprovedBuyId(this.mediaPlan.approvedBuyId);
            promisesApprovedBuyId.push(getPurchaseOrdersByApprovedBuyIdPromise);
            var getApprovedBuyByIdPromise = this.apiService.GetApprovedBuyById(this.mediaPlan.approvedBuyId);
            promisesApprovedBuyId.push(getApprovedBuyByIdPromise);
            Promise.all(promisesApprovedBuyId).then((res) => {
              this.poData = res[0].data.data.purchaseOrders.map((element) => {
                var obj = {
                  id: element.id,
                  poDate: moment(element.issuedDate).format('DD MMM YYYY'),
                  poNumber: element.clientIONumber,
                  value: element.amount,
                  purchaseOrderId: element.id,
                  consumed: element.consumed,
                  locked: element.locked,
                  remaining: element.amount - element.consumed,
                  approvedBuyIds: element.approvedBuyIds != undefined ? element.approvedBuyIds.split(',').map(Number) : []
                };
                return obj;
              });
              if (res[1].data.data.remarks != undefined) {
                this.remarks = res[1].data.data.remarks;
              }
            });
          }
        })
        .catch((err) => {
          console.log("error in fetching co funding data", err);
        });
    },
    cancelAction: function () {
      this.showModal = false;
      this.$emit("cancelCoFundingModal");
    },
    save: function () {
      var isValid = true;
      this.errorMessage = "";
      this.fundingValueExceedingError = false;
      var userPreferenceJson = {};
      if (this.showEstimateConfiguration) {
        if (!this.selectedAgencyUnit) {
          this.agencyUnitEmptyError = " field-empty-error ";
          isValid = false;
        }

        if (!this.primaryClient) {
          this.primaryClientEmptyError = " field-empty-error ";
          isValid = false;
        }

        var selectedClientRows = this.iData.filter((f) =>
          this.selectedClients.includes(f.client)
        );
        selectedClientRows.forEach((row) => {
          if (!row.fundingType) {
            row.fundingTypeEmptyError = " field-empty-error ";
            isValid = false;
          }
          if (
            row.fundingValue == undefined ||
            row.fundingValue == "" ||
            row.fundingValue == null
          ) {
            row.fundingValueEmptyError = " field-empty-error ";
            isValid = false;
          }
          var index = this.iData.findIndex((x) => x.orderId == row.orderId);
          this.iData[index] = row;
          this.iData.splice(index, 1, row);
        });

        if (!isValid) {
          return true;
        }

        var isFixedSumValid = this.checkTotalFixedSum(); // check for fixed type values sum greater than media plan budget
        if (!isFixedSumValid) {
          return true;
        }
        var isPercentSumValid = this.checkTotalPercentSum(); // check for percentage type values sum greater than media plan budget
        if (!isPercentSumValid) {
          return true;
        }
        var totalSum = this.checkTotalSum(); // check for total sum greater than media plan budget
        if (!totalSum) {
          return true;
        }

        var fundingDetail = [];
        var selectedRows = [];
        if (this.clients.length == 1) {
          selectedRows = this.iData;
        } else {
          selectedRows = this.iData.filter((f) =>
            this.selectedClients.includes(f.client)
          );
        }
        selectedRows.forEach((element) => {
          fundingDetail.push({
            ClientId: element.client,
            DistributionType: element.fundingType,
            Value: Number(element.fundingValue)
          });
        });
        userPreferenceJson = {
          agencyUnitId: this.selectedAgencyUnit,
          primaryClientId: this.primaryClient,
          fundingDetail: fundingDetail
        };
      }
      var promises = this.lineItemSave();
      if (this.autoGenerateROSwitch == true && this.mediaPlan.approvedBuyId != undefined) {
        var promise = this.apiService.createApprovedBuySignedContractTask(this.mediaPlan.approvedBuyId);
        promises.push(promise);
      }
      Promise.all(promises)
        .then((response) => {
          if (this.mediaPlan.status == 1) {
            this.$emit("sendEstimate", userPreferenceJson, this.poData, this.remarks);
          } else if (this.mediaPlan.approvedBuyId != undefined) {
            var promisesForApprovedBuy = [];
            var purchaseOrderIds = [];
            if (this.poData.length > 0) {
              purchaseOrderIds = this.poData.map((p) => p.purchaseOrderId);
            }
            var linkPOPromise = this.apiService.LinkPurchaseOrdersWithApprovedBuy(purchaseOrderIds, this.mediaPlan.approvedBuyId);
            promisesForApprovedBuy.push(linkPOPromise);
            var patchRemarksPromise = this.apiService.updateRemarks(this.mediaPlan.approvedBuyId, this.remarks);
            promisesForApprovedBuy.push(patchRemarksPromise);
            var cofundingSavepromise = this.apiService.updateClientJson(this.mediaPlan.approvedBuyId, JSON.stringify("[{'Name':'UserPreferenceJson','Value':'" + JSON.stringify(userPreferenceJson) + "'}, {'Name':'ClientId','Value':'" + userPreferenceJson.primaryClientId + "'}, {'Name':'AgencyUnitId','Value':'" + userPreferenceJson.agencyUnitId + "'}]"));
            promisesForApprovedBuy.push(cofundingSavepromise);
            Promise.all(promisesForApprovedBuy).then((res) => {
              console.log("Link Po and update remarks, client and task creation success");
              this.$emit("sendEstimate");
            });
          }
          this.showModal = false;
        })
        .catch((err) => {
          this.$emit("submitEstimateFailed");
          console.log("error in patch endpoint of RO LineItem or task creation", err);
        });
    },
    clientChanged: function (row) {
      let index = this.iData.findIndex((e) => e.orderId == row.orderId);
      var selectedRow = this.iData[index];
      selectedRow.isUpdated = true;
      selectedRow.client = row.client;
      selectedRow.clientName = row.clientName;
      selectedRow.fundingType = row.fundingType;
      selectedRow.fundingValue = row.fundingValue;
      selectedRow.amount = row.amount;
      selectedRow.orderId = row.orderId;
      selectedRow.fundingTypeEmptyError = row.fundingTypeEmptyError;
      selectedRow.fundingValueEmptyError = row.fundingValueEmptyError;
      this.iData.splice(index, 1, selectedRow);
    },
    clearData: function () {
      this.iData = [];
      this.remarks = "";
      this.clients = [];
      this.selectedClients = [];
      this.primaryClient = 0;
      this.agencyUnits = [];
      this.selectedAgencyUnit = 0;
      this.agencyUnitEmptyError = "";
      this.errorMessage = "";
      this.fundingValueExceedingError = false;
      this.primaryClientEmptyError = "";
      this.enableCoFundingRules = false;
    },
    resetCoFundingData: function () {
      if (this.enableCoFundingRules == false) {
        this.agencyUnitEmptyError = "";
        this.errorMessage = "";
        this.fundingValueExceedingError = false;
        this.primaryClientEmptyError = "";
      }
      this.iData = [];
      if (this.clients.length > 0) {
        this.assignTableData();
      }
    },
    assignTableData: function () {
      var orderId = 0;
      this.selectedClients = [];
      this.selectedClients.push(this.primaryClient);

      this.clients.forEach((client) => {
        orderId = orderId + 1;
        var clientInfo = {
          id: client.id,
          orderId: orderId,
          client: client.id,
          clientName: client.name,
          isUpdated: false
        };

        if (client.id == this.primaryClient) {
          clientInfo["fundingType"] = 2;
          clientInfo["fundingValue"] = 100;
          clientInfo["amount"] = this.mediaPlanBudget;
        }

        this.iData.push(clientInfo);
      });
    },
    fundingValueChanged: function (row) {
      // clear all errors
      this.iData.forEach((element) => {
        element.fundingValueEmptyError = "";
      });
      this.errorMessage = "";
      this.fundingValueExceedingError = false;

      // set data
      let index = this.iData.findIndex((e) => e.orderId == row.orderId);
      var selectedRow = this.iData[index];
      selectedRow.fundingValueEmptyError = "";
      selectedRow.fundingValue = row.fundingValue;
      selectedRow.client = row.client;
      selectedRow.clientName = row.clientName;
      selectedRow.fundingType = row.fundingType;
      selectedRow.orderId = row.orderId;
      selectedRow.fundingTypeEmptyError = row.fundingTypeEmptyError;
      selectedRow.amount = row.amount;
      if (
        row.fundingType &&
        row.fundingValue != undefined &&
        row.fundingValue != ""
      ) {
        selectedRow.isUpdated = true;
        var fundingValue = selectedRow.fundingValue;
        if (selectedRow.fundingType == 1) {
          selectedRow.amount = fundingValue;
        } else {
          var computedAmount =
            this.mediaPlanBudget * (selectedRow.fundingValue / 100);
          selectedRow.amount = Number.isInteger(Number(computedAmount))
            ? computedAmount
            : parseFloat(computedAmount).toFixed(2);
        }
      } else {
        selectedRow.amount = "";
      }

      // validation
      var isFixedsumValid = this.checkTotalFixedSum();
      var isPercentSumValid = this.checkTotalPercentSum();
      if (!isFixedsumValid && !isPercentSumValid) {
        this.errorMessage = "Total allocation of funds exceeds plan budget";
        this.fundingValueExceedingError = true;
      }
      this.iData.splice(index, 1, selectedRow);
    },
    fundingTypeChanged: function (row) {
      // clear all errors
      this.iData.forEach((element) => {
        element.fundingTypeEmptyError = "";
        element.fundingValueEmptyError = "";
      });
      this.errorMessage = "";
      this.fundingValueExceedingError = false;

      // set data
      let index = this.iData.findIndex((e) => e.orderId == row.orderId);
      var selectedRow = this.iData[index];
      selectedRow.isUpdated = true;
      selectedRow.fundingTypeEmptyError = "";
      selectedRow.fundingType = row.fundingType;
      selectedRow.fundingValue = row.fundingValue;
      selectedRow.orderId = row.orderId;
      selectedRow.client = row.client;
      selectedRow.clientName = row.clientName;
      if (selectedRow.fundingValue > 0) {
        var fundingValue = selectedRow.fundingValue;
        if (selectedRow.fundingType == 1) {
          selectedRow.amount = fundingValue;
        } else {
          var computedAmount =
            this.mediaPlanBudget * (selectedRow.fundingValue / 100);
          selectedRow.amount = Number.isInteger(Number(computedAmount))
            ? computedAmount
            : parseFloat(computedAmount).toFixed(2);
        }
      }
      // validation
      var isFixedsumValid = this.checkTotalFixedSum();
      var isPercentSumValid = this.checkTotalPercentSum();
      if (!isFixedsumValid && !isPercentSumValid) {
        this.errorMessage = "Total allocation of funds exceeds plan budget";
        this.fundingValueExceedingError = true;
      }

      this.iData.splice(index, 1, selectedRow);
    },
    checkTotalFixedSum: function () {
      var isValid = true;
      var selectedClientRows = this.iData.filter((f) =>
        this.selectedClients.includes(f.client)
      );
      var fixedTypeRows = selectedClientRows.filter(
        (x) => x.fundingType == 1
      );
      var fixedValuesSum = fixedTypeRows
        .filter((x) => x.fundingValue > 0)
        .map((x) => x.fundingValue)
        .reduce(function (a, b) {
          return parseInt(a) + parseInt(b);
        }, 0);
      if (fixedValuesSum > this.mediaPlanBudget) {
        fixedTypeRows.forEach((row) => {
          if (row.fundingValue > 0) {
            isValid = false;
            row.fundingValueEmptyError = " field-empty-error ";
            this.errorMessage = "Total allocation of funds exceeds plan budget";
            this.fundingValueExceedingError = true;

            var index = this.iData.findIndex((x) => x.orderId == row.orderId);
            this.iData[index] = row;
            this.iData.splice(index, 1, row);
          }
        });
      }
      return isValid;
    },
    checkTotalPercentSum: function () {
      var isValid = true;
      var selectedClientRows = this.iData.filter((f) =>
        this.selectedClients.includes(f.client)
      );
      var percentTypeRows = selectedClientRows.filter(
        (x) => x.fundingType == 2
      );
      var percentValuesSum = percentTypeRows
        .filter((x) => x.fundingValue > 0)
        .map((x) => x.fundingValue)
        .reduce(function (a, b) {
          return parseInt(a) + parseInt(b);
        }, 0);
      if (percentValuesSum > 100) {
        percentTypeRows.forEach((row) => {
          if (row.fundingValue > 0) {
            isValid = false;
            row.fundingValueEmptyError = " field-empty-error ";
            this.errorMessage = "Total allocation of funds exceeds plan budget";
            this.fundingValueExceedingError = true;

            var index = this.iData.findIndex((x) => x.orderId == row.orderId);
            this.iData[index] = row;
            this.iData.splice(index, 1, row);
          }
        });
      }
      return isValid;
    },
    checkTotalSum: function () {
      var isValid = true;
      var selectedClientRows = this.iData.filter((f) =>
        this.selectedClients.includes(f.client)
      );
      var totalSum = selectedClientRows
        .filter((x) => x.amount > 0)
        .map((x) => x.amount)
        .reduce(function (a, b) {
          return Math.round(a) + Math.round(b);
        }, 0);
      if (totalSum > this.mediaPlanBudget || totalSum < this.mediaPlanBudget) {
        isValid = false;
        this.errorMessage = "Total allocation of funds exceeds plan budget";
        this.fundingValueExceedingError = true;
      }
      return isValid;
    },
    disableCheckbox: function (row) {
      if (this.primaryClient == row.client) {
        return true;
      } else {
        return false;
      }
    },
    primaryClientChanged: function () {
      if (
        this.primaryClient != undefined &&
        this.primaryClient > 0
      ) {
        if (!this.enableCoFundingRules) {
          this.resetCoFundingData();
          return;
        }
        if (!this.selectedClients.includes(this.primaryClient)) {
          this.selectedClients.push(this.primaryClient);

          // clear all errors
          this.iData.forEach((element) => {
            element.fundingTypeEmptyError = "";
            element.fundingValueEmptyError = "";
          });
          this.errorMessage = "";
          this.fundingValueExceedingError = false;

          // validation
          var isFixedsumValid = this.checkTotalFixedSum();
          var isPercentSumValid = this.checkTotalPercentSum();
          if (!isFixedsumValid && !isPercentSumValid) {
            this.errorMessage = "Total allocation of funds exceeds plan budget";
            this.fundingValueExceedingError = true;
          }
        }
      }
    },
    checkboxChecked: function (row) {
      // clear all errors
      this.iData.forEach((element) => {
        element.fundingTypeEmptyError = "";
        element.fundingValueEmptyError = "";
      });
      this.errorMessage = "";
      this.fundingValueExceedingError = false;

      // clear and set primary Client info on checking other Client checkboxes
      var primaryClientOrderId = this.iData.find(
        (x) => x.client == this.primaryClient
      ).orderId;
      var index = this.iData.findIndex(
        (x) => x.orderId == primaryClientOrderId
      );
      var primaryClient = this.iData[index];
      if (
        this.selectedClients.length == 1 &&
        this.selectedClients.includes(this.primaryClient)
      ) {
        primaryClient["fundingType"] = 2;
        primaryClient["fundingValue"] = 100;
        primaryClient["amount"] = this.mediaPlanBudget;
      } else {
        if (primaryClient.isUpdated != true && row.isUpdated != true) {
          primaryClient["fundingType"] = 0;
          primaryClient["fundingValue"] = "";
          primaryClient["amount"] = "";
        }
      }
      this.iData.splice(index, 1, primaryClient);

      var isFixedsumValid = this.checkTotalFixedSum();
      var isPercentSumValid = this.checkTotalPercentSum();
      if (!isFixedsumValid && !isPercentSumValid) {
        this.errorMessage = "Total allocation of funds exceeds plan budget";
        this.fundingValueExceedingError = true;
      }
    }
  }
};
</script>
<style scoped>
.footer-wrapper {
  height: 3em;
}
::v-deep .ivu-modal-footer {
  padding-bottom: 8px !important;
}
::v-deep .ivu-table td {
  height: 30px !important;
}
::v-deep .ivu-table-overflowX {
  overflow-x: hidden !important;
}
::v-deep .ivu-table-cell {
  padding-left: 10px !important;
  padding-right: 10px !important;
}
.disabled-btn {
  color: #ccc;
}
.field-empty-error {
  border: red solid 1px;
}
::v-deep .ivu-select-selection {
  box-shadow: none !important;
}
.error-close-padding {
  top: 2px;
}
.error-padding {
  padding-top: 8px;
  padding-bottom: 8px;
  text-align: left;
}
::v-deep .ivu-table th {
  background-color: #f5f5f5 !important;
  height: 33px !important;
}
.header-style {
  padding-bottom: 30px !important;
}
.validate-email {
  border: red solid 1px;
}
.budget-style {
  margin-top: 12px;
  padding-left: 5px;
}
.text-style {
  font-size: 14px;
  color: grey;
}
.header-text-style {
  font-size: 14px;
  color: black;
  padding-left: 10px;
  padding-bottom: 5px;
  padding-top: 10px;
}
.label-billing-agencyUnit {
  font-size: 14px;
  color: grey;
}
.cross-channel-checkbox {
  padding-left: 7px;
  padding-top: 4px;
}
.container-height-with-table {
  height: 300px;
}
.container-height-no-switch {
  height: 100px;
}
.container-height-no-table {
  height: 130px;
}
.cofunding-text-style {
  font-size: 14px;
  color: grey;
  padding-left: 3px;
}
.padding-right {
  right: 10px;
}
.padding-left-select-option {
  text-align: left !important;
}
::v-deep .ivu-select-input {
  padding: 0px 24px 0 4px !important;
}
::v-deep .ivu-input {
  text-align: left !important;
}
::v-deep .ivu-input-small {
  text-align: left !important;
}
::v-deep .ivu-table td.read-only-class {
  background-color: #f5f5f5;
}
.overflow-visible {
  overflow: visible !important;
}
.co-funding-table-class {
  margin-top: 20px;
  height: 130px;
  overflow: auto !important;
}
.ro-confirmation-table-class {
  margin-top: 20px;
}
.estimate-modal-headers-style {
  height: 35px;
  background-color: #e5e4e2;
  border-bottom: 2px solid var(--main-bg-color);
  margin-bottom: 20px;
}
::v-deep .btn {
  margin-bottom: 10px !important;
}
.green {
  color: green;
}
.red {
  color: red;
}
.flex {
  display: flex;
  width: 100%;
}
</style>
<style>
.ivu-input::-webkit-outer-spin-button,
.ivu-input::-webkit-inner-spin-button {
  -webkit-appearance: none !important;
  margin: 0 !important;
}
</style>
<style src="../../../Styles/dx-iview.css"></style>
