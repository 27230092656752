import { Objective } from "./Objective.js"
import * as facebookEnums from "../../Constants/FacebookEnums.js"

let ColumnConfig = function () {
  this.posts = new function () {
    this.base = ["HOT_Preview Ad", "HOT_Ad Name", "HOT_Post ID", "HOT_Post Name", "HOT_StartDate", "HOT_EndDate", "HOT_Targets", "HOT_Locations", "HOT_Standard_Enhancements", "HOT_Image_Enhancements", "HOT_3d_Animation", "HOT_Music"];
    this.requiredBase = ["HOT_Ad Name", "HOT_Post ID"]
    this.card = []
    this.requiredCard = []
    this.callToAction = []
  }();
}
let CreativeSpec = function () {
  this.posts = {
    "creative": {
      "object_story_id": "HOT_Post ID",
      "instagram_actor_id": "HOT_InstagramId"
    }
  };
}

let existingPosts = new Objective(new ColumnConfig(), new CreativeSpec())

function getExistingPostConfig (config) {
  const columnConfig = new ColumnConfig();
  const creativeSpec = new CreativeSpec();

  columnConfig.posts.base = ["HOT_Preview Ad", "HOT_Ad Name", "HOT_Post ID", "HOT_Post Name", "HOT_Call To Action", "HOT_Website Url", "HOT_StartDate", "HOT_EndDate", "HOT_Targets", "HOT_Locations", "HOT_Standard_Enhancements", "HOT_Image_Enhancements", "HOT_3d_Animation", "HOT_Music"];

  if (config.campaign.objective != facebookEnums.objectives.Awareness) columnConfig.posts.requiredBase.push("HOT_Call To Action");

  switch (config.campaign.objective) {
    case facebookEnums.objectives.Awareness:
      columnConfig.posts.callToAction = ["No button", "Apply now", "Book now", "Contact us", "Download", "Send Instagram message", "Get quote", "Send message", "Get showtimes", "Send WhatsApp message", "Learn more", "Listen now", "Order now", "Request time", "See menu", "Shop now", "Sign up", "Subscribe", "Watch more"];
      break;

    case facebookEnums.objectives.Engagement:
      columnConfig.posts.callToAction = ["No button", "Book now", "Download", "Get quote", "Send message", "Get showtimes", "Send WhatsApp message", "Learn more", "Listen now", "Order now", "Shop now", "Sign up", "Subscribe", "Watch more"];
      break;

    case facebookEnums.objectives.Leads:
      columnConfig.posts.callToAction = ["No button", "Get offer", "Apply now", "Book now", "Buy tickets", "Contact us", "Download", "Get quote", "Get showtimes", "Learn more", "Listen now", "Order now", "Play game", "Request time", "See menu", "Shop now", "Sign up", "Subscribe", "Watch more"];
      break;

    case facebookEnums.objectives.Sales:
      columnConfig.posts.callToAction = ["No button", "Get offer", "Apply now", "Book now", "Buy tickets", "Contact us", "Download", "Send Instagram message", "Get quote", "Send message", "Get showtimes", "Send WhatsApp message", "Learn more", "Listen now", "Order now", "Play game", "Request time", "See menu", "Shop now", "Sign up", "Subscribe", "Watch more"];
      break;

    case facebookEnums.objectives["Traffic Outcome"]:
      columnConfig.posts.callToAction = ["No button", "Apply now", "Book now", "Buy tickets", "Contact us", "Download", "Get promotions", "Get offer", "Send Instagram message", "Get Access", "Get quote", "Get updates", "Get showtimes", "Learn more", "Listen now", "Order now", "Request time", "See menu", "Shop now", "Sign up", "Subscribe", "Watch more"];
      break;

    case facebookEnums.objectives["App Promotion"]:
      columnConfig.posts.callToAction = ["No button", "Book now", "Download", "Explore more", "Install now", "Use app", "Learn more", "Listen now", "Order now", "Play game", "Shop now", "Sign up", "Subscribe", "Watch more"];
      break;
  }

  return new Objective(columnConfig, creativeSpec);
}

export { existingPosts, getExistingPostConfig }
