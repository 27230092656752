<template>
  <div>
  </div>
</template>
<script>

// import { displayHeaderNameLookup } from "./../columnConfig.js";
import { mapState } from "vuex";
import FacebookCampaignLauncherConfig from "../Mixins/FacebookCampaignLauncherConfig.js"
import * as fbEnums from "../Constants/FacebookEnums.js";

export default {
  mixins: [FacebookCampaignLauncherConfig],
  data: function () {
    return {
      adData: {},
      globalDict: {},
      languagesDictionary: {}
    };
  },
  computed: {
    ...mapState([
      "imageIdUrlMap",
      "selectedCampaignLauncherConfig",
      "campaignLauncherConfigs",
      "selectedCampaignLauncherConfigId",
      "selectedFbObjective",
      "facebookLanguages",
      "adSheetCustomizationSettings"
    ])
  },
  methods: {
    isValidURL (url) {
      var res = url.match(/(http(s)?:\/\/.)?(www\.)?[-a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_\+.~#?&//=]*)/g);
      return (res !== null)
    },
    handsOnTableDataMapping (value, isPlacementData = false) {
      let data = JSON.parse(JSON.stringify(value));

      let headers = this.remapHeadlines(data[0])

      let filteredData = data.slice(1).map(item => item.reduce((obj, val, index) => {
        obj[headers[index]] = val
        return obj
      }, {}))
      return filteredData
    },
    remapHeadlines (headers) {
      var headlineRemap = {
        "HOT_Ad Title": "HOT_Headline",
        "HOT_Text": "HOT_Primary Text"
      };
      headers.forEach((e, i) => {
        Object.keys(headlineRemap).forEach(key => {
          if (e.includes(key)) {
            headers[i] = e.replace(key, headlineRemap[key])
          }
        })
      })
      return headers
    },
    removeDXidFromImageOrVideo (poupupDataAndMainTableData, pcMediaHeader) {
      var self = this;
      poupupDataAndMainTableData.forEach((elements) => {
        let value = elements[pcMediaHeader] ? elements[pcMediaHeader] : elements['HOT_Video'];
        if (value) {
          if (value.split('-')[0] === 'DXC') {
            if (self.imageIdUrlMap[value].hash) {
              elements['HOT_Image'] = self.imageIdUrlMap[value].hash
            } else {
              elements['HOT_Image'] = self.imageIdUrlMap[value].src
            }
          } else if (value.includes("http") || value.includes("www")) {
            elements['HOT_Image'] = value
          } else {
            elements['HOT_Video'] = self.imageIdUrlMap[value]['SearchEngineVideoId']
            elements['thumbnail_url'] = self.imageIdUrlMap[value]['src']
          }
        }
      })
      return poupupDataAndMainTableData
    },
    appendHeadersWithData (data, tableHeaders) {
      let modifiedTableHeaders = []
      modifiedTableHeaders.push(tableHeaders)
      modifiedTableHeaders.push(data)
      return modifiedTableHeaders;
    },
    putSinglePropertyData (assetFeedSpec, data) {
      if (data[0]['HOT_Call To Action']) {
        let callToAction = data[0]['HOT_Call To Action'].split(' ').map(x => x.toUpperCase()).join('_')
        assetFeedSpec.asset_feed_spec['call_to_action_types'] = [callToAction]
      }
      // App Install objective needs deep link to be set in link_urls.
      if (this.isAppInstallsObjective(this.selectedCampaignLauncherConfig) || this.selectedCampaignLauncherConfig.adset.destination == fbEnums.destinationType.App) {
        assetFeedSpec.asset_feed_spec.link_urls[0]['deeplink_url'] = data[0]['HOT_Deep Link']
      }
      // assetFeedSpec.asset_feed_spec['descriptions'] = [{ text: data[0]['HOT_Description'] ? data[0]['HOT_Description'] : "" }]
      assetFeedSpec.asset_feed_spec['ad_formats'] = (data[0]['HOT_Image']) ? ["SINGLE_IMAGE"] : ["SINGLE_VIDEO"]
      let instagramActorId = this.selectedCampaignLauncherConfig.instagramAccountId
      let pageId = this.selectedCampaignLauncherConfig.facebookPageId
      assetFeedSpec['object_story_spec'] = {
        page_id: pageId
      }
      if (instagramActorId) assetFeedSpec['object_story_spec'].instagram_actor_id = instagramActorId
      return assetFeedSpec;
    },
    getLanguageNameFromKey (key) {
      var hotName = key.split(" - ")[0];
      var language = hotName.split("_")[1].toLowerCase();
      return language;
    },
    CreateJSONForAsset (data, assetKeysInObject, rowData, referenceDict, referenceDictKey, isDefault) {
      var label = "";
      assetKeysInObject.forEach(key => {
        rowData[key] = rowData[key] || "";
        label = isDefault ? referenceDictKey + "_default" : this.getLanguageNameFromKey(key);
        var position = -1;
        if (referenceDictKey == "images_or_videos") {
          if (rowData["HOT_Image"]) {
            position = [...new Set(data.map(item => item["HOT_Image"]).filter(x => x != undefined && x != null))].indexOf(rowData["HOT_Image"]);
            referenceDict = this.CreatePlacementJSON("images", rowData, "HOT_Image", position, referenceDict, label)
          } else if (rowData["HOT_Video"]) {
            position = [...new Set(data.map(item => item["HOT_Video"]).filter(x => x != undefined && x != null))].indexOf(rowData["HOT_Video"]);
            referenceDict = this.CreatePlacementJSON("videos", rowData, "HOT_Video", position, referenceDict, label)
          }
        } else {
          position = [
            ...new Set(data.map(data1 =>
              Object.entries(data1)
                .filter(x => {
                  var columnName = x[0].split(" - ");
                  return x[0].includes(columnName[1])
                }).map(x => x[1])).flat().filter(x => x != undefined && x != null))].indexOf(rowData[key]);
          referenceDict = this.CreatePlacementJSON(referenceDictKey, rowData, key, position, referenceDict, label)
        }
      })
    },
    getAssetKeysInObject (rowData, header) {
      var keys = Object.keys(rowData).filter(x => {
        var columnName = x.split(" - ");
        return x.includes(columnName[1]) && "HOT_" + columnName[1] == header && rowData[x]
      });
      return keys;
    },
    getAssetFeedSpecJSON (filteredData, mainSheetData, mainSheetColumnName) {
      var languageIds = this.adSheetCustomizationSettings.selectedLanguages;
      languageIds.forEach(e => {
        var language = this.facebookLanguages.filter(l => {
          return l.key == e
        })
        this.languagesDictionary[language[0].name.toLowerCase()] = e;
      })
      let assetCR = [];
      filteredData = [mainSheetData];
      var headerStructure = ["HOT_Text", "HOT_Headline", "HOT_Image/Video", "HOT_Link", "HOT_Ad Title", "HOT_Description"]
      var mediaHeader = mainSheetColumnName.find(x => x.includes("Image") || x.includes("Video"))
      let data = this.removeDXidFromImageOrVideo(filteredData, mediaHeader)
      data.forEach((rowData) => {
        let referenceDict = []

        var keysInBodiesObject = this.getAssetKeysInObject(rowData, headerStructure[0]);
        this.CreateJSONForAsset(data, keysInBodiesObject, rowData, referenceDict, 'bodies');

        var keysInTitlesObject = this.getAssetKeysInObject(rowData, headerStructure[1]);
        this.CreateJSONForAsset(data, keysInTitlesObject, rowData, referenceDict, 'titles');

        var keysInImagesObject = this.getAssetKeysInObject(rowData, headerStructure[2]);
        this.CreateJSONForAsset(data, keysInImagesObject, rowData, referenceDict, 'images_or_videos');

        var keysInLinksObject = this.getAssetKeysInObject(rowData, headerStructure[3]);
        this.CreateJSONForAsset(data, keysInLinksObject, rowData, referenceDict, 'link_urls');

        var keysInDescriptionsObject = this.getAssetKeysInObject(rowData, headerStructure[5]);
        this.CreateJSONForAsset(data, keysInDescriptionsObject, rowData, referenceDict, 'descriptions');

        var languageHotColumns = [...keysInTitlesObject, ...keysInBodiesObject, ...keysInImagesObject, ...keysInLinksObject, ...keysInDescriptionsObject];
        var languages = languageHotColumns.map(e => {
          return e.split(" - ")[0].split("_")[1].toLowerCase();
        })
        var uniqueLanguages = new Set(languages);
        uniqueLanguages.forEach(e => {
          assetCR.push(
            this.GenerateAssetCustomizationRulesJSON(e, referenceDict)
          )
        })

        // default rule
        var keysInBodiesObjectDefault = Object.keys(rowData).filter(x => x.includes(headerStructure[0]));
        this.CreateJSONForAsset(data, keysInBodiesObjectDefault, rowData, referenceDict, 'bodies', true);

        var keysInTitlesObjectDefault = Object.keys(rowData).filter(x => x.includes(headerStructure[4]));
        this.CreateJSONForAsset(data, keysInTitlesObjectDefault, rowData, referenceDict, 'titles', true);

        var keysInImagesObjectDefault = Object.keys(rowData).filter(x => x.includes(headerStructure[2]));
        this.CreateJSONForAsset(data, keysInImagesObjectDefault, rowData, referenceDict, 'images_or_videos', true);

        var keysInLinksObjectDefault = Object.keys(rowData).filter(x => x.includes(headerStructure[3]));
        this.CreateJSONForAsset(data, keysInLinksObjectDefault, rowData, referenceDict, 'link_urls', true);

        var keysInDescriptionsnObjectDefault = Object.keys(rowData).filter(x => x.includes(headerStructure[5]));
        this.CreateJSONForAsset(data, keysInDescriptionsnObjectDefault, rowData, referenceDict, 'descriptions', true);

        assetCR.push(
          this.GenerateAssetCustomizationRulesJSON([], referenceDict, true)
        )
      });
      assetCR.forEach(e => {
        if (!e["is_default"]) {
          if (!e["body_label"]) {
            e["body_label"] = {
              "name": "bodies_default"
            }
          }
          if (!e["title_label"]) {
            e["title_label"] = {
              "name": "titles_default"
            }
          }
          if (!e["image_label"]) {
            e["image_label"] = {
              "name": "images_or_videos_default"
            }
          }
          if (!e["link_url_label"]) {
            e["link_url_label"] = {
              "name": "link_urls_default"
            }
          }
          if (!e["description_label"]) {
            e["description_label"] = {
              "name": "descriptions_default"
            }
          }
        }
      })
      var assetCustomizationSpec = [{ asset_customization_rules: assetCR }];
      var mainObject = {
        asset_feed_spec: Object.assign(this.adData, assetCustomizationSpec[0])
      };
      this.adData = {}
      this.globalDict = {}
      return this.putSinglePropertyData(mainObject, filteredData);
    },
    GenerateAssetCustomizationRulesJSON (language, referenceDict, isDefault) {
      isDefault = isDefault || false;
      let labelDict = {
        images: "image_label",
        bodies: "body_label",
        titles: "title_label",
        videos: "video_label",
        link_urls: "link_url_label",
        descriptions: "description_label"
      };
      let customizationSpec = {};
      referenceDict.forEach(entity => {
        if (!isDefault) {
          if (entity.value == language) {
            customizationSpec[labelDict[entity.key]] = { name: entity.value };
          }
        } else {
          customizationSpec[labelDict[entity.key]] = { name: entity.value };
        }
      });
      customizationSpec["customization_spec"] = {};

      if (isDefault) {
        customizationSpec["is_default"] = true
      } else {
        customizationSpec["customization_spec"]["locales"] = [this.languagesDictionary[language]]
      }
      return customizationSpec;
    },
    CreatePlacementJSON (referenceName, rowData, columnHeader, position, referenceDict, label) {
      if (referenceName === "bodies" || referenceName === "titles" || referenceName === "link_urls") {
        rowData[columnHeader] = rowData[columnHeader] ? rowData[columnHeader].trim() : ""
      }
      referenceDict.push({ key: referenceName, value: label })
      if (this.adData[referenceName] !== undefined) {
        if (this.globalDict[columnHeader] && (rowData[columnHeader] in this.globalDict[columnHeader])) {
          this.adData[referenceName][this.globalDict[columnHeader][rowData[columnHeader]].position]
            .adlabels
            .push(
              {
                name: label
              })
        } else if (rowData[columnHeader] == "" && this.adData[referenceName][0]) {
          this.adData[referenceName][0]
            .adlabels
            .push(
              {
                name: label
              })
        } else {
          this.adData[referenceName].push(this.getEntitySpecificJSON(rowData, columnHeader, label))
          if (!this.globalDict[columnHeader]) {
            this.globalDict[columnHeader] = {}
          }
          this.globalDict[columnHeader][rowData[columnHeader]] = {
            asset: rowData[columnHeader],
            label: label,
            position: position
          }
        }
      } else {
        this.adData[referenceName] = [
          this.getEntitySpecificJSON(rowData, columnHeader, label)
        ]
        if (!this.globalDict[columnHeader]) {
          this.globalDict[columnHeader] = {}
        }
        this.globalDict[columnHeader][rowData[columnHeader]] = {
          asset: rowData[columnHeader],
          label: label,
          position: position
        }
      }
      return referenceDict
    },
    getEntitySpecificJSON (rowData, columnHeader, label) {
      if (columnHeader.includes("- Link") || columnHeader == "HOT_Link") {
        return {
          website_url: rowData[columnHeader],
          display_url: rowData["HOT_Display Link"],
          url_tags: rowData["HOT_Url Tags"],
          adlabels: [
            {
              name: label
            }
          ]
        }
      } else if (columnHeader == "HOT_Image") {
        /* Check if hash or URl is comming under row data
          If the string is not a valid url we are sure that it is a hash.
        */
        if (!this.isValidURL(rowData[columnHeader])) {
          return {
            hash: rowData[columnHeader],
            adlabels: [
              {
                name: label
              }
            ]
          }
        } else if (this.isValidURL(rowData[columnHeader])) {
          return {
            url: rowData[columnHeader],
            adlabels: [
              {
                name: label
              }
            ]
          }
        }
        return null;
      } else if (columnHeader == "HOT_Video") {
        return {
          video_id: rowData[columnHeader],
          thumbnail_url: rowData["thumbnail_url"],
          adlabels: [
            {
              name: label
            }
          ]
        }
      } else {
        return {
          text: rowData[columnHeader] ? rowData[columnHeader].trim() : "",
          adlabels: [
            {
              name: label
            }
          ]
        }
      }
    }
  }
}
</script>
