<template>
  <div>
    <div class="alert alert-danger" v-if="showNotification" role="alert">
      {{ notificationMessage }}
      <button type="button" class="close" aria-label="Close" @click="showNotification = false; notificationMessage = '';">
        <span aria-hidden="true">&times;</span>
      </button>
    </div>
    <div class="hot-div" style="width: 100%; height: 530px; overflow: hidden;">
      <!-- <button @click="getPreviewableRowCaller(true)">Click AllRows</button> -->
      <!-- <button @click="getPreviewableRowCaller(false)">Click getPreviewAbleRows</button> -->
      <div v-if="loadingCampiagnLauncherAssets" class="overlay">
        <i class="fas fa-spinner fa-3x fa-spin dark-black"></i>
      </div>
      <hot-table class="ad-sheet-hot" ref="hot" :settings="hotSettings"></hot-table>
    </div>
  </div>
</template>

<script>
import {
  locale
} from 'iview';
import { HotTable } from "@handsontable/vue";
import Handsontable from 'handsontable'
import lang from 'iview/dist/locale/en-US';
import { adwordsEnums } from "../../Constants/AdwordsEnums.js";
import { mapState, mapMutations, mapGetters } from "vuex"
import { APIService } from "../../ApiService";

locale(lang);

export default {
  props: {
    tab: { default: '' },
    creativeSpec: { default: function () { return {} } },
    colHeaders: { default: function () { return [] } },
    displayColHeaders: { default: function () { return [] } },
    requiredCols: { default: function () { return {} } },
    callToAction: { default: function () { return [] } },
    selectedTab: { default: '' },
    generateCampaigns: { default: {} },
    generateOldCampaigns: { default: {} },
    customizedAdWordsColHeaders: { default: [] }
  },
  data: function () {
    return {
      hotSettings: {},
      adwordsAdFormats: adwordsEnums.adwordsAdFormats,
      startRows: 2,
      htData: [],
      campaignsDropDown: [],
      previewable: {},
      lastUsedIndex: 0,
      indexToIdentifierMapper: {},
      publishedIndex: [],
      bulkUpdateQueries: {},
      adType: adwordsEnums.adType,
      servedAssetFieldType: adwordsEnums.servedAssetFieldType,
      agList: [],
      keywordsMatchType: [],
      keywordsMatchTypeMapper: adwordsEnums.keywordsMatchType,
      adFormatToIdentifierNameMapper: adwordsEnums.adFormatToIdentifierNameMapper,
      showNotification: false,
      notificationMessage: '',
      negativeKeywordsIds: [],
      campaignType: adwordsEnums.campaignType,
      validImagePrefixes: ["image", "marketingImage", "squareMarketingImage", "logo", "squareLogo", "portraitMarketingImage", "Image", "landscapeLogo"],
      callToActionTypes: adwordsEnums.callToActionTypes,
      callToActionTypesForVideoAd: adwordsEnums.callToActionTypesForVideoAd,
      callToActionTypesForAssetGroups: adwordsEnums.callToActionTypesForAssetGroups,
      existingSettings: {},
      alreadyProcessed: {},
      storeAssetRegex: /^{{store\.asset([1-9]|[1-4]\d|50)}}$/
    }
  },
  components: {
    "hot-table": HotTable
  },
  computed: {
    ...mapState([
      "adgroups",
      "adGroupCampaignMapper",
      "publishedCreatives",
      "publishedSheetCreatives",
      "selectedCampaignLauncherConfig",
      "selectedCampaignLauncherConfigId",
      "adGroupNameToIdentifierMapper",
      "negativeKeywords",
      "conversionAction",
      "builtImageIdUrlMap",
      "allCreativesInfo",
      "imageIdUrlMap",
      "adwordsAdSheetSettingsInfo",
      "allAdExtensions",
      "loadingCampiagnLauncherAssets",
      "mediaPlanLineItems",
      "isPromotionMode"
    ]),
    ...mapGetters([
      "isLivePromotion"
    ]),
    isPerformanceMaxCampaign () {
      return this.selectedCampaignLauncherConfig.campaign.type == this.campaignType['Performance Max'];
    },
    isSearchCampaign () {
      return this.selectedCampaignLauncherConfig.campaign.type == this.campaignType['Search'];
    },
    createAdsOnly () {
      return this.isPerformanceMaxCampaign || this.isSearchCampaign;
    }
  },
  created () {
    this.campaignsDropDown = Object.keys(this.generateCampaigns)
    this.hotSettings = this.buildHotSettings();
    this.existingSettings = this.hotSettings;
    this.keywordsMatchType = Object.keys(this.keywordsMatchTypeMapper);
  },
  watch: {
    allAdExtensions: {
      handler: function (newVal, oldVal) {
        if (newVal && newVal.length > 0 && oldVal && oldVal.length == 0) {
          this.rerender();
        }
      }
    },
    publishedCreatives: {
      immediate: true,
      handler: function () {
        if (this.publishedCreatives[this.tab]) {
          this.publishedCreatives[this.tab].forEach((element, index) => {
            if (element && !element["HOT_IsNotPublished"]) {
              this.publishedIndex.push(index);
            }
          });
        }
        this.previewable = {};
        this.publishedRows = [];
        this.htData = [];
        this.lastUsedIndex = 0;
        if (this.publishedCreatives[this.tab] && this.createAdsOnly && !this.isPromotionMode) {
          this.lastUsedIndex = this.allCreativesInfo[this.tab];
          this.indexToIdentifierMapper = {}
        }
        if (this.publishedCreatives[this.tab] && this.publishedCreatives[this.tab].length) {
          let publishedRowObjects = JSON.parse(JSON.stringify(this.publishedCreatives[this.tab]));
          publishedRowObjects.forEach((rowObject, rowIndex) => {
            this.lastUsedIndex++;
            if (rowObject && rowObject.Identifier) {
              this.indexToIdentifierMapper[rowIndex] = rowObject.Identifier;
            }
            let htRow = [];
            this.colHeaders.forEach(header => {
              if (header in rowObject) {
                htRow.push(rowObject[header])
              } else {
                htRow.push(null)
              }
            });
            this.htData.push(htRow);
            htRow.forEach((cellData, colIndex) => {
              this.updatePreviewColumn(rowIndex, colIndex, null, cellData, null);
            });
          });
          this.publishedRows = JSON.parse(JSON.stringify(this.htData));
        } else {
          for (let i = 0; i < this.startRows; i++) {
            this.htData.push(Array(this.colHeaders.length).fill(null))
          }
        }
        this.$nextTick(() => {
          let updatedSettings = {
            data: this.htData
          }
          this.$refs['hot'].hotInstance.updateSettings(updatedSettings);
          if (this.isPerformanceMaxCampaign || this.isSearchCampaign) {
            this.existingSettings = this.buildHotSettings();
          }
          this.$refs['hot'].hotInstance.render();
        })
      }
    },
    negativeKeywords: {
      // we need to show invalid negative keywords as an alert instead of showing it as a message
      // we cannot use any hooks because we would not have fetched negativeKeywords by then
      immediate: true,
      handler: function (newVal) {
        let self = this;
        if (this.tab == "adGroups" && this.selectedCampaignLauncherConfig && this.selectedCampaignLauncherConfig.campaign.type == this.campaignType.Search) {
          self.negativeKeywordsIds = newVal.map(e => e.id);
          self.validateNegativeKeywords();
        }
      }
    },
    adGroupNameToIdentifierMapper: {
      immediate: true,
      handler: function (newVal) {
        this.agList = Object.entries(newVal).map(e => e[0]);
      }
    },
    customizedAdWordsColHeaders: {
      immediate: true,
      deep: true,
      handler: function (newVal) {
        let newColHeaders = this.colHeaders;
        let newDisplayColHeaders = this.displayColHeaders;
        var self = this;
        let updatedSettings = {
          data: self.htData,
          colHeaders: function (index) {
            var header = newColHeaders[index];
            var displayName = newDisplayColHeaders[index];
            var displayText = displayName;
            if (self.requiredCols.base.has(header)) {
              displayText += "<span style='color:#b94a48'>*</span>";
            }
            if (header.includes('headline') && self.selectedCampaignLauncherConfig.campaign.type == self.campaignType['Demand Gen']) {
              displayText += "&nbsp<i class='fa fa-info-circle' title='The maximum length is 40 characters'></i>"
            } else if (header.includes('headline')) {
              displayText += "&nbsp<i class='fa fa-info-circle' title='The maximum length is 30 characters'></i>"
            }
            if (header.includes('businessName')) {
              displayText += "&nbsp<i class='fa fa-info-circle' title='The maximum length is 25 characters'></i>"
            }
            if (header.includes('shortDescription')) {
              displayText += "&nbsp<i class='fa fa-info-circle' title='The maximum length is 60 characters'></i>"
            }
            if (header.includes('description') || header.includes('longHeadline')) {
              displayText += "&nbsp<i class='fa fa-info-circle' title='The maximum length is 90 characters'></i>"
            }
            if (header.includes('logo') && this.selectedTab != 'assetGroups') {
              displayText += "&nbsp<i class='fa fa-info-circle' title='The minimum size is 512x128 and the aspect ratio must be 4:1 (+-1%)'></i>"
            } else if (header.includes('logo') || header.includes('squareLogo')) {
              displayText += "&nbsp<i class='fa fa-info-circle' title='The minimum size is 128x128 and the aspect ratio must be 1:1 (+-1%)'></i>"
            }
            if (header.includes('image')) {
              displayText += "&nbsp<i class='fa fa-info-circle' title='The aspect ratio must be 1.91:1 or 1:1 or 4:5'></i>"
            }
            if (header.toLowerCase().includes('portraitmarketingimage')) {
              displayText += "&nbsp<i class='fa fa-info-circle' title='The minimum size is 480x600 and the aspect ratio must be 4:5'></i>"
            } else if (header.toLowerCase().includes('squaremarketingimage')) {
              displayText += "&nbsp<i class='fa fa-info-circle' title='The minimum size is 300x300 and the aspect ratio must be 1:1 (+-1%)'></i>"
            } else if (header.toLowerCase().includes('marketingimage')) {
              displayText += "&nbsp<i class='fa fa-info-circle' title='The minimum size is 600x314 and the aspect ratio must be 1.91:1 (+-1%)'></i>"
            } else if (header.toLowerCase().includes('landscapelogo')) {
              displayText += "&nbsp<i class='fa fa-info-circle' title='The minimum size is 512x128 and the aspect ratio must be 4:1'></i>"
            }
            if (header.includes('video')) {
              displayText += "&nbsp<i class='fa fa-info-circle' title='Youtube Video URL'></i>"
            }
            if (header.includes('youtubeVideo')) {
              displayText += "&nbsp<i class='fa fa-info-circle' title='Aspect ratio of horizontal (16:9), square (1:1), or vertical (9:16); and greater than or equal to 10 seconds in duration'></i>"
            }
            return displayText;
          },
          renderAllRows: true,
          columns: new Array(newColHeaders.length).fill().map((_, index) => {
            if (index >= newColHeaders.length) { return }
            let settings = {};
            if (self.colHeaders[index].indexOf("campaign") != -1) {
              if (self.isAdFormatTab(self.tab)) {
                settings.readOnly = true;
                settings.className = 'disabled-cell-text';
                settings.type = 'text';
                settings.copyable = false;
              } else {
                settings.type = 'dropdown';
                settings.allowInvalid = false;
              }
              settings.source = self.campaignsDropDown;
            }
            if (self.colHeaders[index].indexOf("adgroup") != -1) {
              if (self.isAdFormatTab(self.tab)) {
                settings.type = 'dropdown';
                settings.source = this.getStates;
                settings.allowInvalid = false;
              }
            }
            if (self.colHeaders[index].indexOf("finalUrl") != -1 || self.colHeaders[index].indexOf("appUrl") != -1 || self.colHeaders[index].indexOf("verificationUrl") != -1) {
              if (self.isPromotionMode) {
                settings.validator = this.validatePromoFinalUrl;
                settings.allowInvalid = false;
              } else {
                settings.validator = this.validateFinalUrl;
                settings.allowInvalid = false;
              }
            }
            if (self.colHeaders[index].indexOf("headline") != -1) {
              if (self.selectedCampaignLauncherConfig.campaign.type == self.campaignType['Demand Gen']) {
                settings.validator = this.validateHeadlineForDemandGen;
              } else if (self.selectedCampaignLauncherConfig.campaign.type == self.campaignType['Search'] && self.tab == 'responsiveAds') {
                settings.validator = this.validateHeadlineForRSA;
              } else {
                settings.validator = this.validateHeadline;
              }
              settings.allowInvalid = false;
            }
            if (self.colHeaders[index].indexOf("longHeadline") != -1) {
              settings.validator = this.validateLongHeadline;
              settings.allowInvalid = false;
            }
            if (self.colHeaders[index].indexOf("description") != -1) {
              if (self.selectedCampaignLauncherConfig.campaign.type == self.campaignType['Search'] && self.tab == 'responsiveAds') {
                settings.validator = this.validateDescriptionForRSA;
              } else {
                settings.validator = this.validateDescription;
              }
              settings.allowInvalid = false;
            }
            if (self.colHeaders[index].indexOf("shortDescription") != -1) {
              settings.validator = this.validateShortDescription;
              settings.allowInvalid = false;
            }
            if (self.colHeaders[index].indexOf("businessName") != -1) {
              settings.validator = this.validateBusinessName;
              settings.allowInvalid = false;
            }
            if (self.colHeaders[index].startsWith("youtubeVideo")) {
              if (self.isPromotionMode) {
                settings.validator = this.validateYoutubePromoAssets;
                settings.allowInvalid = false;
              } else {
                settings.validator = this.validateYoutubeUrl;
                settings.allowInvalid = false;
              }
            }
            if (index == 0 && newColHeaders[index].indexOf("valid") != -1) {
              settings.readOnly = true;
              settings.copyable = false;
              settings.editor = false;
              settings.renderer = this.previewBtnRenderer;
            }
            if (self.colHeaders[index].indexOf("lineItem") != -1) {
              settings.renderer = this.lineItemNameRenderer;
            }
            if (this.validImagePrefixes.some(prefix => newColHeaders[index].indexOf(prefix) !== -1) && !deltax.isHotTableRendererDisabled) {
              settings.renderer = this.imageColRenderer;
            }
            if (self.colHeaders[index].indexOf("adExtensions") != -1) {
              if (self.tab == 'adGroups' || self.tab == 'assetGroups') {
                if (this.isPromotionMode) {
                  settings.validator = this.validatePromoAdExtensions;
                  settings.allowInvalid = false;
                } else {
                  settings.validator = this.validateAdExtensions;
                  settings.allowInvalid = false;
                  settings.renderer = this.adExtensionRenderer;
                }
              }
            }
            if (self.colHeaders[index].toLowerCase().includes("calltoaction")) {
              if (self.tab == 'discoveryVideoAds') {
                settings.source = this.callToActionTypesForVideoAd;
              } else if (self.tab == 'assetGroups') {
                settings.source = this.callToActionTypesForAssetGroups;
              } else {
                settings.source = this.callToActionTypes;
              }
              settings.type = 'dropdown';
              settings.allowInvalid = false;
            }
            return settings;
          })
        }
        if (this.$refs['hot'] && this.$refs['hot'].hotInstance) {
          this.existingSettings = updatedSettings;
          this.$refs['hot'].hotInstance.updateSettings(updatedSettings);
          this.$refs['hot'].hotInstance.render();
        }
        this.rerender();
      }
    },
    builtImageIdUrlMap () {
      if (this.builtImageIdUrlMap) {
        this.rerender()
      }
    },
    generateCampaigns: {
      immediate: true,
      handler: function (newVal) {
        this.campaignsDropDown = Object.keys(newVal);
        var self = this;
        if (this.$refs['hot'] && this.$refs['hot'].hotInstance) {
          this.$refs['hot'].hotInstance.updateSettings({
            ...self.existingSettings,
            columns: function (index) {
              if (index >= self.colHeaders.length) { return }
              let settings = {};
              if (self.colHeaders[index].indexOf("campaign") != -1) {
                if (self.tab != 'adGroups') {
                  settings.readOnly = true;
                  settings.className = 'disabled-cell-text';
                  settings.type = 'text';
                  settings.copyable = false;
                } else {
                  settings.type = 'dropdown';
                  settings.allowInvalid = false;
                  settings.source = self.campaignsDropDown;
                }
              } else {
                settings = Array.isArray(self.existingSettings.columns) ? self.existingSettings.columns[index] : self.existingSettings.columns(index);
              }
              return settings;
            }
          });
          this.$refs['hot'].hotInstance.render();
        }
      }
    }
  },
  methods: {
    ...mapMutations([
      "setAdGroupCampaignMapper",
      "setAdGroupMapper",
      "set_adExtensions",
      "set_loadingCampiagnLauncherAssets"
    ]),
    validateNegativeKeywords (rowsToValidate = []) {
      let self = this;
      let hotRefs = self.$refs['hot'];
      if (hotRefs) {
        let instance = hotRefs.hotInstance;
        var gridData = instance.getData();
        let invalidNegativeKeywordList = [];
        if (!rowsToValidate.length) {
          gridData.forEach((rowData, index) => {
            if (!instance.isEmptyRow(index)) {
              self.evaluateAndDumpToInvalidNegativeKeyword(rowData, invalidNegativeKeywordList);
            }
          });
        } else {
          rowsToValidate.forEach(index => {
            if (!instance.isEmptyRow(index)) {
              let rowData = gridData[index];
              self.evaluateAndDumpToInvalidNegativeKeyword(rowData, invalidNegativeKeywordList);
            }
          });
        }
        if (invalidNegativeKeywordList.length > 0) {
          const invalidData = [...new Set(invalidNegativeKeywordList)].join(', ');
          self.notificationMessage = `Invalid Negative Keywords: ${invalidData}`;
          self.showNotification = true;
        }
      }
    },
    evaluateAndDumpToInvalidNegativeKeyword (rowData, invalidNegativeKeywordList) {
      // 4th column refers to negative keywords index
      let self = this;
      if (rowData[4] && rowData[0] == 'true') {
        const val = rowData[4];
        const getList = val.split(',').map(e => e.trim());
        getList.forEach((eachId) => {
          if (!self.negativeKeywordsIds.some(originalId => originalId == eachId)) {
            invalidNegativeKeywordList.push(eachId);
          }
        });
      }
    },
    getPreviewableRowCaller (isAllRows) {
      let getRows = isAllRows ? this.getAllRows() : this.getPreviewableRows();
      console.log(getRows);
    },
    getStates (query, callback) {
      const states = this.agList;
      callback(states);
    },
    previewBtnRenderer (instance, td, row, col, prop, value, cellProperties) {
      let rowInfo = instance.getDataAtRow(row);
      let isFlagSet = rowInfo.length ? rowInfo[col] == 'true' : false;
      isFlagSet = this.publishedIndex.includes(row) ? true : isFlagSet;
      td.innerHTML = '';
      let previewTdWrapper = document.createElement('div');
      previewTdWrapper.classList.add('preview-td-wrapper');
      let previewIcon = document.createElement('span');
      previewIcon.classList.add('eye', 'preivew-icon-span');
      previewIcon.innerHTML = '<i class="fas fa-times-circle"></i>';
      previewTdWrapper.appendChild(previewIcon);
      td.appendChild(previewTdWrapper);
      td.firstElementChild.firstElementChild.innerHTML = isFlagSet ? '<i class="fa fa-check"></i>' : '<i class="fas fa-times"></i>';
      return td;
    },
    buildHotSettings () {
      var self = this;
      return {
        data: self.htData,
        startCols: self.colHeaders.length,
        startRows: this.startRows,
        minSpareRows: 100,
        manualColumnResize: true,
        autoRowSize: true,
        autoColumnSize: true,
        copyPaste: {
          rowsLimit: 5000
        },
        viewportColumnRenderingOffset: this.colHeaders.length,
        colWidths: function (index) {
          if (self.colHeaders[index] && self.colHeaders[index].indexOf("valid") != -1) {
            return 100;
          }
          if (self.colHeaders[index] && self.colHeaders[index].indexOf("card") != -1) {
            return 215;
          }
          return 200;
        },
        colHeaders: function (index) {
          var header = self.colHeaders[index];
          var displayName = self.displayColHeaders[index];
          var displayText = displayName;
          if (self.requiredCols.base.has(header)) {
            displayText += "<span style='color:#b94a48'>*</span>";
          }
          if (header.includes('headline') && self.selectedCampaignLauncherConfig.campaign.type == self.campaignType['Demand Gen']) {
            displayText += "&nbsp<i class='fa fa-info-circle' title='The maximum length is 40 characters'></i>"
          } else if (header.includes('headline')) {
            displayText += "&nbsp<i class='fa fa-info-circle' title='The maximum length is 30 characters'></i>"
          }
          if (header.includes('businessName')) {
            displayText += "&nbsp<i class='fa fa-info-circle' title='The maximum length is 25 characters'></i>"
          }
          if (header.includes('shortDescription')) {
            displayText += "&nbsp<i class='fa fa-info-circle' title='The maximum length is 60 characters'></i>"
          }
          if (header.includes('description') || header.includes('longHeadline')) {
            displayText += "&nbsp<i class='fa fa-info-circle' title='The maximum length is 90 characters'></i>"
          }
          if (header.includes('logo') && self.selectedCampaignLauncherConfig.campaign.type != self.campaignType['Performance Max'] && self.selectedCampaignLauncherConfig.campaign.type != self.campaignType['Demand Gen']) {
            displayText += "&nbsp<i class='fa fa-info-circle' title='The minimum size is 512x128 and the aspect ratio must be 4:1 (+-1%)'></i>"
          } else if (header.includes('logo') || header.includes('squareLogo')) {
            displayText += "&nbsp<i class='fa fa-info-circle' title='The minimum size is 128x128 and the aspect ratio must be 1:1 (+-1%)'></i>"
          }
          if (header.includes('image')) {
            displayText += "&nbsp<i class='fa fa-info-circle' title='The aspect ratio must be 1.91:1 or 1:1 or 4:5'></i>"
          }
          if (header.toLowerCase().includes('portraitmarketingimage')) {
            displayText += "&nbsp<i class='fa fa-info-circle' title='The minimum size is 480x600 and the aspect ratio must be 4:5'></i>"
          } else if (header.toLowerCase().includes('squaremarketingimage')) {
            displayText += "&nbsp<i class='fa fa-info-circle' title='The minimum size is 300x300 and the aspect ratio must be 1:1 (+-1%)'></i>"
          } else if (header.toLowerCase().includes('marketingimage')) {
            displayText += "&nbsp<i class='fa fa-info-circle' title='The minimum size is 600x314 and the aspect ratio must be 1.91:1 (+-1%)'></i>"
          } else if (header.toLowerCase().includes('landscapelogo')) {
            displayText += "&nbsp<i class='fa fa-info-circle' title='The minimum size is 512x128 and the aspect ratio must be 4:1'></i>"
          }
          if (header.includes('video')) {
            displayText += "&nbsp<i class='fa fa-info-circle' title='Youtube Video URL'></i>"
          }
          if (header.includes('youtubeVideo')) {
            displayText += "&nbsp<i class='fa fa-info-circle' title='Aspect ratio of horizontal (16:9), square (1:1), or vertical (9:16); and greater than or equal to 10 seconds in duration'></i>"
          }
          return displayText;
        },
        renderAllRows: true,
        rowHeights: 74,
        columns: (index) => {
          var self = this;
          if (index >= self.colHeaders.length) { return }
          let settings = {};
          if (self.colHeaders[index].indexOf("campaign") != -1) {
            if (self.tab != 'adGroups') {
              settings.readOnly = true;
              settings.className = 'disabled-cell-text';
              settings.type = 'text';
              settings.copyable = false;
            } else {
              settings.type = 'dropdown';
              settings.allowInvalid = false;
            }
            settings.source = self.campaignsDropDown;
          } else if (self.colHeaders[index].indexOf("adgroup") != -1) {
            if (self.tab != 'adGroups') {
              settings.type = 'dropdown';
              settings.source = this.getStates;
              settings.allowInvalid = false;
            }
          } else if (self.colHeaders[index].toLowerCase().includes("calltoaction")) {
            if (self.tab == 'discoveryVideoAds') {
              settings.source = this.callToActionTypesForVideoAd;
            } else if (self.tab == 'assetGroups') {
              settings.source = this.callToActionTypesForAssetGroups;
            } else {
              settings.source = this.callToActionTypes;
            }
            settings.type = 'dropdown';
            settings.allowInvalid = false;
          } else if (self.colHeaders[index].indexOf("finalUrls") != -1) {
            settings.validator = this.validateFinalUrls;
            settings.allowInvalid = false;
          } else if (self.colHeaders[index].indexOf("finalUrl") != -1 || self.colHeaders[index].indexOf("appUrl") != -1 || self.colHeaders[index].indexOf("verificationUrl") != -1) {
            if (self.isPromotionMode) {
              settings.validator = this.validatePromoFinalUrl;
              settings.allowInvalid = false;
            } else {
              settings.validator = this.validateFinalUrl;
              settings.allowInvalid = false;
            }
          } else if (self.colHeaders[index].startsWith("youtubeVideo")) {
            if (self.isPromotionMode) {
              settings.validator = this.validateYoutubePromoAssets;
              settings.allowInvalid = false;
            } else {
              settings.validator = this.validateYoutubeUrl;
              settings.allowInvalid = false;
            }
          } else if (self.colHeaders[index].indexOf("displayPath") != -1) {
            settings.validator = this.validateDisplayPaths;
            settings.allowInvalid = false;
          } else if (self.colHeaders[index].indexOf("headline") != -1) {
            if (self.selectedCampaignLauncherConfig.campaign.type == self.campaignType['Demand Gen']) {
              settings.validator = this.validateHeadlineForDemandGen;
            } else if (self.selectedCampaignLauncherConfig.campaign.type == self.campaignType['Search'] && self.tab == 'responsiveAds') {
              settings.validator = this.validateHeadlineForRSA;
            } else {
              settings.validator = this.validateHeadline;
            }
            settings.allowInvalid = false;
          } else if (self.colHeaders[index].indexOf("longHeadline") != -1) {
            settings.validator = this.validateLongHeadline;
            settings.allowInvalid = false;
          } else if (self.colHeaders[index].indexOf("businessName") != -1) {
            settings.validator = this.validateBusinessName;
            settings.allowInvalid = false;
          } else if (self.colHeaders[index].indexOf("video") != -1) {
            settings.validator = this.validateYoutubeUrl;
            settings.allowInvalid = false;
          } else if (self.colHeaders[index].indexOf("shortDescription") != -1) {
            settings.validator = this.validateShortDescription;
            settings.allowInvalid = false;
          } else if (self.colHeaders[index].indexOf("description") != -1) {
            if (self.selectedCampaignLauncherConfig.campaign.type == self.campaignType['Search'] && self.tab == 'responsiveAds') {
              settings.validator = this.validateDescriptionForRSA;
            } else {
              settings.validator = this.validateDescription;
            }
            settings.allowInvalid = false;
          } else if (self.colHeaders[index].indexOf("matchType") != -1) {
            if (self.tab == "keywords") {
              settings.readOnly = true;
              settings.className = 'disabled-cell-text';
              settings.type = 'dropdown';
              settings.copyable = false;
              settings.source = this.keywordsMatchType;
            }
          } else if (
            self.colHeaders[index].indexOf("hPosition") != -1 || self.colHeaders[index].indexOf("dPosition2") != -1) {
            if (self.tab == "responsiveAds") {
              settings.type = "text";
              settings.validator = this.validatePinedPositions;
              settings.allowInvalid = false;
            }
          } else if (
            self.colHeaders[index].indexOf("descriptionPosition1") != -1 ||
            self.colHeaders[index].indexOf("descriptionPosition2") != -1
          ) {
            if (self.tab == "responsiveAds") {
              settings.type = "text";
            }
          } else if (index == 0 && self.colHeaders[index].indexOf("valid") != -1) {
            settings.readOnly = true;
            settings.copyable = false;
            settings.editor = false;
            settings.renderer = this.previewBtnRenderer;
          } else if (self.colHeaders[index].indexOf("adExtensions") != -1) {
            if (self.tab == 'adGroups' || self.tab == 'assetGroups') {
              if (this.isPromotionMode) {
                settings.validator = this.validatePromoAdExtensions;
                settings.allowInvalid = false;
              } else {
                settings.validator = this.validateAdExtensions;
                settings.allowInvalid = false;
                settings.renderer = this.adExtensionRenderer;
              }
            }
          } else if (self.colHeaders[index].indexOf("conversionAction") != -1) {
            if (self.tab == "callAds") {
              settings.type = 'dropdown';
              settings.allowInvalid = false;
              settings.source = this.conversionAction.map(x => x.name);
            }
          } else if (this.colHeaders[index].indexOf("lineItem") != -1) {
            settings.renderer = this.lineItemNameRenderer;
          }
          if (this.isLivePromotion) {
            settings.readOnly = true;
            settings.className = 'post-cell';
          }
          if (this.validImagePrefixes.some(prefix => this.colHeaders[index].indexOf(prefix) !== -1) && !deltax.isHotTableRendererDisabled) {
            settings.renderer = this.imageColRenderer;
          }
          return settings;
        },
        cells: (row, col, prop) => {
          let cellProperties = {}
          if (this.isLivePromotion && self.htData[row].some((ele) => ele != null)) {
            cellProperties.className = 'disabled-cell-text'
          }
          if (this.publishedCreatives[this.tab] && this.publishedCreatives[this.tab][row] &&
           !this.publishedCreatives[this.tab][row].HOT_IsNotPublished) {
            cellProperties.readOnly = 'true'
            cellProperties.className = 'disabled-cell-text'
          }
          return cellProperties
        },
        beforeChange: (changes, source) => {
          let self = this;
          let instance = this.$refs['hot'].hotInstance;
          this.bulkUpdateQueries = {};
          let rowsToValidateNegativeKeywords = [];
          let isAnyRowPeviewable = false;

          // stores the valid column value with respect to the row.
          let previewBtnRowMapper = {};

          for (let index = 0; index < changes.length; index++) {
            let change = changes[index];
            let [row, col, oldVal, newVal] = change;
            if (col >= this.colHeaders.length) {
              return;
            }
            if (self.tab == "keywords" && self.colHeaders[col] == "keyword") {
              if (newVal && newVal.startsWith('"') && newVal.endsWith('"')) {
                self.setHotCellData(row, col + 1, 'Phrase', instance, true);
              } else if (newVal && newVal.startsWith('[') && newVal.endsWith(']')) {
                self.setHotCellData(row, col + 1, 'Exact', instance, true);
              } else if (newVal) {
                self.setHotCellData(row, col + 1, 'Broad', instance, true);
              } else {
                self.setHotCellData(row, col + 1, '', instance, true);
              }
            }
            if (((self.tab == "assetGroups" || self.tab == "appAds" || self.tab == "appEngagementAds") || self.tab == "responsiveDisplayAds" || self.tab == "discoveryCarouselAds" || self.tab == "discoveryImageAds") && !self.publishedIndex.includes(row)) {
              let columnName = this.colHeaders[col];
              if (this.validImagePrefixes.some(prefix => columnName.includes(prefix))) {
                let cellProperties = {};
                let td = instance.getCell(row, col);
                if (newVal && !(newVal in this.imageIdUrlMap)) {
                  if (newVal.includes("http") || newVal.includes("www") || self.storeAssetRegex.test(newVal)) {
                    // process image urls if rendered enabled
                    if (!deltax.isHotTableRendererDisabled) {
                      if (this.alreadyProcessed[`${row}-${col}`]) {
                        this.alreadyProcessed[`${row}-${col}`] = false;
                      } else {
                        changes[index] = null;
                        this.processImageUrl(row, col, oldVal, newVal, instance);
                        this.imageColRenderer(instance, td, row, col, columnName, newVal, cellProperties);
                        continue
                      }
                    }
                  } else {
                    this.$Message.error({
                      background: true,
                      content: "Please select an Image"
                    })
                    changes[index] = null;
                    continue;
                  }
                } else if (td && !deltax.isHotTableRendererDisabled) {
                  // handle rendering based on feature flag
                  this.imageColRenderer(instance, td, row, col, columnName, newVal, cellProperties);
                }
                if (newVal && !deltax.isHotTableRendererDisabled) {
                  instance.render();
                }
              }
              if (newVal && this.colHeaders[col].indexOf("lineItem") != -1) {
                let cellProperties = {};
                let td = instance.getCell(row, col);
                var lineItems = newVal.split(",");
                var isInvalidLineItem = false;
                lineItems.forEach(lineItem => {
                  let enteredLineItem = newVal.split("-").pop();
                  if (!this.mediaPlanLineItems.some(obj => obj.id == enteredLineItem)) {
                    isInvalidLineItem = true;
                  };
                })
                changes[index][3] = [...new Set(newVal.split(','))].join(",");
                if (isInvalidLineItem) {
                  this.$Message.error({
                    background: true,
                    content: "Line Item not found."
                  })
                  changes[index] = null;
                  continue;
                } else if (td) {
                  this.lineItemNameRenderer(instance, td, row, col, columnName, newVal, cellProperties);
                }
                // instance.render();
              }
            }
            // return valid column value
            let validRow = self.updatePreviewColumn(row, col, oldVal, newVal, instance, true);
            if (validRow && validRow.length) {
              previewBtnRowMapper[row] = validRow;
            }

            if (self.tab == "adGroups" && !self.publishedIndex.includes(row) && self.checkIfPreviewable(self.previewable[row])) {
              isAnyRowPeviewable = true;
            }
          }
          // update the changes array with the value from mapper.// update the changes array with the value from mapper.
          for (let [, value] of Object.entries(previewBtnRowMapper)) {
            if (value) {
              const [row, col] = value.slice(0, 2);
              // handles the case for images with direct URLs. In that case, changes are set null.
              for (let i = changes.length - 1; i >= 0; i--) {
                if (!changes[i]) {
                  changes.splice(i, 1);
                }
              }
              const existingIndex = changes.findIndex(([r, c]) => r === row && c === col);
              if (existingIndex < 0) {
                changes.push(value);
              } else {
                changes[existingIndex] = value;
              }
            }
          }

          let bulkUpdatesArray = Object.values(this.bulkUpdateQueries);
          setTimeout(() => {
            instance.setDataAtRowProp(bulkUpdatesArray);
            if (isAnyRowPeviewable) {
              for (let [row, previewRow] of Object.entries(this.previewable)) {
                if (!self.publishedIndex.includes(Number(row)) && self.checkIfPreviewable(self.previewable[row])) {
                  rowsToValidateNegativeKeywords.push(row);
                }
              }
            }
            if (rowsToValidateNegativeKeywords.length > 0) {
              this.validateNegativeKeywords(rowsToValidateNegativeKeywords);
            }
          })
        },
        afterChange: (changes, source) => {
          if (!changes) {
            return null;
          }
          let self = this;
          let adExtensionIds = [];
          let instance = this.$refs['hot'].hotInstance;
          for (let index = 0; index < changes.length; index++) {
            let change = changes[index];
            let [, col, , newVal] = change;
            if (col >= this.colHeaders.length) {
              return;
            }
            if (newVal && (self.tab == "adGroups" || self.tab == "assetGroups") && self.colHeaders[col] == "adExtensions") {
              var enteredAdExtensions = newVal.split(",");
              enteredAdExtensions.forEach(enteredAdExtension => {
                if (enteredAdExtension.split("-").length == 3) {
                  let adExtensionId = enteredAdExtension.split("-").pop();
                  adExtensionIds.push(adExtensionId);
                }
              });
            }
          }
          adExtensionIds = [...new Set(adExtensionIds)];
          let allAdExtensionIds = self.allAdExtensions.map(adExtension => adExtension.id)
          adExtensionIds = adExtensionIds.filter(adExtensionId => !allAdExtensionIds.includes(parseInt(adExtensionId)))
          if (adExtensionIds.length > 0) {
            self.$store.commit("set_loadingCampiagnLauncherAssets", true);
            APIService.getAdExtensions(self.selectedCampaignLauncherConfig.bpseId, null, adExtensionIds.join(","))
              .then(response => {
                let updatedAllExtensions = [...self.allAdExtensions, ...response.data]
                self.set_adExtensions(updatedAllExtensions);
                let fetchedAdExtensionIds = updatedAllExtensions.map(adExtension => adExtension.id)
                let unfetchedAdExtensions = enteredAdExtensions.filter(adExtension => !fetchedAdExtensionIds.includes(parseInt(adExtension.split("-").pop())))
                self.$store.commit("set_loadingCampiagnLauncherAssets", false);
                if (unfetchedAdExtensions.length > 0) {
                  self.$store.commit("set_showCampaignLauncherError", true);
                  self.$store.commit(
                    "set_campaignLauncherAssetsWithError",
                    unfetchedAdExtensions
                  );
                  for (let index = 0; index < changes.length; index++) {
                    let change = changes[index];
                    let [row, col, , newVal] = change;
                    if (col >= this.colHeaders.length) {
                      return;
                    }
                    if (self.tab == "adGroups" && self.colHeaders[col] == "adExtensions") {
                      let currentAdExtensions = newVal.split(",");
                      let hasCommonElement = currentAdExtensions.some(adExtension => unfetchedAdExtensions.includes(adExtension));
                      if (hasCommonElement) {
                        self.setHotCellData(row, col, null, instance, true);
                      }
                    }
                  }
                } else {
                  self.$store.commit("set_showCampaignLauncherError", false);
                  self.$store.commit(
                    "set_campaignLauncherAssetsWithError",
                    []
                  );
                }
                self.rerender();
              })
              .catch(() => {
                self.showNotification = true;
                self.notificationClass = "alert alert-danger"
                self.notificationMessage = "There was a problem while fetching the AdExtensions"
              })
          }
        }
      }
    },
    updatePreviewColumn (row, col, oldVal, newVal, instance, lazyUpdate = false) {
      if (!(row in this.previewable)) {
        this.previewable[row] = this.createNewPreviewableObj()
      }
      let [required, cardIndex] = this.checkIfReq(col)
      if (required) {
        if (!oldVal && newVal) {
          if (cardIndex != -1) {
            this.previewable[row]['card'][cardIndex] += 1
          } else {
            this.previewable[row]['base'] += 1
          }
        } else if (oldVal && !newVal) {
          if (cardIndex != -1) {
            this.previewable[row]['card'][cardIndex] -= 1
          } else {
            this.previewable[row]['base'] -= 1
          }
        }
        if (instance) {
          const isPreviewable = this.checkIfPreviewable(this.previewable[row]);
          if (this.tab != "adGroups" && this.tab != "assetGroups" && col == 2) {
            if (newVal) this.setHotCellData(row, 1, this.adGroupCampaignMapper[newVal], instance, lazyUpdate);
            else this.setHotCellData(row, 1, null, instance, lazyUpdate);
          }
          // returns the value for preview column
          if (this.htData[row]) {
            return [row, 0, this.htData[row][0], isPreviewable.toString()]
          } else {
            return [row, 0, 'false', isPreviewable.toString()]
          }
        }
      }

      // handles undo and redo cases. In undo and redo true/false values are passed into updatePreview method.
      if (col == 0) {
        if (instance) {
          const isPreviewableRow = this.checkIfPreviewable(this.previewable[row]);
          if (this.htData[row]) {
            return [row, 0, this.htData[row][0], isPreviewableRow.toString()]
          } else {
            return [row, 0, 'false', isPreviewableRow.toString()]
          }
        }
      }
    },
    isValidHttpUrl (string) {
      let url;
      try {
        url = new URL(string);
      } catch (_) {
        return false;
      }
      return url.protocol === "http:" || url.protocol === "https:";
    },
    validateDisplayPaths (value, callBack) {
      if (!value) {
        callBack(true);
        return;
      }
      let displayPath = value.trim();
      let isValid = true;
      if (displayPath.length > 15) {
        this.$Message.destroy();
        this.$Message.error({
          background: true,
          content: "Display Path length should be less than or Equal to 15 characters"
        });
        isValid = false;
      }
      callBack(isValid);
    },
    validateHeadline (value, callBack) {
      if (!value) {
        callBack(true);
        return;
      }
      let headline = value.trim();
      let isValid = true;
      if (headline.length > 30) {
        this.$Message.destroy();
        this.$Message.error({
          background: true,
          content: "Headline length should be less than on Equal to 30 characters"
        });
        isValid = false;
      }
      callBack(isValid);
    },
    validateHeadlineForDemandGen (value, callBack) {
      if (!value) {
        callBack(true);
        return;
      }
      let headline = value.trim();
      let isValid = true;
      if (headline.length > 40) {
        this.$Message.destroy();
        this.$Message.error({
          background: true,
          content: "Headline length should be less than on Equal to 40 characters"
        });
        isValid = false;
      }
      callBack(isValid);
    },
    validateHeadlineForRSA (value, callBack) {
      if (!value) {
        callBack(true);
        return;
      }
      let headline = value.trim();
      let isValid = true;
      let headlineLength = 30;
      const regex = /{COUNTDOWN\(\d{4}-\d{2}-\d{2} \d{2}:\d{2}:\d{2},\d+\)}/;
      if (regex.test(headline)) {
        const parts = headline.split(regex);
        headline = parts.join("");
        headlineLength = 22;
      }
      if (headline.length > headlineLength) {
        this.$Message.destroy();
        this.$Message.error({
          background: true,
          content: "Headline length should be less than on Equal to 30 characters"
        });
        isValid = false;
      }
      callBack(isValid);
    },
    validateLongHeadline (value, callBack) {
      if (!value) {
        callBack(true);
        return;
      }
      let longHeadline = value.trim();
      let isValid = true;
      if (longHeadline.length > 90) {
        this.$Message.destroy();
        this.$Message.error({
          background: true,
          content: "Long Headline length should be less than on Equal to 90 characters"
        });
        isValid = false;
      }
      callBack(isValid);
    },
    validateBusinessName (value, callBack) {
      if (!value) {
        callBack(true);
        return;
      }
      let businessName = value.trim();
      let isValid = true;
      if (businessName.length > 25) {
        this.$Message.destroy();
        this.$Message.error({
          background: true,
          content: "Business Name length should be less than on Equal to 25 characters"
        });
        isValid = false;
      }
      callBack(isValid);
    },
    validateShortDescription (value, callBack) {
      if (!value) {
        callBack(true);
        return;
      }
      let description = value.trim();
      let isValid = true;
      if (description.length > 60) {
        this.$Message.destroy();
        this.$Message.error({
          background: true,
          content: "Short description length should be less than on Equal to 60 characters"
        });
        isValid = false;
      }
      callBack(isValid);
    },
    validateDescription (value, callBack) {
      if (!value) {
        callBack(true);
        return;
      }
      let description = value.trim();
      let isValid = true;
      if (description.length > 90) {
        this.$Message.destroy();
        this.$Message.error({
          background: true,
          content: "Description length should be less than on Equal to 90 characters"
        });
        isValid = false;
      }
      callBack(isValid);
    },
    validateDescriptionForRSA (value, callBack) {
      if (!value) {
        callBack(true);
        return;
      }
      let description = value.trim();
      let isValid = true;
      let descriptionLength = 90;
      const regex = /{COUNTDOWN\(\d{4}-\d{2}-\d{2} \d{2}:\d{2}:\d{2},\d+\)}/;
      if (regex.test(description)) {
        const parts = description.split(regex);
        description = parts.join("");
        descriptionLength = 82;
      }
      if (description.length > descriptionLength) {
        this.$Message.destroy();
        this.$Message.error({
          background: true,
          content: "Description length should be less than on Equal to 90 characters"
        });
        isValid = false;
      }
      callBack(isValid);
    },
    validatePinedPositions (value, callBack) {
      if (!value) {
        callBack(true);
        return;
      }
      let isValid = true;
      let pinnedPositionsNumbers = value.split(',').map(e => e.trim());
      let isNumericList = pinnedPositionsNumbers.filter(function (i) {
        return isNaN(i);
      }).length == 0;
      if (!isNumericList) {
        this.$Message.destroy();
        this.$Message.error({
          background: true,
          content: "Pinned Position should be comma seperated numeric values"
        });
        isValid = false;
      }
      callBack(isValid);
    },
    validateFinalUrls (value, callBack) {
      if (!value) {
        callBack(true);
        return;
      }
      const listOfUrls = value.split(',').map(e => e.trim());
      let self = this;
      let isValid = true;
      for (let i = 0; i < listOfUrls.length; i++) {
        if (!self.isValidHttpUrl(listOfUrls[i])) {
          self.$Message.destroy();
          self.$Message.error({
            background: true,
            content: "Final URL's should be commma seperated list of valid URL's"
          });
          isValid = false;
          break;
        }
      }
      callBack(isValid);
    },
    validateFinalUrl (value, callBack) {
      if (!value) {
        callBack(true);
        return;
      }
      let self = this;
      let isValid = true;
      if (!self.isValidHttpUrl(value)) {
        self.$Message.destroy();
        self.$Message.error({
          background: true,
          content: "The URL should be valid of valid format"
        });
        isValid = false;
      }
      callBack(isValid);
    },
    checkIfReq (col) {
      if (this.colHeaders[col] === undefined) { return [false, -1] }
      let colName = this.colHeaders[col]
      if (col < this.requiredCols['baseLength']) {
        return (this.requiredCols['base'].has(colName)) ? [true, -1] : [false, -1]
      }
      let space = colName.lastIndexOf(' ')
      if (this.requiredCols['card'].has(colName.substring(0, space))) {
        let cardIndex = Math.floor((col - this.requiredCols['baseLength']) / this.requiredCols['cardLength'])
        return [true, cardIndex]
      }
      return [false, -1]
    },
    buildRowDataObj (rowData, rowNum) {
      let rowObj = Object.assign(
        ...rowData.map((cellValue, index) => ({
          [this.colHeaders[index]]: cellValue
        }))
      );
      return rowObj;
    },
    createNewPreviewableObj () {
      return {
        'validBase': false,
        'card': new Array(this.hardcodedCardsCount).fill(0),
        'base': 0
      }
    },
    checkIfPreviewable (previewRow) {
      let validBase = previewRow["base"] >= this.requiredCols["base"].size;
      return validBase;
    },
    getPreviewableRows () {
      let validRows = [];
      let instance = this.$refs['hot'].hotInstance
      let localCounter = this.lastUsedIndex + 1
      for (let [rowNum, previewRow] of Object.entries(this.previewable)) {
        let rowData = instance.getDataAtRow(rowNum);
        let rowDataObj = this.buildRowDataObj(rowData, rowNum);
        rowDataObj.Identifier = this.indexToIdentifierMapper[rowNum] ? this.indexToIdentifierMapper[rowNum] : `${this.adFormatToIdentifierNameMapper[this.tab]}${localCounter++}`;
        rowDataObj.Index = Number(rowNum);
        if (this.checkIfPreviewable(previewRow)) {
          validRows.push(rowDataObj);
        }
      }
      switch (this.tab) {
        case "adGroups":
          validRows = this.generateRowsForAdGroups(validRows);
          break;
        case "keywords":
          validRows = this.generateRowsForKeywords(validRows);
          break;
        case "responsiveAds":
          validRows = this.generateRowsForResponsiveAds(validRows);
          break;
        case "textAds":
          validRows = this.generateRowsForExpandedTextAds(validRows);
          break;
        case "callAds":
          validRows = this.generateRowsForCallAds(validRows);
          break;
        case "assetGroups":
          validRows = this.generateRowsForAssetGroups(validRows);
          break;
        case "responsiveDisplayAds":
          validRows = this.generateRowsForResponsiveDisplayAds(validRows);
          break;
        case "appAds":
          validRows = this.generateRowsForAppAds(validRows);
          break;
        case "appEngagementAds":
          validRows = this.generateRowsForAppEngagementAds(validRows);
          break;
        case "discoveryCarouselAds":
          validRows = this.generateRowsForDiscoveryCarouselAds(validRows);
          break;
        case "discoveryImageAds":
          validRows = this.generateRowsForDiscoveryImageAds(validRows);
          break;
        case "discoveryVideoAds":
          validRows = this.generateRowsForDiscoveryVideoAds(validRows);
          break;
      }
      return validRows;
    },
    buildCreativeSpec (row, index) {
      return this.regularSpec(row)
    },
    regularSpec (row) {
      let headerValMap = {}
      let creativeSpec = JSON.parse(JSON.stringify(this.creativeSpec));
      let spec = JSON.parse(JSON.stringify(this.creativeSpec))
      for (let i = 0; i < this.colHeaders.length; i++) {
        headerValMap[this.colHeaders[i]] = row[i];
      }
      this.recurse(spec, headerValMap)
      return spec
    },
    recurse (obj, headerValMap) {
      Object.keys(obj).forEach((key) => {
        if (obj[key] !== null && typeof obj[key] === 'object') {
          this.recurse(obj[key], headerValMap);
          return;
        }
        if (typeof obj[key] === 'string') {
          obj[key] = headerValMap[obj[key]];
        }
      })
    },
    setHotCellData (row, col, value, instance, lazyUpdate) {
      if (lazyUpdate) {
        this.bulkUpdateQueries[[row, col]] = [row, col, value]
      } else {
        setTimeout(() => { instance.setDataAtCell(row, col, value) })
      }
    },
    getAllRows () {
      let validRows = []
      let instance = this.$refs['hot'].hotInstance
      let rowNum = 0;
      var gridData = instance.getData();
      var cleanedGridData = [];
      gridData.forEach((rowData, index) => {
        if (!instance.isEmptyRow(index)) {
          cleanedGridData.push(rowData);
        }
      })
      for (let rowData of cleanedGridData) {
        let rowDataObj = this.buildRowDataObj(rowData, rowNum);
        validRows.push(rowDataObj)
        rowNum++;
      }
      let publishedRowObjects = (this.publishedCreatives[this.tab] && this.publishedCreatives[this.tab].length) ? JSON.parse(JSON.stringify(this.publishedCreatives[this.tab])) : [];
      let localCounter = this.lastUsedIndex + 1;
      rowNum = 0;
      let self = this;
      validRows.forEach(elem => {
        elem.Identifier = self.indexToIdentifierMapper[rowNum] ? self.indexToIdentifierMapper[rowNum] : `${self.adFormatToIdentifierNameMapper[self.tab]}${localCounter++}`;
        if (self.publishedIndex.includes(rowNum)) {
          validRows[rowNum] = publishedRowObjects[rowNum];
        } else {
          elem["HOT_IsNotPublished"] = true;
        }
        rowNum++;
      });
      return validRows;
    },
    generateRowsForAdGroups (validRows) {
      let rows = [];
      let self = this;
      let localCounter = self.lastUsedIndex + 1;
      let tempAdGroupNameToIdentifierMapper = self.adGroupNameToIdentifierMapper ? JSON.parse(JSON.stringify(self.adGroupNameToIdentifierMapper)) : {};
      validRows.forEach(row => {
        let localRow = {};
        localRow.CampaignIdentifier = self.generateCampaigns[row.campaign] ? self.generateCampaigns[row.campaign] : self.generateOldCampaigns[row.campaign];
        localRow.Identifier = row.Identifier ? row.Identifier : `${self.adFormatToIdentifierNameMapper[self.tab]}${localCounter++}`;
        localRow.Identifier = localRow.Identifier ? localRow.Identifier : self.adGroupNameToIdentifierMapper[row.adgroup] ? self.adGroupNameToIdentifierMapper[row.adgroup] : `${this.adFormatToIdentifierNameMapper[this.tab]}${localCounter++}`
        localRow.Name = row.adgroup;
        localRow.Type = self.selectedCampaignLauncherConfig.adgroup.Type;
        localRow.NegativeKeywordListIds = row.negativeKeywordList ? row.negativeKeywordList.split(',').map(e => e.trim()) : [];
        localRow.AdextensionIds = row.adExtensions ? row.adExtensions.split(',').map(e => e.trim()) : [];
        localRow.HOT_IsNotPublished = !self.publishedIndex.includes(row.Index);
        tempAdGroupNameToIdentifierMapper[localRow.Name] = localRow.Identifier;
        rows.push(localRow);
      });
      if (this.isSearchCampaign) {
        if (this.publishedSheetCreatives && this.publishedSheetCreatives.adGroups) {
          this.publishedSheetCreatives.adGroups.forEach(localRow => {
            tempAdGroupNameToIdentifierMapper[localRow.adgroup] = localRow.Identifier;
          });
        }
      }
      self.$store.commit("setAdGroupMapper", tempAdGroupNameToIdentifierMapper);
      return rows;
    },
    generateRowsForKeywords (validRows) {
      let rows = [];
      let self = this;
      let localCounter = self.lastUsedIndex + 1;
      validRows.forEach(row => {
        let localRow = {};
        localRow.AdgroupIdentifier = self.adGroupNameToIdentifierMapper[row.adgroup];
        localRow.Text = row.keyword;
        localRow.MatchType = row.matchType ? self.keywordsMatchTypeMapper[row.matchType] : self.keywordsMatchTypeMapper.Unknown;
        localRow.Identifier = row.Identifier ? row.Identifier : `${self.adFormatToIdentifierNameMapper[self.tab]}${localCounter++}`;
        localRow.HOT_IsNotPublished = !self.publishedIndex.includes(row.Index);
        rows.push(localRow);
      });
      return rows;
    },
    generateRowsForResponsiveAds (validRows) {
      let rows = [];
      let self = this;
      let localCounter = self.lastUsedIndex + 1;
      validRows.forEach(row => {
        let localRow = {};
        localRow.AdgroupIdentifier = self.adGroupNameToIdentifierMapper[row.adgroup];
        localRow.Type = self.adType.ResponsiveSearchAd;
        localRow.Headlines = [];
        localRow.Descriptions = [];
        localRow.FinalUrls = row.finalUrls ? row.finalUrls.split(',').map(e => e.trim()) : [];
        localRow.Identifier = row.Identifier ? row.Identifier : `${self.adFormatToIdentifierNameMapper[self.tab]}${localCounter++}`;
        [localRow.Headlines, localRow.Descriptions, localRow.DisplayPaths] = self.getAdTextAssets(row);
        localRow.HOT_IsNotPublished = !self.publishedIndex.includes(row.Index);
        if (this.isSearchCampaign) {
          let regex = new RegExp(self.adFormatToIdentifierNameMapper[self.tab] + "(\\d+)");
          let id = localRow.Identifier.match(regex)[1];
          localRow.Name = `responsive ${id}`;
        } else {
          localRow.Name = `responsive ${row.Index + 1}`;
        }
        rows.push(localRow);
      });
      return rows;
    },
    generateRowsForExpandedTextAds (validRows) {
      let rows = [];
      let self = this;
      let localCounter = self.lastUsedIndex + 1;
      validRows.forEach(row => {
        let localRow = {};
        localRow.AdgroupIdentifier = self.adGroupNameToIdentifierMapper[row.adgroup];
        localRow.Type = self.adType.ExpandedTextAd;
        localRow.FinalUrls = row.finalUrls ? row.finalUrls.split(',').map(e => e.trim()) : [];
        localRow.Headlines = [];
        localRow.Descriptions = [];
        localRow.Identifier = row.Identifier ? row.Identifier : `${self.adFormatToIdentifierNameMapper[self.tab]}${localCounter++}`;
        [localRow.Headlines, localRow.Descriptions, localRow.DisplayPaths] = self.getAdTextAssets(row);
        localRow.HOT_IsNotPublished = !self.publishedIndex.includes(row.Index);
        localRow.Name = `expandable ${row.Index + 1}`;
        rows.push(localRow);
      });
      return rows;
    },
    generateRowsForCallAds (validRows) {
      let rows = [];
      let self = this;
      let localCounter = self.lastUsedIndex + 1;
      validRows.forEach(row => {
        let localRow = {};
        localRow.AdgroupIdentifier = self.adGroupNameToIdentifierMapper[row.adgroup];
        localRow.Type = self.adType.CallAd;
        localRow.FinalUrls = row.finalUrls ? row.finalUrls.split(',').map(e => e.trim()) : [];
        localRow.Headlines = [];
        localRow.Descriptions = [];
        localRow.Identifier = row.Identifier ? row.Identifier : `${self.adFormatToIdentifierNameMapper[self.tab]}${localCounter++}`;
        [localRow.Headlines, localRow.Descriptions, localRow.DisplayPaths] = self.getAdTextAssets(row);
        localRow.HOT_IsNotPublished = !self.publishedIndex.includes(row.Index);
        if (this.isSearchCampaign) {
          let regex = new RegExp(self.adFormatToIdentifierNameMapper[self.tab] + "(\\d+)");
          let id = localRow.Identifier.match(regex)[1];
          localRow.Name = `callad ${id}`;
        } else {
          localRow.Name = `callad ${row.Index + 1}`;
        }
        localRow.BusinessName = row.businessName ? row.businessName : '';
        localRow.CountryCode = row.countryCode.trim();
        localRow.PhoneNumber = row.phoneNumber.trim();
        localRow.DisplayUrl = row.verificationUrl ? row.verificationUrl : null;
        localRow.ConversionAction = row.conversionAction ? self.conversionAction.find(x => x.name == row.conversionAction) : '';
        rows.push(localRow);
      });
      return rows;
    },
    generateRowsForAssetGroups (validRows) {
      let rows = [];
      let self = this;
      let localCounter = self.lastUsedIndex + 1;
      validRows.forEach(row => {
        let localRow = {};
        localRow.Headlines = [];
        localRow.LongHeadlines = [];
        localRow.Descriptions = [];
        localRow.BusinessName = row.businessName;
        localRow.MarketingImages = [];
        localRow.SquareMarketingImages = [];
        localRow.Logos = [];
        localRow.Identifier = row.Identifier ? row.Identifier : `${self.adFormatToIdentifierNameMapper[self.tab]}${localCounter++}`;
        localRow.finalUrl = row.finalUrl;
        // localRow.HOT_IsNotPublished = !self.publishedIndex.includes(row.Index);
        // localRow.IsValid = row.valid;
        localRow.Name = row.name;
        localRow.LineItemIds = row.lineItem ? row.lineItem.split(',').map(e => e.trim()) : [];
        localRow.AdextensionIds = row.adExtensions ? row.adExtensions.split(',').map(e => e.trim()) : [];
        localRow.CallToAction = row.callToAction;
        localRow.SearchThemes = row.searchThemes ? row.searchThemes.split(',').map(e => e.trim()) : [];
        localRow.UserLists = row.firstPartyAudienceIds ? row.firstPartyAudienceIds.split(',').map(e => e.trim()) : [];
        localRow.CustomAudiences = row.customAudienceIds ? row.customAudienceIds.split(',').map(e => e.trim()) : [];
        localRow.UserInterests = row.inMarketInterestIds ? row.inMarketInterestIds.split(',').map(e => e.trim()) : [];
        [localRow.Headlines, localRow.LongHeadlines, localRow.Descriptions, localRow.MarketingImages, localRow.SquareMarketingImages, localRow.Logos, localRow.YoutubeVideos, localRow.PortraitMarketingImages, localRow.LandscapeLogos] = self.getAssetGroupsArrayData(row);
        rows.push(localRow);
      });
      return rows;
    },
    getPinnedHeadlinePosition (currentHeadlineNumber, headlinePosition1, headlinePosition2, headlinePosition3) {
      if (headlinePosition1.includes(currentHeadlineNumber)) {
        return { PinnedField: this.servedAssetFieldType["headline1"] };
      } else if (headlinePosition2.includes(currentHeadlineNumber)) {
        return { PinnedField: this.servedAssetFieldType["headline2"] };
      } else if (headlinePosition3.includes(currentHeadlineNumber)) {
        return { PinnedField: this.servedAssetFieldType["headline3"] };
      } else {
        return {};
      }
    },
    getPinnedDescriptionPosition (currentDescriptionNumber, descriptionPosition1, descriptionPosition2) {
      if (descriptionPosition1.includes(currentDescriptionNumber)) {
        return { PinnedField: this.servedAssetFieldType["description1"] };
      } else if (descriptionPosition2.includes(currentDescriptionNumber)) {
        return { PinnedField: this.servedAssetFieldType["description2"] };
      } else {
        return {};
      }
    },
    getAdTextAssets (row) {
      var self = this;
      // only 3 headlines are required, rest are optional, so we support only upto 3 servedAssetFieldType in the API, so we use the terniary operator to send empty prop instead
      // same applies for description, only two descriptions are required
      // we filter out the rest based on wheather the property exist and if it has a valid text field
      let entries = Object.entries(row);
      let headlinePosition1 = row.hPosition1 ? row.hPosition1.split(",") : [];
      let headlinePosition2 = row.hPosition2 ? row.hPosition2.split(",") : [];
      let headlinePosition3 = row.hPosition3 ? row.hPosition3.split(",") : [];
      let headlines = entries.map(function (e) {
        if (e[0].indexOf("headline") != -1) {
          return { "Text": e[1], ...self.getPinnedHeadlinePosition(e[0].substring(8), headlinePosition1, headlinePosition2, headlinePosition3) };
        }
      }).filter(e => e && e.Text);

      let descriptionPosition1 = row.dPosition1 ? row.dPosition1.split(",") : [];
      let descriptionPosition2 = row.dPosition2 ? row.dPosition2.split(",") : [];
      let descriptions = entries.map(function (e) {
        if (e[0].indexOf("description") != -1) {
          return { "Text": e[1], ...self.getPinnedDescriptionPosition(e[0].substring(11), descriptionPosition1, descriptionPosition2) };
        }
      }).filter(e => e && e.Text);
      let displayPaths = entries.map(function (e) {
        if (e[0].indexOf("displayPath") != -1) {
          return e[1];
        }
      }).filter(e => e);

      return [headlines, descriptions, displayPaths];
    },
    validateAdExtensions (value, callBack) {
      if (!value) {
        callBack(true);
        return;
      }
      let self = this
      let adExtensions = value.split(",")
      adExtensions.forEach(function (adExtension) {
        let splitAdExtension = adExtension.split("-")
        if (adExtension != "" && (splitAdExtension.length != 3 || splitAdExtension[0] != 'DXA' || splitAdExtension[1] != Number.parseInt(deltax.businessProfileId).toString(36) || isNaN(splitAdExtension[2]))) {
          self.$Message.destroy();
          self.$Message.error({
            background: true,
            content: "AdExtensions '" + adExtension + "' is invalid"
          })
          callBack(false)
        }
      });
      callBack(true);
    },
    getAssetGroupsArrayData (row) {
      let headlines = [];
      let longHeadlines = [];
      let descriptions = [];
      let marketingImages = [];
      let squareMarketingImages = [];
      let logos = [];
      let youtubeVideos = [];
      let portraitMarketingImages = [];
      let landscapeLogos = [];

      if (Object.keys(row).length) {
        if (row.valid === "true") {
          for (let item in row) {
            if (row[item] === null || row[item] === '') continue;
            if (item.includes("headline")) {
              headlines.push(row[item])
            }
            if (item.includes("longHeadline")) {
              longHeadlines.push(row[item])
            }
            if (item.includes("description") || item.includes("shortDescription")) {
              descriptions.push(row[item])
            }
            if (item.includes("marketingImage")) {
              if (row[item].startsWith("DXC")) {
                marketingImages.push(row[item])
              } else {
                marketingImages.push(this.filterIdFromImageName(row[item]));
              }
            }
            if (item.includes("squareMarketingImage")) {
              if (row[item].startsWith("DXC")) {
                squareMarketingImages.push(row[item])
              } else {
                squareMarketingImages.push(this.filterIdFromImageName(row[item]));
              }
            }
            if (item.includes("logo")) {
              if (row[item].startsWith("DXC")) {
                logos.push(row[item])
              } else {
                logos.push(this.filterIdFromImageName(row[item]));
              }
            }
            if (item.includes("youtubeVideo")) {
              youtubeVideos.push(row[item])
            }
            if (item.includes("portraitMarketingImage")) {
              if (row[item].startsWith("DXC")) {
                portraitMarketingImages.push(row[item])
              } else {
                portraitMarketingImages.push(this.filterIdFromImageName(row[item]));
              }
            }
            if (item.includes("landscapeLogo")) {
              if (row[item].startsWith("DXC")) {
                landscapeLogos.push(row[item])
              } else {
                landscapeLogos.push(this.filterIdFromImageName(row[item]))
              }
            }
          }
        }
      }
      return [headlines, longHeadlines, descriptions, marketingImages, squareMarketingImages, logos, youtubeVideos, portraitMarketingImages, landscapeLogos];
    },
    imageColRenderer (instance, td, row, col, prop, value, cellProperties) {
      if (this.publishedIndex.includes(row)) {
        td.classList.add('disabled-cell')
      }
      value = Handsontable.helper.stringify(value)
      let img = td.querySelector("img")
      if (value in this.imageIdUrlMap || (value.includes("http") || value.includes("www"))) {
        if (img && img.src == this.imageIdUrlMap[value]) {
          return td
        }
        if (img && img.src == value) {
          return td
        }
        img = document.createElement('IMG')
        if (value.startsWith("DXC") && value.split('-')[0] === 'DXC') {
          img.src = this.imageIdUrlMap[value]['src']
        } else if (value.includes("http") || value.includes("www")) {
          img.src = value;
        } else {
          img.src = this.imageIdUrlMap[value]['src']
        }
        img.classList.add('hot-img')
        Handsontable.dom.addEvent(img, 'mousedown', function (event) {
          event.preventDefault()
        })

        Handsontable.dom.empty(td)
        td.appendChild(img)
      } else {
        Handsontable.renderers.TextRenderer.apply(this, arguments)
      }
      if (cellProperties.className) td.classList.add(cellProperties.className);
      return td
    },
    adExtensionRenderer (instance, td, row, col, prop, value, cellProperties) {
      if (this.publishedIndex.includes(row)) {
        td.classList.add('disabled-cell-text')
      }
      while (td.firstChild) {
        td.removeChild(td.firstChild);
      }
      if (value) {
        value = Handsontable.helper.stringify(value);
        let enteredAdExtensions = value.split(",");
        let adExtensionNames = [];
        enteredAdExtensions.forEach(enteredAdExtension => {
          let adExtensionId = enteredAdExtension.split("-").pop();
          let adExtension = this.allAdExtensions.find(x => x.id == adExtensionId)
          if (adExtension) {
            adExtensionNames.push(adExtension.name);
          }
        });
        let span = document.createElement('span');
        span.innerHTML = adExtensionNames.join(",");
        td.appendChild(span);
      }
      return td;
    },
    rerender () {
      this.$nextTick(() => {
        this.$refs['hot'].hotInstance.render()
      })
    },
    validateYoutubeUrl (value, callBack) {
      if (!value) {
        callBack(true);
        return;
      }
      let self = this;
      let isValid = true;
      var youtubeRegex = /(https?:\/\/)?(www\.)?(youtube|youtu|youtube-nocookie)\.(com|be)\/(watch\?v=|embed\/|v\/|.+\?v=)?([^&=%\?]{11})/;
      var youtubeShortsRegex = /(https?:\/\/)?(www\.)?youtube\.com\/shorts\/[^&=%\?]{11}/;
      if (!youtubeRegex.test(value) && !youtubeShortsRegex.test(value)) {
        self.$Message.destroy();
        self.$Message.error({
          background: true,
          content: "The Youtube Video or Short URL should be valid format"
        });
        isValid = false;
      }
      callBack(isValid);
    },
    generateRowsForResponsiveDisplayAds (validRows) {
      let rows = [];
      let self = this;
      let localCounter = self.lastUsedIndex + 1;
      validRows.forEach(row => {
        let localRow = {};
        localRow.Type = self.adType.ResponsiveDisplayAd;
        localRow.AdgroupIdentifier = self.adGroupNameToIdentifierMapper[row.adgroup];
        localRow.HOT_IsNotPublished = !self.publishedIndex.includes(row.Index);
        localRow.Headlines = [];
        localRow.Descriptions = [];
        localRow.MarketingImages = [];
        localRow.SquareMarketingImages = [];
        localRow.LogoImages = [];
        localRow.SquareLogoImages = [];
        localRow.YoutubeVideos = [];
        localRow.BusinessName = row.businessName;
        localRow.FinalUrls = [row.finalUrl]
        localRow.LongHeadline = { "Text": row.longHeadline }
        localRow.Identifier = row.Identifier ? row.Identifier : `${self.adFormatToIdentifierNameMapper[self.tab]}${localCounter++}`;
        [localRow.Headlines, localRow.Descriptions, localRow.MarketingImages, localRow.SquareMarketingImages, localRow.LogoImages, localRow.SquareLogoImages, localRow.YoutubeVideos] = self.getDisplayAssetsGroupArrayData(row);
        localRow.Name = localRow.Headlines.slice(0, 3).map(headline => headline.Text).join(' | ')
        rows.push(localRow);
      });
      return rows;
    },
    getDisplayAssetsGroupArrayData (row) {
      let headlines = [];
      let descriptions = [];
      let marketingImages = [];
      let squareMarketingImages = [];
      let logos = [];
      let squareLogos = [];
      let youtubeVideos = [];

      if (Object.keys(row).length) {
        if (row.valid === "true") {
          for (let item in row) {
            if (row[item] === null || row[item] === '') continue;
            if (item.includes("headline")) {
              let hItem = {}
              hItem.Text = row[item]
              headlines.push(hItem)
            }
            if (item.includes("description")) {
              let bItem = {}
              bItem.Text = row[item]
              descriptions.push(bItem)
            }
            if (item.includes("marketingImage")) {
              marketingImages.push(row[item])
            }
            if (item.includes("squareMarketingImage")) {
              squareMarketingImages.push(row[item])
            }
            if (item.includes("logo")) {
              logos.push(row[item])
            }
            if (item.includes("squareLogo")) {
              squareLogos.push(row[item])
            }
            if (item.includes("youtubeVideo")) {
              youtubeVideos.push(row[item])
            }
          }
        }
      }
      return [headlines, descriptions, marketingImages, squareMarketingImages, logos, squareLogos, youtubeVideos];
    },
    generateRowsForAppAds (validRows) {
      let rows = [];
      let self = this;
      let localCounter = self.lastUsedIndex + 1;
      validRows.forEach(row => {
        let localRow = {};
        localRow.AdgroupIdentifier = self.adGroupNameToIdentifierMapper[row.adgroup];
        localRow.Type = self.adType.AppAd;
        localRow.HOT_IsNotPublished = !self.publishedIndex.includes(row.Index);
        localRow.Headlines = [];
        localRow.Descriptions = [];
        localRow.Images = [];
        localRow.YoutubeVideos = [];
        localRow.Identifier = row.Identifier ? row.Identifier : `${self.adFormatToIdentifierNameMapper[self.tab]}${localCounter++}`;
        [localRow.Headlines, localRow.Descriptions, localRow.Images, localRow.YoutubeVideos] = self.getAppAdsArrayData(row);
        localRow.Name = localRow.Headlines.slice(0, 3).map(headline => headline.Text).join(' | ');
        rows.push(localRow);
      });
      return rows;
    },
    generateRowsForAppEngagementAds (validRows) {
      let rows = [];
      let self = this;
      let localCounter = self.lastUsedIndex + 1;
      validRows.forEach(row => {
        let localRow = {};
        localRow.AdgroupIdentifier = self.adGroupNameToIdentifierMapper[row.adgroup];
        localRow.Type = self.adType.AppEngagementAd;
        localRow.HOT_IsNotPublished = !self.publishedIndex.includes(row.Index);
        localRow.Headlines = [];
        localRow.Descriptions = [];
        localRow.Images = [];
        localRow.YoutubeVideos = [];
        localRow.FinalAppUrls = [row.appUrl]
        localRow.Identifier = row.Identifier ? row.Identifier : `${self.adFormatToIdentifierNameMapper[self.tab]}${localCounter++}`;
        [localRow.Headlines, localRow.Descriptions, localRow.Images, localRow.YoutubeVideos] = self.getAppAdsArrayData(row);
        localRow.Name = localRow.Headlines.slice(0, 3).map(headline => headline.Text).join(' | ');
        rows.push(localRow);
      });
      return rows;
    },
    getAppAdsArrayData (row) {
      let headlines = [];
      let descriptions = [];
      let images = [];
      let youtubeVideos = [];

      if (Object.keys(row).length) {
        if (row.valid === "true") {
          for (let item in row) {
            if (row[item] === null || row[item] === '') continue;
            if (item.includes("headline")) {
              let hItem = {}
              hItem.Text = row[item]
              headlines.push(hItem)
            }
            if (item.includes("description")) {
              let dItem = {}
              dItem.Text = row[item]
              descriptions.push(dItem)
            }
            if (item.includes("image")) {
              images.push(row[item])
            }
            if (item.includes("youtubeVideo")) {
              youtubeVideos.push(row[item])
            }
          }
        }
      }
      return [headlines, descriptions, images, youtubeVideos];
    },
    generateRowsForDiscoveryCarouselAds (validRows) {
      let rows = [];
      let self = this;
      let localCounter = self.lastUsedIndex + 1;
      validRows.forEach(row => {
        let localRow = {};
        localRow.Type = self.adType.DiscoveryCarouselAd;
        localRow.Identifier = row.Identifier ? row.Identifier : `${self.adFormatToIdentifierNameMapper[self.tab]}${localCounter++}`;
        localRow.AdgroupIdentifier = self.adGroupNameToIdentifierMapper[row.adgroup];
        localRow.HOT_IsNotPublished = !self.publishedIndex.includes(row.Index);
        localRow.Name = row.name;
        localRow.BusinessName = row.businessName;
        localRow.Headlines = [{ "Text": row.headline }];
        localRow.Descriptions = [{ "Text": row.description }];
        localRow.FinalUrls = [row.finalUrl];
        localRow.LogoImages = [row.logo];
        localRow.CardAssets = self.getDemandGenArrayData(row, 'carouselAd').filter(item => item !== null && Object.keys(item).length > 0);
        rows.push(localRow);
      });
      return rows;
    },
    generateRowsForDiscoveryImageAds (validRows) {
      let rows = [];
      let self = this;
      let localCounter = self.lastUsedIndex + 1;
      validRows.forEach(row => {
        let localRow = {};
        localRow.Type = self.adType.DiscoveryImageAd;
        localRow.HOT_IsNotPublished = !self.publishedIndex.includes(row.Index);
        localRow.Identifier = row.Identifier ? row.Identifier : `${self.adFormatToIdentifierNameMapper[self.tab]}${localCounter++}`;
        localRow.AdgroupIdentifier = self.adGroupNameToIdentifierMapper[row.adgroup];
        localRow.Name = row.name;
        localRow.BusinessName = row.businessName;
        localRow.CallToAction = row.callToAction;
        localRow.FinalUrls = [row.finalUrl];
        localRow.Headlines = [];
        localRow.Descriptions = [];
        localRow.MarketingImages = [];
        localRow.SquareMarketingImages = [];
        localRow.LogoImages = [];
        localRow.PortraitMarketingImages = [];
        [localRow.Headlines, localRow.Descriptions, localRow.MarketingImages, localRow.SquareMarketingImages, localRow.LogoImages, localRow.PortraitMarketingImages] = self.getDemandGenArrayData(row, 'imageAd');
        rows.push(localRow);
      });
      return rows;
    },
    generateRowsForDiscoveryVideoAds (validRows) {
      let rows = [];
      let self = this;
      let localCounter = self.lastUsedIndex + 1;
      validRows.forEach(row => {
        let localRow = {};
        localRow.Type = self.adType.DiscoveryVideoAd;
        localRow.HOT_IsNotPublished = !self.publishedIndex.includes(row.Index);
        localRow.Identifier = row.Identifier ? row.Identifier : `${self.adFormatToIdentifierNameMapper[self.tab]}${localCounter++}`;
        localRow.AdgroupIdentifier = self.adGroupNameToIdentifierMapper[row.adgroup];
        localRow.Name = row.name;
        localRow.BusinessName = row.businessName;
        localRow.CallToAction = row.callToAction;
        localRow.FinalUrls = [row.finalUrl];
        localRow.YoutubeVideos = [];
        localRow.Headlines = [];
        localRow.Descriptions = [];
        localRow.LongHeadlines = [];
        localRow.LogoImages = [];
        [localRow.Headlines, localRow.Descriptions, localRow.LogoImages, localRow.YoutubeVideos, localRow.LongHeadlines] = self.getDemandGenArrayData(row, 'videoAd');
        rows.push(localRow);
      });
      return rows;
    },
    getDemandGenArrayData (row, adType) {
      if (adType == 'videoAd') {
        let headlines = [];
        let descriptions = [];
        let logos = [];
        let videos = [];
        let longHeadlines = [];
        if (Object.keys(row).length) {
          if (row.valid === "true") {
            for (let item in row) {
              if (row[item] === null || row[item] === '') continue;
              if (item.includes("headline")) {
                let hItem = {}
                hItem.Text = row[item]
                headlines.push(hItem)
              }
              if (item.includes("longHeadline")) {
                let hItem = {}
                hItem.Text = row[item]
                longHeadlines.push(hItem)
              }
              if (item.includes("description")) {
                let bItem = {}
                bItem.Text = row[item]
                descriptions.push(bItem)
              }
              if (item.includes("logo")) {
                logos.push(row[item])
              }
              if (item.includes("video")) {
                videos.push(row[item])
              }
            }
          }
        }
        return [headlines, descriptions, logos, videos, longHeadlines];
      } else if (adType == 'imageAd') {
        let headlines = [];
        let descriptions = [];
        let logos = [];
        let marketingImages = [];
        let squareMarketingImages = [];
        let portraitMarketingImage = [];
        if (Object.keys(row).length) {
          if (row.valid === "true") {
            for (let item in row) {
              if (row[item] === null || row[item] === '') continue;
              if (item.includes("headline")) {
                let hItem = {}
                hItem.Text = row[item]
                headlines.push(hItem)
              }
              if (item.includes("description")) {
                let bItem = {}
                bItem.Text = row[item]
                descriptions.push(bItem)
              }
              if (item.includes("logo")) {
                logos.push(row[item])
              }
              if (item.includes("marketingImage")) {
                marketingImages.push(row[item])
              }
              if (item.includes("squareMarketingImage")) {
                squareMarketingImages.push(row[item])
              }
              if (item.includes("portraitMarketingImage")) {
                portraitMarketingImage.push(row[item])
              }
            }
          }
        }
        return [headlines, descriptions, marketingImages, squareMarketingImages, logos, portraitMarketingImage];
      } else if (adType == 'carouselAd') {
        let card = []
        if (Object.keys(row).length) {
          if (row.valid === "true") {
            Object.keys(row).forEach(key => {
              if (key.includes("card")) {
                let match = key.match(/\d+/);
                let cardNum = match ? parseInt(match[0]) : null;
                if (cardNum !== null && !card[cardNum]) {
                  card[cardNum] = {};
                }
                if (row[key] === null || row[key] === '') return;
                if (key.includes("PortraitMarketingImage")) {
                  card[cardNum].PortraitMarketingImageAsset = row[key]
                } else if (key.includes("SquareMarketingImage")) {
                  card[cardNum].SquareMarketingImageAsset = row[key]
                } else if (key.includes("MarketingImage")) {
                  card[cardNum].MarketingImageAsset = row[key]
                }
                if (key.includes("Headline")) {
                  card[cardNum].Headline = row[key]
                }
                if (key.includes("FinalURL")) {
                  card[cardNum].FinalUrls = [row[key]]
                }
                if (key.includes("CallToAction")) {
                  card[cardNum].CallToActionText = row[key] ? row[key] : "";
                }
              }
            });
          }
        }
        return card;
      }
    },
    isAdFormatTab (tabName) {
      return tabName == 'responsiveDisplayAds' || tabName == 'appAds' || tabName == 'appEngagementAds' || tabName == 'discoveryCarouselAds' || tabName == 'discoveryImageAds' || tabName == 'discoveryVideoAds';
    },
    lineItemNameRenderer (instance, td, row, col, prop, value, cellProperties) {
      if (this.publishedIndex.includes(row)) {
        td.classList.add('disabled-cell')
      }
      while (td.firstChild) {
        td.removeChild(td.firstChild);
      }
      if (value) {
        value = Handsontable.helper.stringify(value);
        let lineItems = value.split(",");
        let names = [];
        lineItems.forEach(lineItem => {
          let lineItemId = lineItem.split("-").pop();
          let lineItemExists = this.mediaPlanLineItems.find(obj => obj.id == lineItemId);
          if (lineItemExists) {
            names.push(lineItemExists.name);
          } else {
            names.push(lineItem);
          }
        });
        if (names.length) {
          td.innerHTML = '';
          let tdWrapper = document.createElement('div');
          tdWrapper.innerHTML = [...new Set(names)].join(",");
          td.appendChild(tdWrapper);
        } else {
          td.innerHTML = '';
        }

        // if (lineItem) {
        //   let span = document.createElement('span');
        //   span.innerHTML = lineItem.name;
        //   td.appendChild(span);
        // }
      }
      if (cellProperties.className) td.classList.add(cellProperties.className);
      return td;
    },
    filterIdFromImageName (imageName) {
      let imageObject = this.imageIdUrlMap[imageName];
      if (imageObject) {
        return imageObject.id;
      } else {
        return imageName;
      }
    },
    processImageUrl (row, col, oldVal, newVal, instance) {
      let img = new Image();
      img.onload = () => {
        this.alreadyProcessed[`${row}-${col}`] = true;
        instance.setDataAtCell(row, col, newVal);
      };
      img.onerror = () => {
        this.$Message.error({
          background: true,
          content: 'Please enter a valid image URL'
        })
      };
      img.src = newVal;
    },
    validateYoutubePromoAssets (value, callBack) {
      if (!value) {
        callBack(true);
        return;
      }
      let youtubeRegex = /(https?:\/\/)?(www\.)?(youtube|youtu|youtube-nocookie)\.(com|be)\/(watch\?v=|embed\/|v\/|.+\?v=)?([^&=%\?]{11})/;
      let youtubeShortsRegex = /(https?:\/\/)?(www\.)?youtube\.com\/shorts\/[^&=%\?]{11}/;
      let isValid = true;
      if (!this.storeAssetRegex.test(value) && !youtubeRegex.test(value) && !youtubeShortsRegex.test(value)) {
        this.$Message.destroy();
        this.$Message.error({
          background: true,
          content: "The Youtube Video or Short URL should be valid format"
        });
        isValid = false;
      }
      callBack(isValid);
    },
    validatePromoFinalUrl (value, callBack) {
      if (!value) {
        callBack(true);
        return;
      }
      let storeWebsiteFormat = "{{store.website}}";
      let isValid = true;
      if (value !== storeWebsiteFormat && !this.isValidHttpUrl(value)) {
        this.$Message.destroy();
        this.$Message.error({
          background: true,
          content: "The URL should be valid of valid format"
        });
        isValid = false;
      }
      callBack(isValid);
    },
    validatePromoAdExtensions (value, callBack) {
      if (!value) {
        callBack(true);
        return;
      }
      let self = this
      let adExtensions = value.split(",")
      adExtensions.forEach(function (adExtension) {
        let splitAdExtension = adExtension.split("-")
        if (!self.storeAssetRegex.test(adExtension) && adExtension != "" && (splitAdExtension.length != 3 || splitAdExtension[0] != 'DXA' || splitAdExtension[1] != Number.parseInt(deltax.businessProfileId).toString(36) || isNaN(splitAdExtension[2]))) {
          self.$Message.destroy();
          self.$Message.error({
            background: true,
            content: "AdExtensions '" + adExtension + "' is invalid"
          })
          callBack(false)
        }
      });
      callBack(true);
    }
  }
}
</script>

<style src="../../../../../../../node_modules/handsontable/dist/handsontable.full.css"></style>
<style src="../../../../Styles/dx-iview.css"></style>
<style scoped>
  .ivu-tabs-nav .ivu-tabs-tab{
    border-radius: 0 !important;
  }

.ad-sheet-hot .handsontable td,
.ad-sheet-hot .handsontable tr,
.ad-sheet-hot .handsontable th {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap !important;
}

.disabled-cell-text {
  background-color: #f6f6f6 !important;
  color: #bbb !important;
}
.preview-td-wrapper {
  cursor: default;
}
::v-deep .handsontable td.htInvalid {
  background-color: white !important;
  border: 1px solid red
}
.post-cell {
  cursor: not-allowed;
}
</style>
