<template>
  <div>
    <i-modal v-model="openRequisitionModal" width="96%" class="mcr-modal" @on-cancel="closeModal()">
      <div slot="header">
        <i-row :gutter="24" class="header-row">
          <i-col span="3" class="bp-selector">
            <i-input v-model="businessProfileName" disabled></i-input>
          </i-col>
          <i-col span="3">
            <i-input v-model="mediaPlanName" disabled v-if="showMediaPlanInInput"></i-input>
              <i-select v-model="mediaPlanId"  v-if="showMediaPlanInDropDown" @on-change="reRenderData()">
                <i-option
                  v-for="option in bpMediaPlansData"
                  :key="option.id"
                  :value="option.id"
                >
                  {{ option.name }}
                </i-option>
              </i-select>
          </i-col>
          <i-col span="3">
            <i-select placeholder="Assignee" v-model="selectedAssignee" multiple :max-tag-count="1">
              <i-option v-for="option in assigneeData" :key="option.id" :value="option.id">
                {{ option.firstName + " " + option.lastName }}
              </i-option>
            </i-select>
          </i-col>
          <i-col span="3">
            <i-select placeholder="Receiver" v-model="selectedReceiver" multiple :max-tag-count="1">
              <i-option v-for="option in receiverData" :key="option.id" :value="option.id">
                {{ option.firstName + " " + option.lastName }}
              </i-option>
            </i-select>
          </i-col>
          <i-col span="3">
            <i-select aria-placeholder="Delivery" v-model="selectedDeliveryMode">
              <i-option v-for="option in DeliveryActionOptions" :key="option.id" :value="option.id">
                {{ option.label }}
              </i-option>
            </i-select>
          </i-col>
          <i-col span="9" class="view-buttons">
            <button :class="['btn', { 'btn-success': currentView === currentViewEnum.Asset, 'btn-white': currentView !== currentViewEnum.Asset }]"
              @click="setView(currentViewEnum.Asset)">Asset View</button>
            <button
              :class="['btn', 'plan-view-btn', { 'btn-success': currentView === currentViewEnum.Plan, 'btn-white': currentView !== currentViewEnum.Plan }]"
              @click="setView(currentViewEnum.Plan)">Plan View</button>
          </i-col>
        </i-row>
      </div>
      <div class="modal-body">
        <div class="spinner-wrapper" v-show="loadingData">
          <i class="fa fa-spinner fa-spin fa-3x"></i>
        </div>
        <div v-show="!loadingData && currentView === currentViewEnum.Asset">
          <div class="mcr-grid">
            <material-copy-grid ref="materialCopy" v-show="!loadingData" v-for="mode in lineItemModes" :key="mode"
              :lineItemMode="mode" @linkLineItems="addtoSummary"
              @unLinkLineItems="removeFromSummary"></material-copy-grid>
          </div>
          <div class="pr-grid">
            <publisher-receipt ref="publisherReceipt" v-show="!loadingData">
            </publisher-receipt>
          </div>
        </div>

        <div v-show="!loadingData && currentView === currentViewEnum.Plan">
          <div class="alert-message" v-if="!needToRecalculateAssetViewData">The plan view is not editable, please make
            edits in the Asset view</div>
          <material-copy-plan-view v-for="(publisher, index) in publishers" :key="index" :publisher="publisher"
            ref="materialCopyPlanView" @linkLineItemMode="addLineItemMode" @unLinkLineItemMode="removeLineItemMode">
          </material-copy-plan-view>
        </div>
      </div>
      <div slot="footer" class="mcr-modal-footer">
        <div class="footer-header" :key="renderKey" v-if="!loadingData">
          <div class="summary-text">
            Summary:
          </div>
          <div class="summary-footer">
            <div v-for="mode in lineItemModes" :key=mode>
              {{ getLineItemType(mode) }}: {{ getLinkedLineItems(mode) }}/{{ countTotalLineItems(mode) }} LineItems
            </div>
          </div>
        </div>
        <div>
          <button class="btn btn-white close-btn" @click="closeModal()">Close</button>
          <button class="btn btn-success" @click="save()">Save</button>
        </div>
      </div>
    </i-modal>
  </div>
</template>

<script>
import { Input, Select, locale, Modal, Row, Col, Option } from 'iview'
import lang from 'iview/dist/locale/en-US'
import MaterialCopyGrid from './MaterialCopyGrid.vue';
import PublisherReceipt from './PublisherReceipt.vue';
import MaterialCopyPlanView from './MaterialCopyPlanView.vue';
import moment from 'moment';
import { ApiService } from '../Services/materialCopyApiService.js';

const apiService = new ApiService();

locale(lang)
export default {
  components: {
    "i-modal": Modal,
    "i-input": Input,
    "i-select": Select,
    "i-row": Row,
    "i-col": Col,
    "material-copy-grid": MaterialCopyGrid,
    "i-option": Option,
    "publisher-receipt": PublisherReceipt,
    "material-copy-plan-view": MaterialCopyPlanView
  },
  props: {
    openModal: Boolean,
    mediaPlanId: Number
  },
  data () {
    return {
      openRequisitionModal: false,
      lineItemModes: [],
      mediaPlanData: null,
      inputGroupId: 0,
      inputGroupData: null,
      businessProfileName: '',
      selectedAssignee: [],
      selectedReceiver: [],
      selectedDeliveryMode: null,
      languagesData: [],
      adTypeData: [],
      assigneeData: [],
      receiverData: [],
      mediaPlanName: '',
      renderKey: 0,
      modeLineItemMap: new Map(),
      modeLinkedLineItemMap: new Map(),
      loadingData: false,
      bpMediaPlansData: [],
      DeliveryActionOptions: [
        {
          id: 1,
          label: 'Send on receipt'
        },
        {
          id: 2,
          label: 'Send after review'
        }
      ],
      publisherSuppliersMap: new Map(),
      publisherLineItemMap: new Map(),
      linkedPlanViewLineItemModeMap: new Map(),
      supplierData: [],
      currentView: 1,
      publishers: [],
      needToRecalculateAssetViewData: true,
      showMediaPlanInInput: true,
      showMediaPlanInDropDown: false,
      currentViewEnum: {
        Asset: 1,
        Plan: 2
      }
    }
  },
  watch: {
    async openModal (value) {
      if (value) {
        this.loadingData = true;
        this.openRequisitionModal = true;
        if (this.mediaPlanId == 0) {
          let mediaPlans = (await apiService.getBpMediaPlanData()).data.data;
          this.bpMediaPlansData = mediaPlans.map(element => { return { id: element.id, name: element.name } })
          this.mediaPlanId = this.bpMediaPlansData[0].id
          this.mediaPlanName = this.bpMediaPlansData[0].name
          this.showMediaPlanInInput = false
          this.showMediaPlanInDropDown = true
        }
        await this.initializeData()
      }
      this.loadingData = false;
    }
  },
  methods: {
    async initializeData () {
      await this.init(this.mediaPlanId);
      await this.setAssetViewData();
    },
    async reRenderData () {
      this.$nextTick(() => {
        this.loadingData = true;
      })
      this.resetData();
      await this.initializeData();
      this.loadingData = false;
    },
    async setView (view) {
      this.currentView = view;
      this.loadingData = true;
      if (view === this.currentViewEnum.Asset && this.needToRecalculateAssetViewData) {
        await this.setAssetViewDataFromPlanView();
      }
      if (view === this.currentViewEnum.Plan) {
        await this.setPlanViewDataFromAssetView();
      }
      this.loadingData = false;
    },
    async setPlanViewDataFromAssetView () {
      let inputData = []
      var notificationInfos = JSON.stringify(this.$refs.publisherReceipt.tblData);
      let inputGroupRequestData = {
        id: this.inputGroupId,
        name: this.mediaPlanName,
        deliveryMode: this.selectedDeliveryMode,
        assignees: this.selectedAssignee.join(","),
        receivers: this.selectedReceiver.join(","),
        mediaPlanId: this.mediaPlanId,
        inputs: [],
        notificationInfo: notificationInfos
      }
      this.lineItemModes.forEach((mode, index) => {
        inputData = [...inputData, ...this.$refs.materialCopy[index].tblData];
      })
      let flag = true;
      inputData.forEach(input => {
        var formattedInputDate = moment(input.dueDate).format().toString().split("T")[0];
        if (input.linkLineItems && input.linkLineItems.length > 1) {
          flag = false;
        }
        inputGroupRequestData.inputs.push({
          id: input.id,
          inputGroupId: this.inputGroupId,
          languageId: input.language,
          adTypeId: input.adType,
          dueDate: formattedInputDate + "T" + input.dueTime,
          specifications: JSON.stringify(input.specifications),
          lineItemIds: input.linkLineItems,
          status: input.status
        })
      })
      this.inputGroupData = inputGroupRequestData;
      this.needToRecalculateAssetViewData = flag;
      this.setPlanViewData(flag);
    },
    async setAssetViewDataFromPlanView () {
      var notificationInfos = null;
      var infos = [];
      this.publishers.forEach((publisher, index) => {
        var publisherInfo = this.$refs.materialCopyPlanView[index];
        infos.push({
          activeStatus: publisherInfo.publisherRecipientsData().isActive,
          publisher: publisherInfo.publisher,
          supplierEmails: publisherInfo.publisherRecipientsData().supplierEmails
        })
      })
      notificationInfos = JSON.stringify(infos);
      let inputGroupRequestData = {
        id: this.inputGroupId,
        name: this.mediaPlanName,
        deliveryMode: this.selectedDeliveryMode,
        assignees: this.selectedAssignee.join(","),
        receivers: this.selectedReceiver.join(","),
        mediaPlanId: this.mediaPlanId,
        inputs: [],
        notificationInfo: notificationInfos
      }
      var planViewInputData = [];

      this.publishers.forEach((publisher, index) => {
        var materialCopyPlanView = this.$refs.materialCopyPlanView[index];

        materialCopyPlanView.lineItemIds.forEach((lineItemId, index2) => {
          var inputDatas = materialCopyPlanView.lineItemPlanViewData(index2).tblData;

          inputDatas.forEach((input) => {
            var formattedDueDate = moment(input.dueDate).format('YYYY-MM-DD');
            var newInput = {
              id: input.id,
              inputGroupId: this.inputGroupId,
              languageId: input.language,
              adTypeId: input.adType,
              dueDate: `${formattedDueDate}T${input.dueTime}`,
              specifications: JSON.stringify(input.specifications),
              lineItemIds: [lineItemId],
              status: input.status
            };

            let existingInput = planViewInputData.find(item => item.id === input.id && item.id != null);

            if (existingInput) {
              existingInput.lineItemIds = [...new Set([...existingInput.lineItemIds, lineItemId])];
            } else {
              planViewInputData.push(newInput);
            }
          });
        });
      });
      inputGroupRequestData.inputs = planViewInputData;
      this.inputGroupData = inputGroupRequestData;
      this.setAssetViewData();
    },

    async setAssetViewData () {
      await this.setGridData();
      if (this.inputGroupId != 0 || this.inputGroupData) {
        this.setFromNotificationInfo();
      } else {
        this.setPublisherGridData();
      }
    },
    async setPlanViewData (isEdit) {
      await this.setMaterialCopyPlanViewLineItemIds();
      await this.setPublisherGridDataPlanView(isEdit);
      await this.setLineItemPlanViewData(isEdit);
      if (this.inputGroupId != 0 || this.inputGroupData) {
        await this.setInputDataForPlanView();
      }
      await this.setMaterialCopyPlanViewLoadingFalse();
    },
    setMaterialCopyPlanViewLineItemIds () {
      this.publishers.forEach((publisher, index1) => {
        this.$refs.materialCopyPlanView[index1].lineItemIds = [];
        this.$refs.materialCopyPlanView[index1].lineItemIds = this.publisherLineItemMap.get(publisher);
      })
    },
    setMaterialCopyPlanViewLoadingFalse () {
      this.publishers.forEach((publisher, index1) => {
        this.$refs.materialCopyPlanView[index1].isLoading = false;
      })
    },
    setInputDataForPlanView () {
      this.publishers.forEach((publisher, index1) => {
        const materialCopyPlanViewComponent = this.$refs.materialCopyPlanView[index1];
        if (materialCopyPlanViewComponent) {
          materialCopyPlanViewComponent.lineItemIds.forEach((lineItemId, index2) => {
            let inputs = this.inputGroupData.inputs.filter(obj => Array.isArray(obj.lineItemIds) && obj.lineItemIds.includes(lineItemId));
            let inputData = [];
            inputs.forEach((input, indx) => {
              inputData.push({
                index: indx,
                id: input.id,
                language: input.languageId,
                adType: input.adTypeId,
                specifications: JSON.parse(input.specifications),
                dueDate: input.dueDate.split("T")[0],
                dueTime: input.dueDate.split("T")[1],
                status: input.status
              })
            })
            inputData.sort((a, b) => {
              if (a.id === null) return 1;
              if (b.id === null) return -1;
              return a.id - b.id;
            });
            if (materialCopyPlanViewComponent.lineItemPlanViewData(index2)) {
              materialCopyPlanViewComponent.lineItemPlanViewData(index2).tblData = [...inputData];
              materialCopyPlanViewComponent.lineItemPlanViewData(index2).lasttblIndex = inputs.length;
            }
          })
        }
      });
    },
    setLineItemPlanViewData (isEdit) {
      this.publishers.forEach((publisher, index1) => {
        const materialCopyPlanViewComponent = this.$refs.materialCopyPlanView[index1];
        if (materialCopyPlanViewComponent) {
          materialCopyPlanViewComponent.lineItemIds.forEach((lineItemId, index2) => {
            var lineItem = this.mediaPlanData.lineItems.find(item => item.id == lineItemId);
            if (materialCopyPlanViewComponent.lineItemPlanViewData(index2)) {
              if (lineItem) {
                materialCopyPlanViewComponent.lineItemPlanViewData(index2).lineItem = {
                  id: lineItem.id,
                  theme: lineItem.theme,
                  startDate: lineItem.startDate,
                  endDate: lineItem.endDate,
                  budget: lineItem.budget,
                  pricingTypeReferenceValueName: lineItem.pricingTypeReferenceValueName
                }
              }
              materialCopyPlanViewComponent.lineItemPlanViewData(index2).adTypeOptions = [...this.adTypeData]
              materialCopyPlanViewComponent.lineItemPlanViewData(index2).languageOptions = [...this.languagesData]
              materialCopyPlanViewComponent.lineItemPlanViewData(index2).isEdit = isEdit;
              materialCopyPlanViewComponent.lineItemPlanViewData(index2).tblData = [];
              materialCopyPlanViewComponent.lineItemPlanViewData(index2).rowToDelete = null;
              materialCopyPlanViewComponent.lineItemPlanViewData(index2).lasttblIndex = 0;
            }
          })
        }
      });
    },
    setPublisherGridDataPlanView (isEdit) {
      const supplierEmailMap = new Map();
      this.supplierData.forEach(supplier => {
        if (supplier.email) {
          supplierEmailMap.set(supplier.id, supplier.email);
        }
      });
      var publisherEmailMap = new Map();
      var publisherActiveStatusMap = new Map();
      if (this.inputGroupId == 0 && !this.inputGroupData) {
        this.mediaPlanData.lineItems.forEach(item => {
          const email = supplierEmailMap.get(item.supplierId).split(',') || [];
          if (email.length > 0) {
            publisherEmailMap.set(item.publisher, email);
          }
          publisherActiveStatusMap.set(item.publisher, true);
        });
      } else {
        let notificationInfo = JSON.parse(this.inputGroupData.notificationInfo);
        notificationInfo.forEach(notification => {
          if (notification) {
            const publisher = notification.publisher;
            const supplierEmails = notification.supplierEmails;
            if (supplierEmails.length > 0) {
              publisherEmailMap.set(publisher, supplierEmails);
            }
            publisherActiveStatusMap.set(publisher, notification.activeStatus)
          }
        });
      }
      this.publishers.forEach((publisher, index) => {
        const materialCopyPlanViewComponent = this.$refs.materialCopyPlanView[index];
        if (materialCopyPlanViewComponent) {
          const supplierEmail = publisherEmailMap.get(publisher) || '';
          const activeStatus = publisherActiveStatusMap.get(publisher) || false;
          if (materialCopyPlanViewComponent.publisherRecipientsData) {
            materialCopyPlanViewComponent.publisherRecipientsData().supplierEmails = supplierEmail;
            materialCopyPlanViewComponent.publisherRecipientsData().isActive = activeStatus;
            materialCopyPlanViewComponent.publisherRecipientsData().isEdit = isEdit;
          }
        }
      });
    },
    addLineItemMode (lineItemId) {
      let foundEntry = [...this.modeLineItemMap].find(([key, valueArray]) => valueArray.includes(lineItemId));
      let mode = foundEntry ? foundEntry[0] : null;
      if (this.linkedPlanViewLineItemModeMap.has(mode)) {
        let lineItems = this.linkedPlanViewLineItemModeMap.get(mode);
        if (!lineItems.includes(lineItemId)) {
          lineItems.push(lineItemId);
        }
      } else {
        this.linkedPlanViewLineItemModeMap.set(mode, [lineItemId]);
      }
      this.renderKey += 1;
    },
    removeLineItemMode (lineItemId) {
      let foundEntry = [...this.modeLineItemMap].find(([key, valueArray]) => valueArray.includes(lineItemId));
      let mode = foundEntry ? foundEntry[0] : null;
      if (mode) {
        let lineItems = this.linkedPlanViewLineItemModeMap.get(mode);
        if (lineItems) {
          let index = lineItems.indexOf(lineItemId);
          if (index > -1) {
            lineItems.splice(index, 1);
          }
          if (lineItems.length === 0) {
            this.linkedPlanViewLineItemModeMap.delete(mode);
          } else {
            this.linkedPlanViewLineItemModeMap.set(mode, lineItems);
          }
        }
      }
      this.renderKey += 1;
    },
    addtoSummary ({ mode, lineItemId }) {
      if (this.modeLinkedLineItemMap.has(mode)) {
        let linkedLineItems = this.modeLinkedLineItemMap.get(mode);
        linkedLineItems.push(lineItemId);
        this.modeLinkedLineItemMap.set(mode, linkedLineItems);
      } else {
        this.modeLinkedLineItemMap.set(mode, [lineItemId]);
      }
      this.renderKey += 1;
    },
    setPublisherGridData () {
      this.publisherSuppliersMap.clear();
      this.mediaPlanData.lineItems.forEach(item => {
        if (this.publisherSuppliersMap.has(item.publisher)) {
          let supplierList = this.publisherSuppliersMap.get(item.publisher);
          if (item.hasOwnProperty('supplier')) {
            supplierList.push(item.supplierId);
          }
          let supplierSet = new Set(supplierList)
          this.publisherSuppliersMap.set(item.publisher, Array.from(supplierSet));
        } else {
          let supplierList = []
          if (item.hasOwnProperty('supplierId')) {
            supplierList.push(item.supplierId);
          }
          this.publisherSuppliersMap.set(item.publisher, supplierList);
        }
      });

      this.$refs.publisherReceipt.tblData = []
      this.publisherSuppliersMap.forEach((value, key) => {
        let suppliersData = this.supplierData.filter(item => value.includes(item.id));
        let emailList = suppliersData.map(item => item.email);
        this.$refs.publisherReceipt.tblData.push(
          {
            activeStatus: false,
            publisher: key,
            supplierEmails: [...emailList]
          }
        )
      })
    },
    setFromNotificationInfo () {
      this.publisherSuppliersMap.clear();
      this.mediaPlanData.lineItems.forEach(item => {
        if (this.publisherSuppliersMap.has(item.publisher)) {
          let supplierList = this.publisherSuppliersMap.get(item.publisher);
          if (item.hasOwnProperty('supplier')) {
            supplierList.push(item.supplierId);
          }
          let supplierSet = new Set(supplierList)
          this.publisherSuppliersMap.set(item.publisher, Array.from(supplierSet));
        } else {
          let supplierList = []
          if (item.hasOwnProperty('supplierId')) {
            supplierList.push(item.supplierId);
          }
          this.publisherSuppliersMap.set(item.publisher, supplierList);
        }
      });

      let notificationData = JSON.parse(this.inputGroupData.notificationInfo);
      this.$refs.publisherReceipt.tblData = []

      const notificationPublishers = new Set(notificationData.map(item => item.publisher));
      notificationData.forEach(item => {
        if (this.publishers.includes(item.publisher)) {
          this.$refs.publisherReceipt.tblData.push({
            activeStatus: item.activeStatus,
            publisher: item.publisher,
            supplierEmails: [...item.supplierEmails]
          });
        }
      });
      const missingPublishers = this.publishers.filter(publisher => !notificationPublishers.has(publisher))

      missingPublishers.forEach(publisher => {
        const supplierIds = this.publisherSuppliersMap.get(publisher) || [];
        const suppliersData = this.supplierData.filter(item => supplierIds.includes(item.id));
        const emailList = suppliersData.map(item => item.email);

        this.$refs.publisherReceipt.tblData.push({
          activeStatus: false,
          publisher: publisher,
          supplierEmails: [...emailList]
        });
      });
    },
    removeFromSummary ({ mode, lineItemId }) {
      let lineItems = this.modeLinkedLineItemMap.get(mode);
      let index = lineItems.indexOf(lineItemId);
      lineItems.splice(index, 1);
      this.modeLinkedLineItemMap.set(mode, lineItems);
      this.renderKey += 1;
    },
    async init (mediaPlanId) {
      let bpLanguages = (await apiService.getLanguagesByBpId()).data.data;
      bpLanguages.forEach(element => {
        this.languagesData.push({
          id: element.id,
          name: element.displayName
        })
      });
      // this.languagesData = bpLanguages.map(element => {return {id: element.id, name: element.displayName}});
      /// get adtype data to show in dropdown.
      // for now putting dummy data
      for (let index = 1; index < 10; index++) {
        this.adTypeData.push({
          id: index,
          name: "social" + index
        })
      }
      this.assigneeData = (await apiService.getBPUsers({ roleIds: '5' })).data.data;
      this.receiverData = (await apiService.getBPUsers({ roleIds: '4' })).data.data;
      this.businessProfileName = (await apiService.getBPById()).data.data.name;

      if (mediaPlanId) {
        this.mediaPlanData = (await apiService.getMediaPlanById(mediaPlanId)).data.data;
        if (this.mediaPlanData.hasOwnProperty('inputGroupId')) {
          this.inputGroupId = this.mediaPlanData.inputGroupId;
          this.inputGroupData = (await apiService.getInputGroupById(this.inputGroupId)).data.data;
        }
      }

      let supplierIds = [...new Set(this.mediaPlanData.lineItems.filter(item => item.supplierId !== undefined)
        .map(item => item.supplierId))]
      if (supplierIds.length > 0) {
        this.supplierData = (await apiService.getAllSuppliers({ supplierIds: supplierIds.join(",") })).data.suppliers
      }

      this.publishers = [...new Set(this.mediaPlanData.lineItems.map(item => item.publisher))];

      this.mediaPlanName = this.mediaPlanData.name;
      this.lineItemModes = [...new Set(this.mediaPlanData.lineItems.map(item => item.lineItemMode))];
      this.lineItemModes.sort();

      this.lineItemModes.forEach(mode => {
        let lineItemsIds = this.mediaPlanData.lineItems.filter(obj => obj.lineItemMode == mode).map(item => item.id);
        this.modeLineItemMap.set(mode, lineItemsIds);
      })
      this.publisherLineItemMap.clear();
      this.mediaPlanData.lineItems.forEach(item => {
        if (!this.publisherLineItemMap.has(item.publisher)) {
          this.publisherLineItemMap.set(item.publisher, []);
        }
        this.publisherLineItemMap.get(item.publisher).push(item.id);
      });

      this.renderKey += 1;
      if (this.inputGroupId) {
        this.setInputGroupData()
      }
    },
    setInputGroupData () {
      this.selectedDeliveryMode = this.inputGroupData.deliveryMode;
      let assigneList = this.inputGroupData.assignees != undefined ? this.inputGroupData.assignees.split(",") : []
      let receiverList = this.inputGroupData.receivers != undefined ? this.inputGroupData.receivers.split(",") : []
      this.selectedAssignee = assigneList.map(Number);
      this.selectedReceiver = receiverList.map(Number)
    },
    countTotalLineItems (mode) {
      let lineItems = this.modeLineItemMap.get(mode);
      if (lineItems != undefined) {
        return new Set(lineItems).size;
      } else {
        return 0;
      }
    },
    getLineItemType (mode) {
      switch (mode) {
        case 1: return 'Digital'
        case 2: return 'TV'
        case 3: return 'Radio'
        case 4: return 'Press'
        case 5: return 'OOH'
        case 6: return 'Cinema'
        case 7: return 'Magazine'

        default: return ''
      }
    },
    getLinkedLineItems (mode) {
      let linkedLineItmes = null;
      if (this.currentView === this.currentViewEnum.Asset) {
        linkedLineItmes = this.modeLinkedLineItemMap.get(mode);
      } else {
        linkedLineItmes = this.linkedPlanViewLineItemModeMap.get(mode);
      }
      if (linkedLineItmes != undefined) {
        return new Set(linkedLineItmes).size;
      } else {
        return 0;
      }
    },
    async setGridData () {
      let index = 0;
      this.lineItemModes.forEach(mode => {
        this.setLinkedLineItemsOptions(mode, index);
        if (this.inputGroupId != 0 || this.inputGroupData) {
          this.setInputData(mode, index);
        }
        index++;
      });
    },
    setInputData (mode, index) {
      let inputTableGridData = []
      let i = 1;
      let lineItemIds = this.mediaPlanData.lineItems.filter(obj => obj.lineItemMode === mode).map(obj => obj.id);
      let inputs = this.inputGroupData.inputs.filter(obj => obj.lineItemIds.some(id => lineItemIds.map(String).includes(String(id))));
      inputs.forEach(input => {
        input.lineItemIds = input.lineItemIds.filter(id => lineItemIds.includes(id));
        if (this.modeLinkedLineItemMap.has(mode)) {
          let linkedLineitems = this.modeLinkedLineItemMap.get(mode);
          let combinedLinekedLineItems = [...linkedLineitems, ...input.lineItemIds];
          this.modeLinkedLineItemMap.set(mode, combinedLinekedLineItems)
        } else {
          this.modeLinkedLineItemMap.set(mode, input.lineItemIds)
        }
        inputTableGridData.push({
          index: i,
          id: input.id,
          language: input.languageId,
          adType: input.adTypeId,
          specifications: JSON.parse(input.specifications),
          dueDate: input.dueDate.split("T")[0],
          dueTime: input.dueDate.split("T")[1],
          linkLineItems: input.lineItemIds,
          status: input.status
        })
        i++;
        this.$refs.materialCopy[index].lasttblIndex = i;
      });
      inputTableGridData.sort((a, b) => {
        if (a.id === null) return 1;
        if (b.id === null) return -1;
        return a.id - b.id;
      });
      this.$refs.materialCopy[index].tblData = []
      this.$refs.materialCopy[index].tblData = [...inputTableGridData]
    },
    setLinkedLineItemsOptions (mode, index) {
      const linkedlineItemOptionsMap = new Map();
      let linkedLineItemOptions = []
      const lineItems = this.mediaPlanData.lineItems.filter(item => item.lineItemMode === mode);

      lineItems.forEach(item => {
        if (!linkedlineItemOptionsMap.has(item.publisher)) {
          linkedlineItemOptionsMap.set(item.publisher, {
            id: item.publisher,
            label: item.publisher,
            children: []
          });
        }

        linkedlineItemOptionsMap.get(item.publisher).children.push({
          id: item.id,
          label: item.name
        });
      });

      // Convert map values to an array and assign to linkedlineItemOptions
      linkedLineItemOptions = Array.from(linkedlineItemOptionsMap.values());
      this.$refs.materialCopy[index].publisherLineItemOptions = [...linkedLineItemOptions]
      this.$refs.materialCopy[index].languageOptions = [...this.languagesData]
      this.$refs.materialCopy[index].adTypeOptions = [...this.adTypeData]
    },
    closeModal () {
      this.openRequisitionModal = false;
      this.renderKey = 0;
      this.resetData();
      this.$emit("closeMaterialCopyModal");
    },
    async save () {
      let index = 0;
      let inputData = []
      this.loadingData = true;
      var notificationInfos = null;
      if (this.currentView === this.currentViewEnum.Plan && this.needToRecalculateAssetViewData) {
        var infos = [];
        this.publishers.forEach((publisher, index) => {
          var publisherInfo = this.$refs.materialCopyPlanView[index];
          infos.push({
            activeStatus: publisherInfo.publisherRecipientsData().isActive,
            publisher: publisherInfo.publisher,
            supplierEmails: publisherInfo.publisherRecipientsData().supplierEmails
          })
        })
        notificationInfos = JSON.stringify(infos);
      } else {
        notificationInfos = JSON.stringify(this.$refs.publisherReceipt.tblData);
      }
      let inputGroupRequestData = {
        id: this.inputGroupId,
        name: this.mediaPlanName,
        deliveryMode: this.selectedDeliveryMode,
        assignees: this.selectedAssignee.join(","),
        receivers: this.selectedReceiver.join(","),
        mediaPlanId: this.mediaPlanId,
        inputs: [],
        notificationInfo: notificationInfos
      }

      if (this.currentView === this.currentViewEnum.Plan && this.needToRecalculateAssetViewData) {
        var planViewInputData = [];
        this.publishers.forEach((publisher, index) => {
          var materialCopyPlanView = this.$refs.materialCopyPlanView[index];
          materialCopyPlanView.lineItemIds.forEach((lineItemId, index2) => {
            var inputDatas = materialCopyPlanView.lineItemPlanViewData(index2).tblData;
            inputDatas.forEach((input, index) => {
              var formattedInputDate = moment(input.dueDate).format().toString().split("T")[0];
              planViewInputData.push({
                id: input.id,
                inputGroupId: this.inputGroupId,
                languageId: input.language,
                adTypeId: input.adType,
                dueDate: formattedInputDate + "T" + input.dueTime,
                specifications: JSON.stringify(input.specifications),
                lineItemIds: lineItemId.toString(),
                status: input.status
              });
            })
          })
        })
        inputGroupRequestData.inputs = planViewInputData;
      } else {
        this.lineItemModes.forEach(mode => {
          inputData = [...inputData, ...this.$refs.materialCopy[index].tblData];
          index += 1;
        })
        inputData.forEach(input => {
          var formattedInputDate = moment(input.dueDate).format().toString().split("T")[0];
          inputGroupRequestData.inputs.push({
            id: input.id,
            inputGroupId: this.inputGroupId,
            languageId: input.language,
            adTypeId: input.adType,
            dueDate: formattedInputDate + "T" + input.dueTime,
            specifications: JSON.stringify(input.specifications),
            lineItemIds: input.linkLineItems.join(","),
            status: input.status
          })
        })
      }
      try {
        if (this.inputGroupId == 0) {
          await apiService.create(inputGroupRequestData);
        } else {
          await apiService.edit(this.inputGroupId, inputGroupRequestData);
        }
      } finally {
        this.loadingData = false;
        this.closeModal()
      }
    },

    resetData () {
      this.lineItemModes = []
      this.mediaPlanData = null
      this.inputGroupId = 0
      this.inputGroupData = null
      this.businessProfileName = ''
      this.selectedAssignee = []
      this.selectedReceiver = []
      this.selectedDeliveryMode = null
      this.languagesData = []
      this.adTypeData = []
      this.assigneeData = []
      this.receiverData = []
      this.mediaPlanName = ''
      this.renderKey = 0
      this.modeLineItemMap.clear()
      this.modeLinkedLineItemMap.clear()
      this.loadingData = false
      this.publisherSuppliersMap.clear()
      this.publisherLineItemMap.clear()
      this.supplierData = []
      this.currentView = null
      this.publishers = []
      this.currentView = this.currentViewEnum.Asset
      this.needToRecalculateAssetViewData = true
      this.linkedPlanViewLineItemModeMap.clear()
    }
  }
}
</script>

<style scoped>
.alert-message {
  width: max-content;
  padding: 2px 0px;
  color: red;
  margin-bottom: 10px;
}

.close-btn {
  margin-bottom: 0px;
}

.header-title {
  font-size: medium;
  padding-top: 8px;
}

.mcr-modal-footer {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.setting-font {
  font-size: x-large;
  position: relative;
  top: 0.5vh;
}

.plan-view-btn {
  margin-left: 0px;
}

.spinner-wrapper {
  display: grid;
  place-items: center;
}

.summary-footer {
  display: flex;
  justify-content: flex-start;
  gap: 30px;
}

.footer-header {
  display: flex;
  gap: 10px;
  padding-left: 1%;
}

.view-buttons {
  padding-left: 12px !important;
  padding-right: 3.2% !important;
  text-align: right !important;
}

.bp-selector {
  padding-left: 1.6% !important;
}

.summary-text {
  font-weight: bold
}

::v-deep .ivu-modal {
  top: 53px !important;
}

::v-deep .modal-body {
  min-height: max-content !important;
  max-height: 70vh !important;
}

::v-deep .ivu-modal-body {
  padding-top: 0px !important;
}
</style>
